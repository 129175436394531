import React from "react";

import Admin from "layouts/Admin";
import YouTubeIcon from "@material-ui/icons/YouTube";
import InstagramIcon from "@material-ui/icons/Instagram";
const styles = {
  wrapper: {
    width: "50%",
    margin: "1rem auto 5rem auto",
    color: "#666",
  },
  title: {
    color: "black",
  },
  section: {
    marginBottom: "5rem",
  },
};
function Privacy() {
  return (
    <Admin headerPanel>
      <div style={styles.wrapper}>
        <section style={styles.section}>
          <h4 style={styles.title}>EMAIL</h4>
          <p>
            {`If you'd just like to make a general enquiry about something, or get
            in touch about any Recipe, Event or Shop, or
            even just to say Hi! then please email me.`}
          </p>
          <p>
            <strong>hello@freshtable.io</strong>
          </p>
        </section>
        <section style={styles.section}>
          <h4 style={styles.title}>SOCIAL MEDIA</h4>
          <p>You can also follow me on social media platforms:</p>
          <p>
            <a
              rel="noopener noreferrer"
              href="https://www.instagram.com/freshtable.io/"
              style={{
                color: "black",
                marginRight: "1rem",
                display: "inline-block",
              }}
              target="_blank"
              referrerPolicy="noopener noreferrer"
            >
              <InstagramIcon />
            </a>
            <a
              rel="noopener noreferrer"
              href="https://www.youtube.com/channel/UCQ1jDGX6p6XvOSiH8UvNQdA/"
              style={{
                color: "black",
                display: "inline-block",
              }}
              target="_blank"
              referrerPolicy="noopener noreferrer"
            >
              <YouTubeIcon />
            </a>
          </p>
        </section>
      </div>
    </Admin>
  );
}

export default Privacy;
