const MODULE_PREFIX = `CART`;

export const ADD_TO_CART = `${MODULE_PREFIX}/ADD_TO_CART`;
export const ADD_TO_CART_SUCCESS = `${MODULE_PREFIX}/ADD_TO_CART_SUCCESS`;
export const ADD_TO_CART_FAIL = `${MODULE_PREFIX}/ADD_TO_CART_FAIL`;
export const GET_TO_CART = `${MODULE_PREFIX}/GET_TO_CART`;
export const GET_TO_CART_SUCCESS = `${MODULE_PREFIX}/GET_TO_CART_SUCCESS`;
export const GET_TO_CART_FAIL = `${MODULE_PREFIX}/GET_TO_CART_FAIL`;
export const DELETE_TO_CART = `${MODULE_PREFIX}/DELETE_TO_CART`;
export const DELETE_TO_CART_SUCCESS = `${MODULE_PREFIX}/DELETE_TO_CART_SUCCESS`;
export const DELETE_TO_CART_FAIL = `${MODULE_PREFIX}/DELETE_TO_CART_FAIL`;
