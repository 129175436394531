import React from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import PropTypes from "prop-types";
import { Checkbox } from "@material-ui/core";
import Check from "@material-ui/icons/Check";
import { makeStyles } from "@material-ui/core/styles";
import {
  primaryColor,
  hexToRgb,
  blackColor,
} from "assets/jss/material-dashboard-pro-react.js";
import Gallery from "components/Gallery/Gallery";
import { getImagePath } from "shared/utils/helpers";

const styles = {
  ingredients_container: {
    padding: "2rem",
  },
  inline_ingredient: {
    display: "inline",
  },
  ingredient_item: {
    listStyle: "none",
  },
  ingredient_amount: {
    display: "inline-block",
    color: "#9D9EA3",
    fontWeight: "bold",
  },
  ingredient_description: {
    display: "inline-block",
    marginLeft: 10,
    color: "#264472",
    fontWeight: "bold",
  },
  checkRoot: {
    padding: "14px",
    "&:hover": {
      backgroundColor: "unset",
    },
  },
  checked: {
    color: primaryColor[0] + "!important",
  },
  checkedIcon: {
    width: "20px",
    height: "20px",
    border: "1px solid rgba(" + hexToRgb(blackColor) + ", .54)",
    borderRadius: "3px",
  },

  uncheckedIcon: {
    width: "0px",
    height: "0px",
    padding: "9px",
    border: "1px solid rgba(" + hexToRgb(blackColor) + ", .54)",
    borderRadius: "3px",
  },
};
const useStyles = makeStyles(styles);

export default function Ingredients({ ingredients, ingredientsMedia }) {
  const classes = useStyles();

  return (
    <div>
      <h3>Ingredients</h3>
      <GridContainer className={classes.ingredients_container}>
        <GridItem md={6}>
          {ingredientsMedia && (
            <Gallery
              media={ingredientsMedia.map((m) => ({
                ...m,
                url: getImagePath(m.url),
              }))}
            />
          )}
        </GridItem>
        <GridItem md={6}>
          <div>
            {(ingredients || []).map((ingredient, index) => (
              <div
                key={`ingredient-${index}`}
                className={`${classes.ingredient_item} ${
                  ingredient.inline ? classes.inline_ingredient : ""
                }`}
              >
                <Checkbox
                  disableRipple
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
                <span className={classes.ingredient_amount}>
                  {ingredient.amount}
                </span>
                <span className={classes.ingredient_description}>
                  {ingredient.description}
                </span>
              </div>
            ))}
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
}

Ingredients.propTypes = {
  ingredients: PropTypes.array,
  ingredientsMedia: PropTypes.string,
};
