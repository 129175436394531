import {
  container,
  defaultFont,
  primaryColor,
  defaultBoxShadow,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  boxShadow,
  drawerWidth,
  transition,
  whiteColor,
  grayColor,
  blackColor,
  hexToRgb,
} from "assets/jss/material-dashboard-pro-react.js";
import { deepPurple } from '@material-ui/core/colors';

const pagesHeaderStyle = (theme) => ({
  appBar: {
    backgroundColor: "transparent",
    boxShadow: "none",
    borderBottom: "0",
    marginBottom: "0",
    width: "100%",
    paddingTop: "0",
    zIndex: "1029",
    color: grayColor[6],
    border: "0",
    borderRadius: "3px",
    padding: "10px 0",
    transition: "all 150ms ease 0s",
    minHeight: "50px",
    display: "block",
    position: "sticky",
    top: 0,
  },
  headerPanelToolbar: {
    padding: "0rem 5rem",
  },
  container: {
    ...container,
    minHeight: "50px",
  },
  flex: {
    flex: "auto",
  },
  title: {
    ...defaultFont,
    lineHeight: "30px",
    fontSize: "1.75rem",
    // fontWeight: "bold",
    letterSpacing: '0.25rem !important',
    borderRadius: "3px",
    textTransform: "unset",
    color: whiteColor,
    // letterSpacing: "unset",
    "&:hover,&:focus": {
      background: "transparent",
      color: whiteColor,
    },
  },
  titleInverted: {
    color: "white",
    "&:hover,&:focus": {
      // background: "white",
      color: "white",
    },
  },
  appResponsive: {
    top: "8px",
  },
  primary: {
    backgroundColor: primaryColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  info: {
    backgroundColor: infoColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  success: {
    backgroundColor: successColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  warning: {
    backgroundColor: warningColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  danger: {
    backgroundColor: dangerColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  list: {
    ...defaultFont,
    fontSize: "14px",
    margin: 0,
    marginRight: "-15px",
    paddingLeft: "0",
    listStyle: "none",
    color: whiteColor,
    paddingTop: "0",
    paddingBottom: "0",
    display: 'flex',
  },
  listItem: {
    float: "left",
    position: "relative",
    display: "block",
    width: "auto",
    margin: "0",
    padding: "0",
    [theme.breakpoints.down("sm")]: {
      zIndex: "999",
      width: "100%",
      paddingRight: "15px",
    },
  },
  navbarInverted: {
    backgroundColor: '#3fbfba',
  },
  navLink: {
    color: whiteColor,
    margin: "0 5px",
    paddingTop: "15px",
    paddingBottom: "15px",
    fontWeight: "bold",
    fontSize: "13px",
    textTransform: "uppercase",
    borderRadius: "3px",
    lineHeight: "20px",
    position: "relative",
    display: "block",
    padding: "10px 15px",
    textDecoration: "none",
    "&:hover,&:focus": {
      color: whiteColor,
      background: "rgba(" + hexToRgb(grayColor[17]) + ", 0.2)",
    },
  },
  navLinkActive: {
    backgroundColor: "rgba(" + hexToRgb(whiteColor) + ", 0.1)",
  },
  navLinkInverted: {
    color: "white !important",
    fontWeight: "normal !important",
    "&:hover,&:focus": {
      fontWeight: 'bold !important'
      // color: "#1A72E7 !important",
      // background: "#E7F0FF",
    },
  },
  navLinkInvertedActive: {
    // background: "#E7F0FF",
    // color: "white !important",
    fontWeight: 'bold !important',
    backgroundColor: 'rgba(200, 200, 200, 0.2)',
  },
  listItemIcon: {
    marginTop: "-3px",
    top: "0px",
    position: "relative",
    marginRight: "3px",
    width: "20px",
    height: "20px",
    verticalAlign: "middle",
    color: "inherit",
    display: "inline-block",
  },
  listItemText: {
    flex: "none",
    padding: "0",
    minWidth: "0",
    margin: 0,
    display: "inline-block",
    position: "relative",
    whiteSpace: "nowrap",
  },
  drawerPaper: {
    border: "none",
    bottom: "0",
    transitionProperty: "top, bottom, width",
    transitionDuration: ".2s, .2s, .35s",
    transitionTimingFunction: "linear, linear, ease",
    ...boxShadow,
    width: drawerWidth,
    ...boxShadow,
    position: "fixed",
    display: "block",
    top: "0",
    height: "100vh",
    right: "0",
    left: "auto",
    visibility: "visible",
    overflowY: "visible",
    borderTop: "none",
    textAlign: "left",
    paddingRight: "0px",
    paddingLeft: "0",
    ...transition,
    "&:before,&:after": {
      position: "absolute",
      zIndex: "3",
      width: "100%",
      height: "100%",
      content: '""',
      display: "block",
      top: "0",
    },
    "&:after": {
      background: blackColor,
      opacity: ".8",
    },
  },
  sidebarButton: {
    "&,&:hover,&:focus": {
      color: whiteColor,
    },
    top: "-2px",
  },
  cartNumber: {
    marginLeft: 18,
    marginTop: -10,
    backgroundColor: 'red',
    color: '#fff',
    width: 20,
    height: 20,
    textAlign: 'center',
    borderRadius: 20
  },
  avatar: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
    width: '32px',
    height: '32px',
  },
});

export default pagesHeaderStyle;
