import 'date-fns';
import React from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Gallery from "components/Gallery/Gallery";
import { getImagePath } from "shared/utils/helpers";
import {
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment'
import { ORDER_TYPE_MAP } from "shared/constants";

const styles = {
  cardTitle: {
    marginBottom: 0,
  },
  marginTop30: {
    marginTop: "30px",
  },
  marginBottom30: {
    marginBottom: "30px",
  },
  paddingTop30: {
    paddingTop: "30px",
  },
  recipe_description: {
    paddingTop: "20px",
  },
  recipe_summary: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "30px",
  },
  recipe_meta: {
    display: "inline-block",
    width: "30%",
    textAlign: "center",
    borderRight: "1px solid #e1e7ec",
    "&:last-child": {
      borderRight: "none",
    },
  },
  meta_title: {
    fontSize: "2rem",
  },
  meta_description: {
    marginTop: "10px",
  },
  addToCartButton: {
    padding: "1rem 2rem",
    opacity: "0.9",
  },
  quantity: {
    marginTop: 30,
  },
  quantity_select: {
    width: 100,
    textAlign: "center",
  },
  special_requests: {
    marginTop: 30,
  },
  textArea: {
    width: "80%",
    height: 80,
    padding: "1rem",
  },
  guestsLeft: {
    color: "red",
    fontWeight: "bold",
    marginTop: 20,
  },
  add_to_card: {
    marginTop: 30,
  },
  priceRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  vLine: {
    height: 100,
    width: 1,
    backgroundColor: 'lightgrey'
  }
};


const useStyles = makeStyles(styles);

export default function Details({
  selectedMeal,
  notes,
  quantity,
  onChange,
  onAddToCart,
  subtotal,
  submitButtonTitle
}) {
  const classes = useStyles();


  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <GridContainer>
        <GridItem md={6}>
          <h2 className={classes.cardTitle}>{selectedMeal.meal_title}</h2>
          <div className={classes.marginBottom30}>
            <p
              className={classes.recipe_description}
              dangerouslySetInnerHTML={{
                __html: selectedMeal.meal_description,
              }}
            />
            {selectedMeal.meal_date && (
              <div style={{ marginBottom: 15 }}>
                <p>
                  Event Date:{" "}
                  <strong>
                    {moment
                      .utc(selectedMeal.meal_date)
                      .format("dddd MMMM DD , YYYY hh:mm a")}
                  </strong>
                </p>
              </div>
            )}
            <div style={{ marginBottom: 15 }}>
              <p>
                Event Location: <strong>Irvine, California</strong>
              </p>
            </div>
            {selectedMeal.meal_order_type && (
              <div style={{ marginBottom: 15 }}>
                <p>
                  Event Type:{" "}
                  <strong>{ORDER_TYPE_MAP[selectedMeal.meal_order_type]}</strong>
                </p>
              </div>
            )}
            {/* <div>
              <div className={classes.priceRow}>
                <div className={classes.meta_title}>
                  $
                {parseInt(selectedMeal.price) === selectedMeal.price
                    ? `${selectedMeal.price}.00`
                    : selectedMeal.price}
                  {selectedMeal.price_text && (
                    <span style={{ marginLeft: 10 }}>
                      <small>{selectedMeal.price_text}</small>
                    </span>
                  )}
                </div>
                <div className={classes.vLine} />
                <div className={classes.quantity}>
                  <div>
                    <p>
                      {selectedMeal.order_limit > 0 && (
                        <React.Fragment>
                          <span style={{ marginRight: "1rem" }}>Qty:</span>
                          <Select
                            className={classes.quantity_select}
                            value={quantity}
                            onChange={(e) => onChange({ quantity: e.target.value })}
                          >
                            {Array.from(Array(selectedMeal.order_limit)).map((_, index) => index + 1).map((q) => (
                              <MenuItem key={`quantity-${q}`} value={q}>
                                {q}
                              </MenuItem>
                            ))}
                          </Select>
                        </React.Fragment>
                      )}
                    </p>
                    {selectedMeal.order_limit > 0 ? (
                      <p className={classes.guestsLeft}>
                         {selectedMeal.order_limit} items left 
                      </p>
                    ) : (
                      <p className={classes.guestsLeft}></p>
                    )}
                  </div>
                </div>
              </div>
              {selectedMeal.order_limit > 0 &&
                <div className={classes.add_to_card}>

                  <Grid container justify={'space-between'} alignItems={"center"} style={{ marginBottom: 20 }}>
                    <Grid>
                      <Typography>Pickup Date</Typography>
                      <KeyboardDatePicker
                        disableToolbar
                        autoOk
                        style={{ width: 150 }}
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label=""
                        value={state.selectedDate}
                        onChange={(e) => handleDateChange(e, 'date')}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </Grid>
                    <Grid style={{ marginTop: -5 }}>
                      <Typography>Pickup Time</Typography>
                      <Select
                        value={state.time}
                        style={{ marginTop: 15 }}
                        onChange={(e) => handleDateChange(e, 'time')}>
                        {
                          timeRange.map((time, index) => (
                            <MenuItem key={index} value={time}>{time}</MenuItem>
                          ))
                        }
                      </Select>
                    </Grid>
                  </Grid>
                  <Grid container justify={"center"}>
                    <Button
                      size="large"
                      onClick={() => onAddToCart({ email: 'fahadhussain368@gmail.com', name: 'fahad', selectedMeal, date: state.date, time: state.time })}
                      disabled={!state.date || !state.time || !subtotal || (selectedMeal.order_limit > 0 ? false : true)}
                      style={{ backgroundColor: !subtotal ? 'gray' : "#3fbfba", color: "white", marginLeft: "10px", }}
                    >
                      {submitButtonTitle}
                    </Button>
                  </Grid>
                </div>
              }
            </div> */}
          </div>
        </GridItem>
        <GridItem md={6}>
          {selectedMeal.media && (
            <Gallery
              videoFirst
              media={selectedMeal.media.map((m) => ({
                ...m,
                url: getImagePath(m.url),
              }))}
            />
          )}
        </GridItem>
      </GridContainer>
    </MuiPickersUtilsProvider>
  );
}

Details.propTypes = {
  selectedMeal: PropTypes.object,
  quantity: PropTypes.number,
  notes: PropTypes.string,
  onChange: PropTypes.func,
  onAddToCart: PropTypes.func,
  subtotal: PropTypes.number,
  submitButtonTitle: PropTypes.string,
};
