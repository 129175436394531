import React, { useState, useEffect } from "react";

// @material-ui/core components
import {
  Input,
  FormControl,
  FormHelperText,
  FormGroup,
  Grid,
  Typography,
  Button,
  CircularProgress,
} from "@material-ui/core";
import Admin from "layouts/Admin";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { verifyEmail, setPass } from "./modules/actions";

const useStyles = makeStyles({
  checkedIcon: {
    color: '#1ea2d5 !important'
  },
  buttonProgress: {
    color: '#fff'
  }
});

function SetPassword({ verifyEmail, history, verifySuccess, setPass, setPassLoading, setPassSuccess }: any) {
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();
  const [token, setToken] = useState(false);

  const onSubmit = (data) => {

    if (token) {
      setPass(data?.password, token)
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('token');
    if (myParam) {
      verifyEmail(myParam)
      setToken(myParam)
      return
    } else {
      history.push('/app/create')
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (setPassSuccess) {
      history.push('/app/login')
    }
    // eslint-disable-next-line
  }, [setPassSuccess])

  return (
    <Admin backgroundColor="white" containerStyles={{ alignItems: "unset" }}>
      <div style={{ width: 400, margin: "0px auto", color: "black" }}>
        {
          verifySuccess &&
          <Grid container>
            <Grid item xs={12}>
              <Typography
                style={{ color: "black", marginBottom: "2rem", fontFamily: 'proxima-nova,sans-serif' }}
                align="center"
                variant="h4"
              >
                Your email address is now verified.
            </Typography>
            </Grid>

            <Grid item xs={12}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                  <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <p
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        borderBottom: "1px solid #eee",
                        paddingBottom: 20,
                        width: '100%'
                      }}
                    ></p>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      style={{ color: "black", marginBottom: "2rem", fontFamily: 'proxima-nova,sans-serif' }}
                      align="left"
                      variant="h4"
                    >
                      Please set a password.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormGroup>
                      <p style={{ fontFamily: 'proxima-nova,sans-serif', color: 'black', fontWeight: "bold", fontSize: 16 }}>PASSWORD</p>
                      <FormControl>
                        <Input
                          style={{
                            borderColor: ' #e6e6e6',
                            boxShadow: '0 1px 2px 0 #e6e6e6',
                            color: 'grey',
                            border: '1px solid #ccc',
                            fontFamily: 'proxima-nova,sans-serif',
                            padding: '5px 15px 5px 10px',
                            marginTop: -5
                          }}
                          name="password"
                          type={"password"}
                          disableUnderline
                          inputRef={register({
                            required: true,
                          })}
                        />
                        {errors.password && (
                          <FormHelperText style={{ color: "red" }}>
                            This field is required
                          </FormHelperText>
                        )}
                      </FormControl>
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        color="primary"
                        style={{
                          backgroundColor: "#1ea2d5",
                          textTransform: "none",
                          borderRadius: 1,
                          height: 40,
                          fontSize: 13,
                          color: "white",
                          letterSpacing: 0.80
                        }}
                        fullWidth
                        variant="contained"
                        disabled={setPassLoading || Object.keys(errors).length > 0}
                        type="submit"
                      >
                        {
                          setPassLoading ?
                            <CircularProgress size={18} className={classes.buttonProgress} />
                            : "Continue"
                        }
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        }
      </div>
    </Admin>
  );
}


const mapStateToProps = ({ setpassword }) => ({
  loading: setpassword.verifyLoading,
  verifySuccess: setpassword.verifySuccess,
  setPassLoading: setpassword.setPassLoading,
  setPassSuccess: setpassword.setPassSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  verifyEmail: (id) =>
    dispatch(verifyEmail(id)),
  setPass: (password, id) =>
    dispatch(setPass(password, id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SetPassword);