import React from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import PropTypes from "prop-types";
import { Badge } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Gallery from "components/Gallery/Gallery";
import { getImagePath } from "shared/utils/helpers";

const styles = {
  steps_container: {
    padding: "2rem",
  },
  step_item: {
    padding: 30,
    paddingTop: 0,
  },
  step_image: {
    width: "100%",
    // maxHeight: 450,
  },
  step_title: {
    padding: 10,
    borderBottom: "1px solid #eee",
    display: "flex",
    alignItems: "center",
    fontWeight: "bold",
  },
  step_title_badge: {
    display: "inline-block",
    marginRight: 15,
  },
  marginTop60: {
    marginTop: "60px",
  },
};
const useStyles = makeStyles(styles);

export default function Steps({ steps }) {
  const classes = useStyles();

  return (
    <div>
      <h3>Steps</h3>
      <GridContainer className={classes.steps_container}>
        {steps.map((step, index) => (
          <GridItem md={6} sm={12} key={`step-item-${index}`}>
            <div className={classes.step_item}>
              <div className={classes.step_image}>
                {step.media && (
                  <Gallery
                    media={step.media.map((m) => ({
                      ...m,
                      url: getImagePath(m.url),
                    }))}
                  />
                )}
              </div>
              <h5
                className={`${classes.step_title} ${
                  step.media && step.media.length > 1 ? classes.marginTop60 : ""
                }`}
              >
                <Badge
                  className={classes.step_title_badge}
                  color="primary"
                  badgeContent={index + 1}
                ></Badge>
                {step.title}
              </h5>
              <p dangerouslySetInnerHTML={{ __html: step.description }} />
            </div>
          </GridItem>
        ))}
      </GridContainer>
    </div>
  );
}

Steps.propTypes = {
  steps: PropTypes.array,
};
