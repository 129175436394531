import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { fetchProductBySlug } from "actions/product";
import { addToCart } from "actions/cart";
import { withRouter } from "react-router-dom";
import Admin from "layouts/Admin";
import Loader from "components/Loader";
import Details from "./Details";
import AboutThisItem from "./AboutThisItem";
import Separator from "components/Separator";

import { setOrderItems } from "actions/order";
import { updateOrderItem } from "actions/order";

import { createProductOrder, requestEmailVerifyCode } from "actions/order";

function ShopDetails({ selectedProduct,
  fetchProductBySlug,
  loading,
  orderItems,
  updateOrderItem,
  setOrderItems,
  createProductOrder,
  match,
  addToCart}: any) {
  const { id } = match.params;
  
  const [state, setstate] = useState({
    quantity: 1
  })
  useEffect(() => {
    fetchProductBySlug(id);
  }, [id, fetchProductBySlug]);

  useEffect(() => {
    if (selectedProduct) {
      const orderItems = [
        {
          product: selectedProduct,
          quantity: 1,
          notes: "",
        },
      ];
      setOrderItems(orderItems);
    }
  }, [selectedProduct, setOrderItems]);

  const onOrderSubmit = ({ selectedProduct, date, time }) => {
    addToCart(selectedProduct, state.quantity, true, date, time)
  };

  return (
    <Admin>
      <Loader visible={loading} />

      <div style={{ padding: "1rem", color: "rgba(0, 0, 0, 0.87)" }}>
        {selectedProduct && (
          <React.Fragment>
            <Details
              selectedProduct={selectedProduct}
              quantity={
                orderItems && orderItems[0] ? orderItems[0].quantity : 1
              }
              note
              onChange={(changes) => {
                setstate({ ...state, quantity: changes.quantity })
                updateOrderItem(0, changes);
              }} submitButtonTitle="Add To Cart"
              subtotal={orderItems.map((o) => o.product.price * o.quantity)}
              onAddToCart={onOrderSubmit}
            />
            {selectedProduct.about_this_item && (
              <div>
                <Separator />
                <AboutThisItem item={selectedProduct.about_this_item} />
              </div>
            )}
            {/* <Separator />
            <PanelsList panels={selectedProduct.panels} /> */}
            {/* <Separator />
            {orderItems && orderItems.length > 0 && (
              <OrderSummary
                submitButtonTitle="SUBMIT ORDER REQUEST"
                title={selectedProduct.title}
                subtotal={orderItems.map((o) => o.product.price * o.quantity)}
                onSubmit={onOrderSubmit}
              />
            )} */}
          </React.Fragment>
        )}
      </div>
    </Admin>
  );
}

ShopDetails.propTypes = {
  fetchProductBySlug: PropTypes.func,
  addToCart: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  match: PropTypes.object,
  selectedProduct: PropTypes.object,
  orderItems: PropTypes.array,
  updateOrderItem: PropTypes.func,
  setOrderItems: PropTypes.func,
};

const mapStateToProps = ({ product, order }) => ({
  loading: product.loading,
  selectedProduct: product.selectedProduct,
  orderItems: order.orderItems,
});

const mapDispatchToProps = (dispatch) => ({
  addToCart: (product, quantity, showCartDialog, date, fromTime, toTime) => dispatch(addToCart(product, quantity, showCartDialog, date, fromTime, toTime)),
  fetchProductBySlug: (id) => dispatch(fetchProductBySlug(id)),
  setOrderItems: (items) => dispatch(setOrderItems(items)),
  createProductOrder: (data) => dispatch(createProductOrder(data)),
  requestEmailVerifyCode: ({ email, name }) =>
    dispatch(requestEmailVerifyCode({ email, name })),
  updateOrderItem: (index, changes) =>
    dispatch(updateOrderItem(index, changes)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ShopDetails));
