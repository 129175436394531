import * as actionTypes from "./types";
import Api from "../../shared/api";
import requestAgent from "../../shared/requestAgent";
import { shuffleArray } from "shared/utils/helpers";

const setHomeImagesLoading = (loading) => ({
  type: actionTypes.SET_HOME_IMAGES_LOADING,
  payload: loading,
});
export const setHomeImages = (images) => ({
  type: actionTypes.SET_HOME_IMAGES,
  payload: images,
});

export const fetchHomeImages = () => (dispatch) => {
  dispatch(setHomeImagesLoading(true));
  requestAgent
    .get(Api.ui.getHomeImages())
    .then(({ body }) => {
      const c = body.res_data.data.images || null;
      dispatch(setHomeImages(shuffleArray(c)));
      dispatch(setHomeImagesLoading(false));
    })
    .catch((err) => {
      console.log(err);
      dispatch(setHomeImagesLoading(false));
    });
};

export const showLoginDialog = () => (dispatch) => {
  dispatch({ type: actionTypes.SHOW_LOGIN_DIALOG });
};

export const hideLoginDialog = () => (dispatch) => {
  dispatch({ type: actionTypes.HIDE_LOGIN_DIALOG });
};

export const showSignupDialog = () => (dispatch) => {
  dispatch({ type: actionTypes.SHOW_SIGNUP_DIALOG });
};

export const hideSignupDialog = () => (dispatch) => {
  dispatch({ type: actionTypes.HIDE_SIGNUP_DIALOG });
};

export const showCartnDialog = () => (dispatch) => {
  dispatch({ type: actionTypes.SHOW_CART_DIALOG });
};

export const hideCartDialog = () => (dispatch) => {
  dispatch({ type: actionTypes.HIDE_CART_DIALOG });
};