import React from "react";
import PropTypes from 'prop-types';

export default function Loader({ visible }) {
  if (!visible) return null;

  return (
    <div id="loader-wrapper" display="none">
      <div id="loader"></div>
    </div>
  );
}

Loader.propTypes = {
 visible: PropTypes.bool,
}