import React, { useEffect, useState } from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import PropTypes from "prop-types";
import { Select, MenuItem, Button, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { getImagePath } from "shared/utils/helpers";
import moment from "moment";
import Gallery from "components/Gallery/Gallery";
import { ORDER_TYPE_MAP } from "shared/constants";
import { Add, Delete, Remove } from "@material-ui/icons";

const styles = {
  cardTitle: {
    marginBottom: 0,
  },
  marginTop30: {
    marginTop: "30px",
  },
  marginBottom30: {
    marginBottom: "30px",
  },
  paddingTop30: {
    paddingTop: "30px",
  },
  recipe_description: {
    paddingTop: "20px",
  },
  recipe_summary: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "30px",
  },
  recipe_meta: {
    display: "inline-block",
    width: "30%",
    textAlign: "center",
    borderRight: "1px solid #e1e7ec",
    "&:last-child": {
      borderRight: "none",
    },
  },
  meta_title: {
    fontSize: "2rem",
  },
  meta_description: {
    marginTop: "10px",
  },
  numberOfGuests: {
    width: "30%",
    display: "flex",
    justifyContent: "space-between",
    textAlign: "center",
  },
  quantity_select: {
    width: 100,
    textAlign: "center",
  },
  cost: {
    marginLeft: 30,
    marginTop: 20,
    fontSize: 20
  },
  inputClass: {
    textAlign: "center",
  },
  guestsLeft: {
    color: "red",
    fontWeight: "bold",
    marginTop: 15,
    marginLeft: 40,
  },
};
const useStyles = makeStyles(styles);

export default function Details({
  selectedEvent,
  numberOfGuests,
  costPerGuest,
  onAddToCart,
  submitButtonTitle,
  onChange,
  cartItems
}: any) {
  const [cartItemState, setCartItemState] = useState(null);
  const classes = useStyles();

  const checkItem = () => {
    const check = cartItems?.filter(e => e.title === selectedEvent.title)
    if (check?.length > 0) {
      // for (let i = 0; i < cartItems.length; i++) {
      //   const element = cartItems[i];
      //   if (element?.title === selectedEvent.title) {
      setCartItemState(check[0])
      //   }
      // }
      // } else {
      //   setCartItemState(null)
    }

  }

  useEffect(() => {
    checkItem()
    // eslint-disable-next-line
  }, [cartItems])

  console.log('cartItemState', cartItemState);
  return (
    <GridContainer>
      <GridItem md={6}>
        <h2 className={classes.cardTitle}>{selectedEvent.title}</h2>
        <div className={classes.marginBottom30}>
          <p
            className={classes.recipe_description}
            dangerouslySetInnerHTML={{
              __html: selectedEvent.event_description,
            }}
          />
        </div>
        {selectedEvent.event_date && (
          <div style={{ marginBottom: 15 }}>
            <p>
              Event Date:{" "}
              <strong>
                {moment
                  .utc(selectedEvent.event_date)
                  .format("dddd MMMM DD , YYYY hh:mm a")}
              </strong>
            </p>
          </div>
        )}
        <div style={{ marginBottom: 15 }}>
          <p>
            Event Location: <strong>Irvine, California</strong>
          </p>
        </div>
        {selectedEvent.order_type && (
          <div style={{ marginBottom: 15 }}>
            <p>
              Event Type:{" "}
              <strong>{ORDER_TYPE_MAP[selectedEvent.order_type]}</strong>
            </p>
          </div>
        )}

        {!(
          selectedEvent.event_type === "culinary_hosting" &&
          ["pickup_only", "pickup_delivery"].indexOf(
            selectedEvent.order_type
          ) !== -1
        ) && (
            <div>
              <div style={{ display: "flex", flexDirection: 'row', alignItems: "center", marginBottom: 20 }}>
                {selectedEvent.menuClass &&
                  selectedEvent.menuClass.order_limit > 0 && !cartItemState?.quantity && (
                    <>
                      <span style={{ marginRight: 10 }}>Qty:</span>
                      <Select
                        className={classes.quantity_select}
                        value={numberOfGuests}
                        onChange={(e) => onChange({ quantity: e.target.value })}
                      >
                        {Array.from(
                          Array(
                            (selectedEvent.menuClass &&
                              selectedEvent.menuClass.order_limit < 10 &&
                              selectedEvent.menuClass.order_limit) ||
                            10
                          )
                        )
                          .map((_, i) => i + 1)
                          .map((q) => (
                            <MenuItem key={`quantity-${q}`} value={q}>
                              {q}
                            </MenuItem>
                          ))}
                      </Select>
                    </>
                  )}
                {selectedEvent.event_type === "classes" && (
                  <div>
                    <p className={classes.cost} style={{ marginLeft: cartItemState?.quantity > 0 ? 0 : 30 }}>
                      Cost per guest: <strong>${costPerGuest}</strong>
                    </p>
                  </div>
                )}
              </div>

              {selectedEvent.menuClass &&
                selectedEvent.menuClass.order_limit > 0 &&
                <div className={classes.add_to_card} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  {
                    cartItemState?.quantity > 0 ?
                      <div style={{
                        backgroundColor: "rgba(221, 116, 93, 0.2)", display: 'flex', alignItems: 'center', paddingLeft: 20, paddingRight: 20, flexDirection: 'row', justifyContent: 'space-between',
                        height: 50, marginTop: "10px", width: 180, borderRadius: 8
                      }}>
                        {
                          cartItemState?.quantity === 1 ?
                            <IconButton
                              onClick={() => {
                                onAddToCart(-1)
                                setCartItemState(null)
                              }}
                            >
                              <Delete />
                            </IconButton>
                            :
                            <IconButton onClick={() => onAddToCart(-1)}>
                              <Remove />
                            </IconButton>
                        }
                        <div style={{ fontSize: 20, fontWeight: '500', color: '#000' }}>
                          {cartItemState?.quantity}
                        </div>
                        <IconButton onClick={() => onAddToCart(1)} >
                          <Add />
                        </IconButton>
                      </div>
                      :
                      <Button
                        size="large"
                        onClick={() => onAddToCart(numberOfGuests > 0 ? numberOfGuests : 1)}
                        disabled={(selectedEvent.menuClass.order_limit > 0 ? false : true)}
                        style={{ backgroundColor: "#dd745d", color: "white", marginTop: "10px", width: 180, borderRadius: 8 }}
                      >
                        {submitButtonTitle}
                      </Button>
                  }
                  {/* {
                    cartQuantity > 0 &&
                    <div style={{ marginLeft: 20, width: 80, height: 30, backgroundColor: 'lightgrey', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{cartQuantity + " in cart"}</div>
                  } */}
                </div>
              }
            </div>
          )}
        {selectedEvent.menuClass &&
          selectedEvent.menuClass.order_limit > 0 ? (
          <p className={classes.guestsLeft}>
            {selectedEvent.menuClass.order_limit} seats left
          </p>
        ) : (
          <p className={classes.guestsLeft}>{!selectedEvent.menuClass ? "" : "Out of stock!"}</p>
        )}
      </GridItem>
      <GridItem md={6}>
        {selectedEvent.media && (
          <Gallery
            videoFirst
            media={selectedEvent.media.map((m) => ({
              ...m,
              url: getImagePath(m.url),
            }))}
          />
        )}
      </GridItem>
    </GridContainer>
  );
}

Details.propTypes = {
  selectedEvent: PropTypes.object,
  numberOfGuests: PropTypes.number,
  costPerGuest: PropTypes.number,
  onNumberOfGuestsChange: PropTypes.func,
  onAddToCart: PropTypes.func,
  cartQuantity: PropTypes.array,
};
