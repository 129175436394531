import * as actionTypes from "../actions/order/types";

/*
    item:
    {
        product: object,
        quantity: number
        notes: string
    }
*/
const INITIAL_STATE = {
  orderItems: [],
  userOrderItems: [],
  singleOrderItems: null,
  numberOfGuests: 1,
  loading: false,
  paypalModal: false
};

// eslint-disable-next-line
export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.SET_LOADING:
      return { ...state, loading: action.payload };
    case actionTypes.SET_PAYPAL_MODAL:
      return { ...state, paypalModal: action.payload };
    case actionTypes.SET_ORDER_ITEMS:
      return { ...state, orderItems: action.payload };
    case actionTypes.SET_USER_ORDER_ITEMS:
      return { ...state, userOrderItems: action.payload };
    case actionTypes.SET_ORDER_BYID_ITEMS:
      return { ...state, singleOrderItems: action.payload };
    case actionTypes.SET_NUMBER_OF_GUESTS:
      return { ...state, numberOfGuests: action.payload };
    default:
      return state;
  }
}
