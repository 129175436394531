import * as actionTypes from '../actions/product/types';

const INITIAL_STATE = {
    products: [],
   loading: false,
   selectedProduct: null,
}

// eslint-disable-next-line
export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case actionTypes.SET_LOADING:
            return { ...state, loading: action.payload };
        case actionTypes.SET_PRODUCTS:
            return { ...state, products: action.payload };
        case actionTypes.SET_SELECTED_PRODUCT:
            return { ...state, selectedProduct: action.payload };
        default:
            return state;
    }
}