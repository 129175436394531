import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import { fetchRecipes, fetchRecipeBySlug } from "actions/recipe";
import Ingredients from "./Ingredients";
import { withRouter } from "react-router-dom";
import Nutritions from "./Nutritions";
import Steps from "./Steps";
import Details from "./Details";
import Admin from "layouts/Admin";
import Separator from "components/Separator";
import Loader from "components/Loader";

const styles = {
  marginTop30: {
    marginTop: "30px",
  },
};
const useStyles = makeStyles(styles);

function RecipeDetails(props) {
  const classes = useStyles();
  const { id } = props.match.params;
  const { selectedRecipe, loading, fetchRecipeBySlug } = props;
  useEffect(() => {
    fetchRecipeBySlug(id);
  }, [id, fetchRecipeBySlug]);

  return (
    <Admin>
      <div>
        <Loader visible={loading} />
        <div style={{ padding: "1rem", color: "rgba(0, 0, 0, 0.87)" }}>
          {selectedRecipe ? (
            <GridContainer>
              <GridItem md={12} sm={12}>
                <Details selectedRecipe={selectedRecipe} />
              </GridItem>
              {selectedRecipe.ingredients &&
                selectedRecipe.ingredients.length > 0 && (
                  <GridItem md={12} sm={12} className={classes.marginTop30}>
                    <Separator />
                    <Ingredients
                      ingredientsMedia={selectedRecipe.ingredientsMedia}
                      ingredients={selectedRecipe.ingredients}
                    />
                  </GridItem>
                )}
              {((selectedRecipe.nutritions &&
                selectedRecipe.nutritions.length > 0) ||
                selectedRecipe.calories) && (
                <GridItem md={12} sm={12} className={classes.marginTop30}>
                  <Separator />
                  <Nutritions
                    calories={selectedRecipe.calories}
                    nutritions={selectedRecipe.nutritions}
                  />
                </GridItem>
              )}
              {selectedRecipe.steps && selectedRecipe.steps.length > 0 && (
                <GridItem md={12} sm={12} className={classes.marginTop30}>
                  <Separator />
                  <Steps steps={selectedRecipe.steps} />
                </GridItem>
              )}
            </GridContainer>
          ) : null}
        </div>
      </div>
    </Admin>
  );
}

RecipeDetails.propTypes = {
  fetchRecipes: PropTypes.func.isRequired,
  fetchRecipeBySlug: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  match: PropTypes.object,
  selectedRecipe: PropTypes.object,
};

const mapStateToProps = ({ recipe }) => ({
  loading: recipe.loading,
  selectedRecipe: recipe.selectedRecipe,
});

const mapDispatchToProps = (dispatch) => ({
  fetchRecipes: () => dispatch(fetchRecipes()),
  fetchRecipeBySlug: (id) => dispatch(fetchRecipeBySlug(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RecipeDetails));
