import ProgressLabel from "react-progress-label";
import React from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

const styles = {
  nutritions_container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexWrap: "wrap",
  },
  nutrition_item: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: "20px",
    marginRight: "25px",
  },
  nutrition_name: {
    fontWeight: "bold",
    color: "rgba(0,0,0,0.6)",
    display: "inline-block",
    marginTop: "20px",
    textAlign: "center",
  },
};
const useStyles = makeStyles(styles);

export default function Nutritions({ nutritions, calories }) {
  const classes = useStyles();
  let renderedNutritions = [];
  if (calories && calories.amount) {
    renderedNutritions.push({
      ...calories,
      name: "Calories",
    });
  }
  renderedNutritions = renderedNutritions.concat(nutritions);

  return (
    <div>
      <h3>Nutritions</h3>
      <GridContainer>
        <GridItem md={12}>
          <div>
            <ul className={classes.nutritions_container}>
              {renderedNutritions.map((nutrition, index) => (
                <li
                  key={`nutrition-${index}`}
                  className={classes.nutrition_item}
                >
                  <ProgressLabel
                    progress={nutrition.percentage}
                    fillColor="rgb(248,247,243)"
                    trackColor="#eeeeee"
                    progressColor="#3498db"
                    progressWidth={10}
                    trackWidth={16}
                    trackBorderWidth={1}
                    trackBorderColor="rgb(232,223,209)"
                    cornersWidth={5}
                    size={150}
                    text={nutrition.percentage + "%"}
                    textProps={{
                      x: "50%",
                      y: "50%",
                      dx: 8,
                      dy: 8,
                      textAnchor: "middle",
                      style: {
                        fontSize: 28,
                        fontWeight: "500",
                        fill: "#3498db",
                      },
                    }}
                  />
                  <span className={classes.nutrition_name}>
                    {nutrition.amount} <br /> {nutrition.name}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
}

Nutritions.propTypes = {
  nutritions: PropTypes.array,
  calories: PropTypes.object,
};
