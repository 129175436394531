import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { NavLink, Link } from "react-router-dom";
import { connect } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

// @material-ui/icons
import Menu from "@material-ui/icons/Menu";

//import School from "@material-ui/icons/School";
import Event from "@material-ui/icons/Event";
import { ReactComponent as MealsIcon } from 'assets/img/dinner_dining-white-24dp.svg';
import PersonIcon from "@material-ui/icons/Person";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
// core components
import Button from "components/CustomButtons/Button";
import Sidebar from "components/Sidebar/Sidebar";
import LoginDialog from "components/Login/Login";
import SignupDialog from "components/Signup/Signup";
import CartDialog from "components/Cart/CartDialog";
import logo from "assets/img/logo-primary.png";
import styles from "assets/jss/material-dashboard-pro-react/components/authNavbarStyle.js";
import dashRoutes from "routes";
import { getCartFromLocal } from "actions/cart";
import Avatar from '@material-ui/core/Avatar';

import { showLoginDialog } from "actions/ui";

const useStyles = makeStyles(styles);

const NAV_ROUTES = [
  // {
  //   title: 'Home',
  //   NavIcon: HomeIcon,
  //   path: '/home'
  // },
  // {
  //   title: "Recipes",
  //   NavIcon: LocalDining,
  //   path: "/app/recipes",
  // },
  {
    title: "Events",
    NavIcon: Event,
    path: "/app/events",
  },
  // {
  //   title: "Shop",
  //   NavIcon: Store,
  //   path: "/app/shop",
  // },
  {
    title: "Fresh Meals",
    NavIcon: MealsIcon,
    path: "/app/freshmeals",
  },
];
function AuthNavbar({ color, inverted, numberOfCartItems, getCartFromLocal, itemsAdded, showLoginDialog }) {

  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (itemsAdded) {
      getCartFromLocal()
    }
    // eslint-disable-next-line
  }, [itemsAdded])

  const handleDrawerToggle = () => {
    setOpen(!open);
  };
  // verifies if routeName is the one active (in browser input)
  const classes = useStyles();
  const appBarClasses = cx({
    [" " + classes[color]]: color,
  });

  let user = null;
  if (window.localStorage.getItem("user")) {
    user = JSON.parse(window.localStorage.getItem("user"))
  }
  var list = (inverted) => (
    <List className={classes.list}>
      {NAV_ROUTES.map(({ path, NavIcon, title }, i) => (
        <ListItem key={`nav-item-${i}`} className={classes.listItem}>
          <NavLink
            to={path}
            className={`${classes.navLink} ${inverted ? classes.navLinkInverted : ""
              }`}
            activeClassName={
              inverted ? classes.navLinkInvertedActive : classes.navLinkActive
            }
          >
            <NavIcon className={classes.listItemIcon} />
            <ListItemText
              primary={title}
              disableTypography={true}
              className={classes.listItemText}
            />
          </NavLink>
        </ListItem>
      ))}
    </List>
  );
  const mdContent = (
    <React.Fragment>
      <Hidden smDown>
        <div className={classes.flex}>
          <Link to="/">
            <Button
              className={`${classes.title} ${inverted &&
                classes.titleInverted}`}
              color="transparent"
            >
              <strong>Fresh</strong>
              <span style={{ fontWeight: 100, opacity: 0.5 }}>table</span>
            </Button>
          </Link>
        </div>
        <div className={classes.flex} style={{ flexGrow: 750 }} />
        <div className={classes.flex} style={{ flexGrow: 1000 }}>{list(inverted)}</div>
        <div className={classes.flex}>
          <List className={classes.list} style={{ justifyContent: "flex-end", alignItems: 'center' }}>

            <ListItem className={classes.listItem}>
              {
                user ?
                  <>
                    {/* user logged in */}
                    <NavLink
                      className={`${classes.navLink} ${inverted ? classes.navLinkInverted : ""
                        } `}

                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      to="/profile"
                    >
                      {
                        (user && user['name'] && (user['name']).trim() !== '')
                          ?
                          <>
                            <Avatar style={{ marginTop: -5 }} className={classes.avatar}>{(user['name']).charAt(0).toUpperCase()}</Avatar>
                          </>
                          :
                          <>
                            <PersonIcon style={{ fontSize: 25, cursor: "pointer" }} />
                          </>
                      }
                    </NavLink>
                  </>
                  :
                  <>
                    {/* user logged out */}
                    <NavLink
                      className={`${classes.navLink} ${inverted ? classes.navLinkInverted : ""
                        } `}
                      // eslint-disable-next-line
                      href="javascript:void(0)"
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={() => { showLoginDialog() }}
                    >
                      <PersonIcon style={{ fontSize: 25, cursor: "pointer" }} />
                    </NavLink>
                  </>
              }
            </ListItem>
            <ListItem className={classes.listItem}>
              <NavLink
                to={"/app/cart"}
                className={`${classes.navLink} ${inverted ? classes.navLinkInverted : ""
                  } `}
              >
                <div className={classes.cartNumber}>
                  <span >{numberOfCartItems}</span>
                </div>
                <ShoppingCartIcon style={{ fontSize: 25, marginTop: -10 }} />
              </NavLink>
            </ListItem>
          </List>
        </div>
      </Hidden>
      <LoginDialog />
      <SignupDialog />
      <CartDialog />
    </React.Fragment >
  );
  return (
    <AppBar position="static" className={classes.appBar + appBarClasses}>
      <Toolbar className={inverted ? classes.navbarInverted : ""}>
        {mdContent}
        <Hidden mdUp>
          <div className={classes.flex}>
            <Link to="/">
              <Button
                href="#"
                className={`${classes.title} ${inverted &&
                  classes.titleInverted}`}
                color="transparent"
              >
                <strong>Fresh</strong>
                <span style={{ fontWeight: 200, opacity: 0.5 }}>table</span>
              </Button>
            </Link>
          </div>
        </Hidden>
        <Hidden mdUp>
          <List className={classes.list} style={{ justifyContent: "flex-end", alignItems: 'center', marginRight: -20 }}>
            <ListItem className={classes.listItem}>
              <NavLink
                to={"/app/cart"}
                className={`${classes.navLink} ${inverted ? classes.navLinkInverted : ""
                  } `}
              >
                <div className={classes.cartNumber}>
                  <span >{numberOfCartItems}</span>
                </div>
                <ShoppingCartIcon style={{ fontSize: 25, marginTop: -10 }} />
              </NavLink>
            </ListItem>
          </List>
          <Button
            className={classes.sidebarButton}
            style={{ marginRight: -10}}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <Menu style={{ color: inverted ? "white" : "white" }} />
          </Button>
        </Hidden>

        <Hidden mdUp>
          <Sidebar
            routes={dashRoutes}
            logoText={"Freshtable"}
            handleDrawerToggle={handleDrawerToggle}
            logo={logo}
            open={open}
            color={"white"}
            bgColor={"white"}
          />
          <div></div>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}


const mapStateToProps = ({ cart }) => ({
  numberOfCartItems: cart.numberOfCartItems,
  itemsAdded: cart.itemsAdded,
});

const mapDispatchToProps = (dispatch) => ({
  getCartFromLocal: () => dispatch(getCartFromLocal()),
  showLoginDialog: () => dispatch(showLoginDialog()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthNavbar);


AuthNavbar.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  brandText: PropTypes.string,
  inverted: PropTypes.bool,
  headerPanel: PropTypes.bool,
  itemsAdded: PropTypes.bool,
  numberOfCartItems: PropTypes.number,
  getCartFromLocal: PropTypes.func,
  showLoginDialog: PropTypes.func,
};