import { combineReducers } from 'redux'
import recipe from './recipe';
import event from './event';
import product from './product';
import meal from './meal';
import order from './order';
import ui from './ui';
import cart from './cart';
import snackbar from './snackbar';
import admin from '../views/Admin/modules/reducer';
import signup from '../components/Signup/modules/reducer';
import login from '../components/Login/modules/reducer';
import setpassword from '../views/SetPassword/modules/reducer';

export default function createRootReducer(additionalReducers = {}) {
  const reducers = {
    // state: (state = {}) => state,
    recipe,
    event,
    product,
    meal,
    order,
    admin,
    ui,
    signup,
    login,
    setpassword,
    cart,
    snackbar
  }

  return combineReducers(Object.assign({}, additionalReducers, reducers))
}
