import React from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Gallery from "components/Gallery/Gallery";
import { getImagePath } from "shared/utils/helpers";

const styles = {
  cardTitle: {
    marginBottom: 0,
  },
  marginTop30: {
    marginTop: "30px",
  },
  marginBottom30: {
    marginBottom: "30px",
  },
  marginLeft30: {
    marginLeft: "30px",
  },  
  marginLeft50: {
    marginLeft: "50px",
  },
  paddingTop30: {
    paddingTop: "30px",
  },
  recipe_description: {
    paddingTop: "20px",
  },
  recipe_summary: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "30px",
  },
  menuItem: {
    marginBottom: "1rem",
  },
};
const useStyles = makeStyles(styles);
export default function AboutThisItem({ item }) {
  const classes = useStyles();

  return (
    <div className={classes.marginTop30}>
      <GridContainer>
        <GridItem md={6}>
          <h2 className={[classes.marginLeft30,classes.cardTitle].join(" ")}>About this item</h2>
          <div
            dangerouslySetInnerHTML={{ __html: item.item_description }}
            className={[classes.marginLeft50,classes.marginBottom30].join(" ")}
          ></div>
        </GridItem>        
        <GridItem md={6}>
          {item.media && (
            <Gallery
              videoFirst
              media={item.media.map((m) => ({
                ...m,
                url: getImagePath(m.url),
              }))}
            />
          )}
        </GridItem>

      </GridContainer>
    </div>
  );
}

AboutThisItem.propTypes = {
  item: PropTypes.object,
};
