import React from "react";
import BackgroundSlider from "react-background-slider";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import HomeNavbar from "components/Navbars/HomeNavbar";
import Footer from "components/Footer/Footer.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";
import { fetchHomeImages } from "actions/ui";
import { connect } from "react-redux";
import Loader from "components/Loader";

const useStyles = makeStyles(styles);

const BG_CHANGE_INTERVAL = 5;

function Home(props: any) {
  const { loading, fetchHomeImages, homeImages, ...rest } = props;
  // ref for the wrapper div
  const wrapper = React.createRef();
  // styles
  const classes = useStyles();
  React.useEffect(() => {
    fetchHomeImages();
    document.body.style.overflow = "unset";
    return function cleanup() { };
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      <HomeNavbar {...rest} />
      <div className={classes.wrapper} ref={wrapper}>
        {/* PRELOAD BG_IMAGES */}

        <div className={classes.fullPage}>
          {(!homeImages || homeImages.length === 0) && loading && <Loader visible={loading} />}
          {homeImages && (
            <BackgroundSlider
              images={homeImages}
              duration={BG_CHANGE_INTERVAL}
              transition={1}
            />
          )}
          {rest.children}
          {/* <Switch>
            {getRoutes(routes)}
          </Switch> */}
          <Footer transparent />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ ui }) => ({
  homeImages: ui.homeImages,
  loading: ui.homeImagesLoading,
});
const mapDispatchToProps = (dispatch) => ({
  fetchHomeImages: () => dispatch(fetchHomeImages()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);
