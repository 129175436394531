import React, { useEffect } from "react";
import swal from "@sweetalert/with-react";

function PayPalCancel() {

  useEffect(() => {
    swal({
      content: <div style={{ marginTop: 30 }}>
        <div style={{ marginBottom: 20 }}>
          Payment cancel please try again
        </div>
      </div>,
      icon: "warning",
      button: false
    });
  }, []);

  return (
    <div>
    </div>
  );
}



export default PayPalCancel;