export const MODULE_NAME = "login";

export const LOGIN = `${MODULE_NAME}/LOGIN`;
export const LOGIN_FAIL = `${MODULE_NAME}/LOGIN_FAIL`;
export const LOGIN_SUCCESS = `${MODULE_NAME}/LOGIN_SUCCESS`;
export const LOGIN_GOOGLE = `${MODULE_NAME}/LOGIN_GOOGLE`;
export const LOGIN_GOOGLE_FAIL = `${MODULE_NAME}/LOGIN_GOOGLE_FAIL`;
export const LOGIN_GOOGLE_SUCCESS = `${MODULE_NAME}/LOGIN_GOOGLE_SUCCESS`;
export const LOGIN_FACEBOOK = `${MODULE_NAME}/LOGIN_FACEBOOK`;
export const LOGIN_FACEBOOK_FAIL = `${MODULE_NAME}/LOGIN_FACEBOOK_FAIL`;
export const LOGIN_FACEBOOK_SUCCESS = `${MODULE_NAME}/LOGIN_FACEBOOK_SUCCESS`;
export const LOGOUT = `${MODULE_NAME}/LOGOUT`;