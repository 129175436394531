import React, { useState, useEffect } from "react";
import swal from "@sweetalert/with-react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getCartFromLocal } from "actions/cart";
import { orderSubmitPaypalSuccess } from "actions/order";
import { withRouter } from "react-router-dom";
import Loader from "components/Loader";

function PayPalSuccess({ orderSubmitPaypalSuccess, getCartFromLocal, cartItems }) {

  const [email, setEmail] = useState('');
  const [tableData, settableData] = useState([]);
  useEffect(() => {
    if (tableData.length) {
    onCnfirm()
    swal({
      content: <div style={{ marginTop: 30, height: 300, backgroundColor: '#fff' }}>
        <Loader visible={true} />
        <div style={{ color: '#000', position: 'relative', paddingTop: 250, zIndex: 5555555 }}>
          Payment is in process please wait!
        </div>
      </div>,
      icon: "",
      button: false
    });
    }
    // eslint-disable-next-line
  }, [tableData]);
  useEffect(() => {
    getCartFromLocal()
    const userEmail = localStorage.getItem('userEmail')
    setEmail(userEmail)
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    if (cartItems.length && email) {
      settableData(cartItems)
    }
    // eslint-disable-next-line
  }, [cartItems])

  const totalPrice = () => {
    let total = 0
    if (tableData) {
      for (let i = 0; i < tableData.length; i++) {
        const element = tableData[i];
        if (element?.meal_items?.length) {

          for (let j = 0; j < element.meal_items.length; j++) {
            const element1 = element.meal_items[j];

            total = total + (Number(element1.meal_item_price ? element1.meal_item_price : element.event_price ? element.event_price : element.price) * (element1.quantity ? element1.quantity : 0))
          }
        } else if (element?.menuCulinaries?.length) {
          for (let j = 0; j < element.menuCulinaries.length; j++) {
            const element1 = element.menuCulinaries[j];

            total = total + (Number(element1.price ? element1.price : element.event_price ? element.event_price : element.price) * (element1.quantity ? element1.quantity : 0))
          }
        }
        else if (element?.event_type === 'classes') {
          total = total + (Number(element.event_price) * (element.quantity))
        }
      }
      return total.toFixed(2)
    }
  }


  const onCnfirm = () => {

    let products = []
    let item_type = ""
    for (let k = 0; k < tableData.length; k++) {
      const element = tableData[k];
      let value = {}
      if (element.meal_title) {
        value = {
          meal: element._id,
          event: element._id,
          meal_title: element.meal_title,
          meal_items: element.meal_items,
          slug: element.slug,
          item_type: "meals",
          notes: element.notes ? element.notes : '',
          meal_date: element.meal_date,
          meal_order_type: element.meal_order_type,
        }
      } else if (element.event_type === 'culinary_hosting') {
        value = {
          meal: element._id,
          event: element._id,
          title: element.title,
          menuCulinaries: element.menuCulinaries,
          slug: element.slug,
          item_type: "events",
          event_type: "culinary_hosting",
          notes: element.notes ? element.notes : '',
          event_date: element.event_date,
          order_type: element.order_type,
        }
      }
      else {
        value = {
          meal: element._id,
          event: element._id,
          title: element.title,
          menuClass: element.menuClass,
          quantity: element.quantity,
          event_price: element.event_price,
          slug: element.slug,
          item_type: "events",
          event_type: element.event_type,
          notes: element.notes ? element.notes : '',
          event_date: element.event_date,
          order_type: element.order_type,
          date: element.date ? element.date : '',
          time: element.time ? element.time : '',
        }
      }
      products.push(value)
    }
    const eventsType = products.some(e => e.item_type === 'events')
    const productType = products.some(e => e.item_type === 'products')
    if (eventsType && productType) {
      item_type = "events_products"
    } else if (eventsType) {
      item_type = "events"
    } else {
      item_type = "products"
    }
    const urlParams = new URLSearchParams(window.location.search);
    const PayerID = urlParams.get('PayerID');
    const paymentId = urlParams.get('paymentId');
    const values = {
      "email": email,
      "payerId": PayerID,
      "paymentId": paymentId,
      "amount": Number(totalPrice()).toFixed(2),
      "items": products,
      "item_type": item_type
    }
    orderSubmitPaypalSuccess(values)
    // window.close()
  }

  return (
    <div>
    </div>
  );
}



const mapStateToProps = ({ product, order, cart }) => ({
  loading: order.loading,
  itemsAdded: cart.itemsAdded,
  cartItems: cart.cartItems,
  email: order.email,
});

const mapDispatchToProps = (dispatch) => ({
  getCartFromLocal: () => dispatch(getCartFromLocal()),
  orderSubmitPaypalSuccess: (params) => dispatch(orderSubmitPaypalSuccess(params)),
});

PayPalSuccess.propTypes = {
  loading: PropTypes.any,
  orderSubmitPaypalSuccess: PropTypes.func,
  getCartFromLocal: PropTypes.func,
  cartItems: PropTypes.any,
  itemsAdded: PropTypes.any,
  email: PropTypes.any,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PayPalSuccess));