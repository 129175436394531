/*!

=========================================================
* Material Dashboard PRO React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ConnectedRouter } from "connected-react-router";

import { store, persistor, history } from "./store";

import { Route, Switch } from "react-router-dom";

// import AuthLayout from "layouts/Auth.js";
import "assets/scss/material-dashboard-pro-react.scss?v=1.8.0";
import "react-image-gallery/styles/scss/image-gallery.scss";
import ScrollToTop from "containers/ScrollToTop";

import routes from "./routes";
import { setGoogleAnalyticsPage } from "shared/utils/helpers";
import SuccessSnackbar from "./snackBar";

history.listen((location) => {
  // Update page for google analytics
  setGoogleAnalyticsPage(location.pathname);
});

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <ScrollToTop />
        <SuccessSnackbar />
        <Switch>
          <div>
            {routes.map((prop, key) => {
              return (
                <Route
                  exact={prop.exact}
                  path={prop.path}
                  component={prop.component}
                  key={key}
                />
              );
            })}
          </div>
          {/* <Route  path="/rtl" component={RtlLayout} />
          <Route path="/app" component={AdminLayout} />
          <Route path="/" component={HomeLayout} />
          <Redirect from="/" to="/app/recipes" /> */}
        </Switch>
      </ConnectedRouter>
      {/* <NotificationContainer /> */}
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
