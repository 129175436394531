// import { store } from '../store';
const request = require('superagent-use')(require('superagent'));

// const ENVIRONMENT = process.env.NODE_ENV;

request.use((req) => {
  const token = window.localStorage.getItem('x-access-token');
  if (token) {
    req.header['x-access-token'] = token;
  }
  return req;
});

export default request;
