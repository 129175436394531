import * as actionTypes from "./types";
import Api from "../../shared/api";
import requestAgent from "../../shared/requestAgent";
import swal from "sweetalert";

export const actionCreator = () => ({});

export const setNumberOfGuests = (no) => ({
  type: actionTypes.SET_NUMBER_OF_GUESTS,
  payload: no,
});

export const setOrderItems = (orderItems) => ({
  type: actionTypes.SET_ORDER_ITEMS,
  payload: orderItems,
});

export const setLoading = (loading) => ({
  type: actionTypes.SET_LOADING,
  payload: loading,
});

export const setPaypalModal = (loading) => ({
  type: actionTypes.SET_PAYPAL_MODAL,
  payload: loading,
});


export const setUserOrderItems = (orderItems) => ({
  type: actionTypes.SET_USER_ORDER_ITEMS,
  payload: orderItems,
});

export const setOrderByIDItems = (orderItems) => ({
  type: actionTypes.SET_ORDER_BYID_ITEMS,
  payload: orderItems,
});

export const updateOrderItem = (index, changes) => (dispatch, getState) => {
  const { orderItems } = getState().order;

  orderItems[index] = { ...orderItems[index] };
  Object.keys(changes).forEach((key) => {
    orderItems[index][key] = changes[key];
  });
  dispatch(setOrderItems(orderItems.slice()));
};



export const createMealOrder = ({ ...rest }) => (
  dispatch
) => {
  requestAgent
    .post(Api.order.createMealOrder(), {
      ...rest
    })
    .then((res) => {
      console.log(res.body);
      const { body } = res;
      const { success, res_text } = body;
      if (success) {
        swal({
          text: "Your order has been placed successfully!",
          icon: "success",
        })
          .then(() => {
            window.location.href = "/app/freshmeals";
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        swal({
          text: res_text,
          icon: "error",
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
export const createProductOrder = ({ ...rest }) => (
  dispatch
) => {
  requestAgent
    .post(Api.order.createProductOrder(), {
      ...rest
    })
    .then((res) => {
      console.log(res.body);
      const { body } = res;
      const { success, res_text } = body;
      if (success) {
        swal({
          text: "Your order has been placed successfully!",
          icon: "success",
        })
          .then(() => {
            window.location.href = "/app/shop";
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        swal({
          text: res_text,
          icon: "error",
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
export const createEventOrder = ({ orderItems, ...rest }) => () => {
  // dispatch(setLoading(true));
  requestAgent
    .post(Api.order.createEventOrder(), {
      orderItems: orderItems.map((order) => ({
        ...order,
        product: order.product,
      })),
      ...rest,
    })
    .then((res) => {
      console.log(res.body);
      const { body } = res;
      const { success, res_text } = body;
      if (success) {
        // dispatch(setOrderItems([]));
        swal({
          text: "Your order has been placed successfully!",
          icon: "success",
        })
          .then(() => {
            if (rest.itemType === "events") {
              window.location.href = "/app/events";
            } else if (rest.itemType === "products") {
              window.location.href = "/app/shop";
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        swal({
          text: res_text,
          icon: "error",
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};


export const requestEmailVerifyCode = ({ email, name }) => () => {
  requestAgent
    .post(Api.user.requestEmailVerifyCode(), {
      email,
      name
    })
    .then((res) => {
    })
    .catch((err) => {
      console.log(err);
    });
}

export const fetchOrdersByUser = id => (dispatch) => {
  dispatch(setLoading(true));
  requestAgent.get(Api.order.getByUser(id))
    .then(({ body }) => {
      const orders = body.res_data.data.orders || [];
      dispatch(setUserOrderItems(orders))
      dispatch(setLoading(false));
    })
    .catch(err => {
      console.log(err);
      dispatch(setLoading(false));
    })
}

export const orderSubmit = (values) => (dispatch) => {
  dispatch(setLoading(true));
  requestAgent.post(Api.order.createPayment(), values)
    .then((res) => {
      const { body } = res;
      const { success, res_text, res_data } = body;
      if (success) {
        let culinaries = []
        for (let i = 0; i < values.items.length; i++) {
          const element = values.items[i];
          if (element.item_type === 'events') {
            culinaries.push(element)
          }
        }
        culinaries = JSON.stringify(culinaries)
        localStorage.setItem('culinaries', culinaries)
        localStorage.removeItem('products')
        const id = res_data.data.order._id
        window.location.href = `/app/confirm-order/${id}`;
      } else {
        swal({
          text: res_text.err.message,
          icon: "error",
        });
      }
      dispatch(setLoading(false));
    })
    .catch(err => {
      console.log(err);
      dispatch(setLoading(false));
    })
}

export const orderSubmitPaypal = (values) => (dispatch) => {
  dispatch(setLoading(true));
  localStorage.setItem('userEmail', values.email)
  requestAgent.post(Api.order.createPaymentPaypal(), values)
    .then((res) => {
      const { body } = res;

      windowPopup(true, body, dispatch)
      dispatch(setPaypalModal(true));
      dispatch(setLoading(false));
    })
    .catch(err => {
      console.log(err);
      dispatch(setLoading(false));
    })
}
var win;
export const windowPopup = (openPopup, body, dispatch) => {
  if (openPopup) {
    // eslint-disable-next-line no-restricted-globals
    var left = (screen.width - 500) / 2;
    // eslint-disable-next-line no-restricted-globals
    var top = (screen.height - 600) / 4;
    win = window.open(body, 'paypal', 'width=' + 500 + ', height=' + 600 + ', top=' + top + ', left=' + left);
  }
  document.addEventListener('visibilitychange', function () {
    if (document.hidden) {
      win.close()
    }
  })
  var timer = setInterval(function () {
    const _id = localStorage.getItem('payID')
    if (win && win.closed && _id) {
      clearInterval(timer);
      window.location.href = `/app/confirm-order/${_id}`
      localStorage.removeItem('payID')
    }
  }, 1000);
}

export const closePaypalModal = () => (dispatch) => {
  win.close()
  dispatch(setPaypalModal(false));
}

export const orderSubmitPaypalSuccess = (values) => (dispatch) => {
  dispatch(setLoading(true));
  requestAgent.post(Api.order.paymentPaypalSuccess(), values)
    .then((res) => {
      const { body } = res;
      const { success, res_text, res_data } = body;
      if (success) {
        let culinaries = []
        for (let i = 0; i < values.items.length; i++) {
          const element = values.items[i];
          if (element.item_type === 'events') {
            culinaries.push(element)
          }
        }
        culinaries = JSON.stringify(culinaries)
        localStorage.setItem('culinaries', culinaries)
        localStorage.removeItem('products')
        localStorage.removeItem('userEmail')
        const id = res_data.data.order._id
        // localStorage.setItem('payID', id)

        window.location.href = `/app/confirm-order/${id}`
        // setTimeout(() => {
        // window.close()
        dispatch(setPaypalModal(false));
        // }, 2000);
      } else {
        swal({
          text: res_text.raw.message,
          icon: "error",
        });
      }
      dispatch(setLoading(false));
    })
    .catch(err => {
      console.log(err);
      dispatch(setLoading(false));
    })
}

export const getOrderById = (id) => (dispatch) => {
  dispatch(setLoading(true));
  requestAgent.get(Api.order.getOrderById(id))
    .then(({ body }) => {
      const orders = body.res_data.data.order || {};
      dispatch(setOrderByIDItems(orders))
      dispatch(setLoading(false));
    })
    .catch(err => {
      console.log(err);
      dispatch(setLoading(false));
    })
}