import 'date-fns';
import React from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import PropTypes from "prop-types";
import { Button, Grid, Select, MenuItem, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Gallery from "components/Gallery/Gallery";
import { getImagePath } from "shared/utils/helpers";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment'


const styles = {
  cardTitle: {
    marginBottom: 0,
  },
  marginTop30: {
    marginTop: "30px",
  },
  marginBottom30: {
    marginBottom: "30px",
  },
  paddingTop30: {
    paddingTop: "30px",
  },
  recipe_description: {
    paddingTop: "20px",
  },
  recipe_summary: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "30px",
  },
  recipe_meta: {
    display: "inline-block",
    width: "30%",
    textAlign: "center",
    borderRight: "1px solid #e1e7ec",
    "&:last-child": {
      borderRight: "none",
    },
  },
  meta_title: {
    fontSize: "2rem",
  },
  meta_description: {
    marginTop: "10px",
  },
  addToCartButton: {
    padding: "1rem 2rem",
    opacity: "0.9",
  },
  quantity: {
    marginTop: 30,
  },
  quantity_select: {
    width: 100,
    textAlign: "center",
  },
  special_requests: {
    marginTop: 30,
  },
  textArea: {
    width: "80%",
    height: 80,
    padding: "1rem",
  },
  guestsLeft: {
    color: "red",
    fontWeight: "bold",
    marginTop: 20,
  },
  add_to_card: {
    marginTop: 30,
  }
};

const timeRange = [

  "05:00 pm - 06:00 pm",
  "06:00 pm - 07:00 pm",

]
const useStyles = makeStyles(styles);

export default function Details({
  selectedProduct,
  notes,
  quantity,
  onChange,
  onAddToCart,
  subtotal,
  submitButtonTitle
}) {
  const classes = useStyles();
  const [state, setstate] = React.useState({
    date: new Date(),
    time: "05:00 pm - 06:00 pm",
  });

  const handleDateChange = (date, type) => {
    if (type === 'date') {
      setstate(pre => ({
        ...pre,
        selectedDate: date,
        [type]: moment(date).format('DD-MM-YYYY')
      }))
    } else {
      setstate(pre => ({
        ...pre,
        [type]: date.target.value
      }))
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <GridContainer>
        <GridItem md={6}>
          <h2 className={classes.cardTitle}>{selectedProduct.title}</h2>
          <div className={classes.marginBottom30}>
            <p
              className={classes.recipe_description}
              dangerouslySetInnerHTML={{
                __html: selectedProduct.description,
              }}
            />
            <div>
              <div className={classes.meta_title}>
                $
              {parseInt(selectedProduct.price) === selectedProduct.price
                  ? `${selectedProduct.price}.00`
                  : selectedProduct.price}
                {selectedProduct.price_text && (
                  <span style={{ marginLeft: 10 }}>
                    <small>{selectedProduct.price_text}</small>
                  </span>
                )}
              </div>
              <div className={classes.quantity}>
                <div>
                  <p>
                    {selectedProduct.order_limit > 0 && (
                      <React.Fragment>
                        <span style={{ marginRight: "1rem" }}>Qty:</span>
                        <Select
                          className={classes.quantity_select}
                          value={quantity}
                          onChange={(e) => onChange({ quantity: e.target.value })}
                        >
                          {Array.from(Array(selectedProduct.order_limit)).map((_, index) => index + 1).map((q) => (
                            <MenuItem key={`quantity-${q}`} value={q}>
                              {q}
                            </MenuItem>
                          ))}
                        </Select>
                      </React.Fragment>
                    )}
                  </p>
                  {selectedProduct.order_limit > 0 ? (
                    <p className={classes.guestsLeft}>
                       {selectedProduct.order_limit} items left 
                    </p>
                  ) : (
                    <p className={classes.guestsLeft}></p>
                  )}
                </div>
              </div>
              {selectedProduct.order_limit > 0 &&
                <div className={classes.add_to_card}>
                  <Typography>Delivery/Pickup - Date & Time:</Typography>
                  <Grid container alignItems={"center"} style={{ marginBottom: 20 }}>
                    <KeyboardDatePicker
                      disableToolbar
                      autoOk
                      style={{ width: 150 }}
                      variant="inline"
                      format="MM/dd/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label=""
                      value={state.selectedDate}
                      onChange={(e) => handleDateChange(e, 'date')}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                    <Select
                      value={state.time}
                      style={{ marginTop: 7, marginLeft: 5, marginRight: 5 }}
                      onChange={(e) => handleDateChange(e, 'time')}>
                      {
                        timeRange.map((time, index) => (
                          <MenuItem key={index} value={time}>{time}</MenuItem>
                        ))
                      }
                    </Select>
                  </Grid>
                  <Button
                    size="large"
                    onClick={() => onAddToCart({ email: 'fahadhussain368@gmail.com', name: 'fahad', selectedProduct, date: state.date, time: state.time })}
                    disabled={!state.date || !state.time || !subtotal || (selectedProduct.order_limit > 0 ? false : true)}
                    style={{ backgroundColor: !subtotal ? 'gray' : "#3fbfba", color: "white", marginLeft: "10px", }}
                  >
                    {submitButtonTitle}
                  </Button>
                </div>
              }
              {/* <div className={classes.special_requests}>
              <p>
                <strong>List any special requests</strong>
              </p>
              <textarea
                onChange={(e) => onChange({ notes: e.target.value })}
                value={notes}
                className={classes.textArea}
                placeholder="e.g. allergies, extra spicy, etc."
              />
            </div> */}
            </div>
          </div>
        </GridItem>
        <GridItem md={6}>
          {selectedProduct.media && (
            <Gallery
              videoFirst
              media={selectedProduct.media.map((m) => ({
                ...m,
                url: getImagePath(m.url),
              }))}
            />
          )}
        </GridItem>
      </GridContainer>
    </MuiPickersUtilsProvider>
  );
}

Details.propTypes = {
  selectedProduct: PropTypes.object,
  quantity: PropTypes.number,
  notes: PropTypes.string,
  onChange: PropTypes.func,
  onAddToCart: PropTypes.func,
  subtotal: PropTypes.number,
  submitButtonTitle: PropTypes.string,
};
