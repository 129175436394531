import { createBrowserHistory } from 'history'
import { createStore, applyMiddleware, compose } from 'redux'
import { createLogger } from 'redux-logger'
import reduxThunk from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { connectRouter, routerMiddleware } from 'connected-react-router'


import createRootReducer from './reducers'


function configureStore(rootReducer, initialState, middlewares = {}) {
  const persistedReducer = persistReducer(persistConfig, rootReducer)

  const store = createStore(
    persistReducer(persistConfig, persistedReducer),
    initialState,
    composeEnhancers(middlewares),
  )
  const persistor = persistStore(store)

  return { store, persistor }
}

const persistConfig = {
  key: 'store_root',
  storage,
  whitelist: ['admin']
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const history = createBrowserHistory();
const useLogger = process.env.NODE_ENV === 'development'
const middlewares = useLogger
  ? applyMiddleware(reduxThunk, routerMiddleware(history), createLogger())
  : applyMiddleware(reduxThunk, routerMiddleware(history))

    
const rootReducer = createRootReducer({ router: connectRouter(history) })

const { store, persistor } = configureStore(rootReducer, {}, middlewares)

export {
  store,
  persistor,
  history,
}
