import React from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Gallery from "components/Gallery/Gallery";
import { getImagePath } from "shared/utils/helpers";

const styles = {
  cardTitle: {
    marginBottom: 0,
  },
  marginTop30: {
    marginTop: "30px",
  },
  marginBottom30: {
    marginBottom: "30px",
  },
  paddingTop30: {
    paddingTop: "30px",
  },
  recipe_description: {
    paddingTop: "20px",
  },
  recipe_summary: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "30px",
  },
  menuItem: {
    marginBottom: "1rem",
  },
};
const useStyles = makeStyles(styles);
export default function ClassMenu({ menu }) {
  const classes = useStyles();

  return (
    <div className={classes.marginTop30}>
      <GridContainer>
        <GridItem md={6}>
          {menu.media && (
            <Gallery
              videoFirst
              media={menu.media.map((m) => ({
                ...m,
                url: getImagePath(m.url),
              }))}
            />
          )}
        </GridItem>
        <GridItem md={6}>
          <h2 className={classes.cardTitle}>Menu</h2>
          <div
            dangerouslySetInnerHTML={{ __html: menu.menu_description }}
            className={classes.marginBottom30}
          ></div>
        </GridItem>
      </GridContainer>
    </div>
  );
}

ClassMenu.propTypes = {
  menu: PropTypes.object,
};
