import React from "react";

// @material-ui/core components
import {
  Grid,
  Typography,
} from "@material-ui/core";
import Admin from "layouts/Admin";


export default function EmailActivation() {

  return (
    <Admin backgroundColor="white" containerStyles={{ alignItems: "unset" }}>
      <div style={{ width: 400, margin: "0px auto", color: "black" }}>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              style={{ color: "black", marginBottom: "2rem", fontFamily: 'proxima-nova,sans-serif' }}
              align="center"
              variant="h4"
            >
              {"You're almost finished!"}
            </Typography>
            <Typography
              style={{ color: "black", marginBottom: "1.5rem", fontFamily: 'proxima-nova,sans-serif' }}
              align="center"
              variant="h5"
            >
              Check you mail
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <p
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    borderBottom: "1px solid #eee",
                    paddingBottom: 20,
                    width: '100%'
                  }}
                ></p>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </div>
    </Admin >
  );
}

