import React from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Gallery from "components/Gallery/Gallery";
import { getImagePath } from "shared/utils/helpers";

const styles = {
  cardTitle: {
    marginBottom: 0,
  },
  marginTop30: {
    marginTop: "30px",
  },
  marginBottom30: {
    marginBottom: "30px",
  },
  paddingTop30: {
    paddingTop: "30px",
  },
  recipe_description: {
    paddingTop: "20px",
  },
  recipe_summary: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "30px",
  },
  recipe_meta: {
    display: "inline-block",
    width: "30%",
    textAlign: "center",
    borderRight: "1px solid #e1e7ec",
    "&:last-child": {
      borderRight: "none",
    },
  },
  meta_title: {
    fontSize: "2rem",
  },
  meta_description: {
    marginTop: "10px",
  },
};
const useStyles = makeStyles(styles);

export default function Details({ selectedRecipe }) {
  const classes = useStyles();

  return (
    <GridContainer>
      <GridItem md={6}>
        <h2 className={classes.cardTitle}>{selectedRecipe.title}</h2>
        <div className={classes.marginBottom30}>
          <p
            className={classes.recipe_description}
            dangerouslySetInnerHTML={{ __html: selectedRecipe.description }}
          />
        </div>
      </GridItem>
      <GridItem md={6}>
        {selectedRecipe.media && (
          <Gallery
            videoFirst
            media={selectedRecipe.media.map((m) => ({
              ...m,
              url: getImagePath(m.url),
            }))}
          />
        )}
      </GridItem>
    </GridContainer>
  );
}

Details.propTypes = {
  selectedRecipe: PropTypes.object,
};
