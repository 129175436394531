import * as actionTypes from './types';
import Api from '../../shared/api';
import requestAgent from '../../shared/requestAgent';

// const MODULE_NAME = 'recipe';

export const actionCreator = () => ({

});


export const setLoading = (loading) => ({
    type: actionTypes.SET_LOADING,
    payload: loading,
});

export const setRecipes = (recipes) => ({
    type: actionTypes.SET_RECIPES,
    payload: recipes,
});

export const setSelectedRecipe = recipe => ({
    type: actionTypes.SET_SELECTED_RECIPE,
    payload: recipe,
})

export const fetchRecipes = () => (dispatch) => {
    dispatch(setLoading(true));
    requestAgent.get(Api.recipe.getRecipes())
    .then(({ body }) => {
        const recipes = body.res_data.data.recipes || [];
        dispatch(setRecipes(recipes))
        dispatch(setLoading(false));
    })
    .catch(err => {
        console.log(err);
        dispatch(setLoading(false));
    })
}

export const fetchRecipeBySlug = slug => (dispatch) => {
    dispatch(setLoading(true));
    requestAgent.get(Api.recipe.getBySlug(slug))
    .then(({ body }) => {
        const recipe = body.res_data.data.recipe || null;
        dispatch(setSelectedRecipe(recipe))
        dispatch(setLoading(false));
    })
    .catch(err => {
        console.log(err);
        dispatch(setLoading(false));
    })
}

export const fetchRecipeById = id => (dispatch) => {
    dispatch(setLoading(true));
    requestAgent.get(Api.recipe.getById(id))
    .then(({ body }) => {
        const recipe = body.res_data.data.recipe || null;
        dispatch(setSelectedRecipe(recipe))
        dispatch(setLoading(false));
    })
    .catch(err => {
        console.log(err);
        dispatch(setLoading(false));
    })
}