import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { fetchMealBySlug } from "actions/meal";
import { addToCart } from "actions/cart";
import { withRouter } from "react-router-dom";
import Admin from "layouts/Admin";
import Loader from "components/Loader";
import Details from "./Details";
import AboutThisItem from "./AboutThisItem";
import MenuItems from "./MenuItems";
import Separator from "components/Separator";

import { setOrderItems } from "actions/order";
import { updateOrderItem } from "actions/order";
import { v4 as uuidv4 } from 'uuid';

import { createMealOrder, requestEmailVerifyCode } from "actions/order";

function FreshmealDetails({ selectedMeal,
  fetchMealBySlug,
  loading,
  orderItems,
  updateOrderItem,
  setOrderItems,
  createMealOrder,
  match,
  cartItems,
  numberOfCartItems,
  addToCart }: any) {
  const { id } = match.params;

  const [state, setstate] = useState({
    quantity: 1
  })
  useEffect(() => {
    fetchMealBySlug(id);
  }, [id, fetchMealBySlug]);

  useEffect(() => {
    if (selectedMeal) {
      const newOrderItems = [];
      if (
        selectedMeal.meal_items &&
        selectedMeal.meal_items.length > 0
      ) {
        selectedMeal.meal_items.forEach((product) => {
          product.quantity = product.order_limit > 0 ? 1 : 0
          newOrderItems.push({
            meal_title: selectedMeal.meal_title,
            meal_order_type: selectedMeal.meal_order_type,
            slug: selectedMeal.slug,
            meal_date: selectedMeal.meal_date,
            meal_items: [product],
            notes: "",
            _id: selectedMeal._id,
            id: uuidv4()
          });
        });
      }
      setOrderItems(newOrderItems);
    }
  }, [selectedMeal, setOrderItems]);

  const changeQuantity = (index, quantity) => {
    if (selectedMeal) {
      const newOrderItems = [];
      if (
        selectedMeal.meal_items &&
        selectedMeal.meal_items.length > 0
      ) {
        selectedMeal.meal_items.forEach((product) => {
          product.quantity = product.order_limit > 0 ? 1 : 0
          newOrderItems.push({
            meal_title: selectedMeal.meal_title,
            meal_order_type: selectedMeal.meal_order_type,
            slug: selectedMeal.slug,
            meal_date: selectedMeal.meal_date,
            meal_items: [product],
            notes: "",
            _id: selectedMeal._id,
            id: uuidv4()
          });
        });
      }
      selectedMeal.meal_items[index].quantity = quantity
      setOrderItems(newOrderItems);
    }
  }
  const onOrderSubmit = (index, i) => {
    changeQuantity(index, i);
    addToCart(orderItems[index], state.quantity, true)
  };

  return (
    <Admin>
      <Loader visible={loading} />

      <div style={{ padding: "1rem", color: "rgba(0, 0, 0, 0.87)" }}>
        {selectedMeal && (
          <React.Fragment>
            <Details
              selectedMeal={selectedMeal}
              quantity={
                orderItems && orderItems[0] ? orderItems[0].quantity : 1
              }
              note
              onChange={(changes) => {
                setstate({ ...state, quantity: changes.quantity })
                updateOrderItem(0, changes);
              }} submitButtonTitle="Add To Cart"
              // subtotal={orderItems.map((o) => o.meal.meal_item_price * o.quantity)}
              onAddToCart={onOrderSubmit}
            />
            {selectedMeal.about_this_item && (
              <div>
                <Separator />
                <AboutThisItem item={selectedMeal.about_this_item} />
              </div>
            )}
            {
              selectedMeal.meal_items && (
                <div>
                  <Separator />
                  <div style={{ marginBottom: 20 }}>
                    <h2 style={{ marginBottom: 5 }}>Menu</h2>
                    <p>
                      Please select the menu items and quantity based on your
                      guests preferences
                    </p>
                  </div>
                  {selectedMeal.meal_items.map((menu, index) => (
                    <MenuItems
                      menu={menu}
                      quantity={
                        orderItems && orderItems[index]
                          ? orderItems[index].quantity
                          : 1
                      }
                      cartItems={cartItems}
                      numberOfCartItems={numberOfCartItems}
                      key={`culinary_hosting_menu_${index}`}
                      submitButtonTitle="Add To Cart"
                      onAddToCart={(i) => onOrderSubmit(index, i)}
                      onChange={(changes) => {
                        setstate({ ...state, quantity: changes.quantity })
                        updateOrderItem(index, changes);
                        changeQuantity(index, changes.quantity)
                      }}
                    />
                  ))}
                </div>
              )}
            {orderItems && orderItems.length > 0 && (
              <div>
                <Separator />
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    </Admin>
  );
}

FreshmealDetails.propTypes = {
  fetchMealBySlug: PropTypes.func,
  addToCart: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  match: PropTypes.object,
  selectedMeal: PropTypes.object,
  numberOfCartItems: PropTypes.number,
  cartItems: PropTypes.array,
  orderItems: PropTypes.array,
  updateOrderItem: PropTypes.func,
  setOrderItems: PropTypes.func,
};

const mapStateToProps = ({ meal, order, cart }) => ({
  loading: meal.loading,
  numberOfCartItems: cart.numberOfCartItems,
  selectedMeal: meal.selectedMeal,
  cartItems: cart.cartItems,
  orderItems: order.orderItems,
});

const mapDispatchToProps = (dispatch) => ({
  addToCart: (meal, quantity, showCartDialog) => dispatch(addToCart(meal, quantity, showCartDialog)),
  fetchMealBySlug: (id) => dispatch(fetchMealBySlug(id)),
  setOrderItems: (items) => dispatch(setOrderItems(items)),
  createMealOrder: (data) => dispatch(createMealOrder(data)),
  requestEmailVerifyCode: ({ email, name }) =>
    dispatch(requestEmailVerifyCode({ email, name })),
  updateOrderItem: (index, changes) =>
    dispatch(updateOrderItem(index, changes)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FreshmealDetails));
