import * as actionTypes from "../actions/cart/types";

const INITIAL_STATE = {
  loading: false,
  itemsAdded: true,
  cartItems: [],
  numberOfCartItems: 0,
};
// eslint-disable-next-line
export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.ADD_TO_CART:
      return { ...state, loading: true, itemsAdded: false };
    case actionTypes.ADD_TO_CART_SUCCESS:
      return { ...state, cartItems: action.payload, itemsAdded: true, loading: false };
    case actionTypes.ADD_TO_CART_FAIL:
      return { ...state, loading: false, itemsAdded: false };
    case actionTypes.GET_TO_CART:
      return { ...state, loading: true, itemsAdded: false, };
    case actionTypes.GET_TO_CART_SUCCESS:
      return { ...state, cartItems: action.payload, numberOfCartItems: action.productQuantities, itemsAdded: false, loading: false };
    case actionTypes.GET_TO_CART_FAIL:
      return { ...state, loading: false, itemsAdded: false };
    case actionTypes.DELETE_TO_CART:
      return { ...state, loading: true };
    case actionTypes.DELETE_TO_CART_SUCCESS:
      return { ...state, cartItems: action.payload, numberOfCartItems: action.productQuantities, loading: false };
    case actionTypes.DELETE_TO_CART_FAIL:
      return { ...state, loading: false };
    default:
      return state;
  }
}
