const API_ROOT_V1 = process.env.REACT_APP_FT_API_ROOT_V1;

module.exports = {
  recipe: {
    getRecipes: () => `${API_ROOT_V1}/recipe`,
    getById: (id) => `${API_ROOT_V1}/recipe/${id}`,
    getBySlug: (slug) => `${API_ROOT_V1}/recipe/by_slug/${slug}`,
  },
  event: {
    getEvents: () => `${API_ROOT_V1}/event`,
    getById: (id) => `${API_ROOT_V1}/event/${id}`,
    getBySlug: (slug) => `${API_ROOT_V1}/event/by_slug/${slug}`,
  },
  product: {
    getProducts: () => `${API_ROOT_V1}/product`,
    getById: (id) => `${API_ROOT_V1}/product/${id}`,
    getBySlug: (slug) => `${API_ROOT_V1}/product/by_slug/${slug}`,
  },
  meal: {
    getMeals: () => `${API_ROOT_V1}/meal`,
    getById: (id) => `${API_ROOT_V1}/meal/${id}`,
    getBySlug: (slug) => `${API_ROOT_V1}/meal/by_slug/${slug}`,
  },  
  order: {
    createEventOrder: () => `${API_ROOT_V1}/order/event`,
    createProductOrder: () => `${API_ROOT_V1}/order/product`,
    createMealOrder: () => `${API_ROOT_V1}/order/meal`,
    updateOrderStatus: (id) => `${API_ROOT_V1}/order/${id}/order_status`,
    getAll: () => `${API_ROOT_V1}/order`,
    getOrderById: (id) => `${API_ROOT_V1}/order/${id}`,
    getByUser: (id) => `${API_ROOT_V1}/order/by_user/${id}`,
    getEvents: () => `${API_ROOT_V1}/order/event`,
    createPayment: () => `${API_ROOT_V1}/order/payment`,
    createPaymentPaypal: () => `${API_ROOT_V1}/order/paymentPaypal`,
    paymentPaypalSuccess: () => `${API_ROOT_V1}/order/paymentPaypalSuccess`,
    getEventOrders: (id) => `${API_ROOT_V1}/order/event/${id}`,
  },
  user: {
    requestEmailVerifyCode: () => `${API_ROOT_V1}/user/email_verify_code`,
    generateToken: () => `${API_ROOT_V1}/user/generateToken`,
    login: () => `${API_ROOT_V1}/user/login`,
    create: () => `${API_ROOT_V1}/user/register`,
    verifyEmail: () => `${API_ROOT_V1}/user/verifyEmail`,
    updatePassword: () => `${API_ROOT_V1}/user/updatePassword`,
    loginGoogle: () => `${API_ROOT_V1}/user/login/google`,
    loginFacebook: () => `${API_ROOT_V1}/user/login/facebook`,
  },
  ui: {
    getHomeImages: () => `${API_ROOT_V1}/ui/homeImages`
  },
};

