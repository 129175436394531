import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchEventBySlug } from "actions/event";
import { withRouter } from "react-router-dom";
import Admin from "layouts/Admin";
import Details from "./Details";
import CulinaryMenu from "./CulinaryMenu";
import ClassMenu from "./ClassMenu";
import { setOrderItems } from "actions/order";
import { updateOrderItem } from "actions/order";
import { requestEmailVerifyCode } from "actions/order";
import { createEventOrder } from "actions/order";
import { setNumberOfGuests } from "actions/order";
import Separator from "components/Separator";
import Loader from "components/Loader";
import { addToCart } from "actions/cart";
import { v4 as uuidv4 } from 'uuid';

function EventDetails({ selectedEvent,
  orderItems,
  fetchEventBySlug,
  setOrderItems,
  updateOrderItem,
  createEventOrder,
  setNumberOfGuests,
  numberOfGuests,
  requestEmailVerifyCode,
  loading,
  addToCart,
  cartItems,
  match }: any) {
  const { id } = match.params;


  const [state, setstate] = useState({
    quantity: 1
  })

  useEffect(() => {
    fetchEventBySlug(id);
    setNumberOfGuests(1);
  }, [id, fetchEventBySlug, setNumberOfGuests]);

  useEffect(() => {
    if (selectedEvent) {
      const newOrderItems = [];
      if (
        selectedEvent.menuCulinaries &&
        selectedEvent.menuCulinaries.length > 0
      ) {
        selectedEvent.menuCulinaries.forEach((product) => {
          product.quantity = product.order_limit > 0 ? 1 : 0
          newOrderItems.push({
            menuCulinaries: [product],
            media: product.media,
            title: selectedEvent.title,
            order_type: selectedEvent.order_type,
            event_type: selectedEvent.event_type,
            event_date: selectedEvent.event_date,
            slug: selectedEvent.slug,
            notes: "",
            _id: selectedEvent._id,
            id: uuidv4()
          });
        });
      } else if (selectedEvent.menuClass && selectedEvent.menuClass && selectedEvent.menuClass.order_limit > 0) {
        newOrderItems.push({
          quantity: 1,
          media: selectedEvent.media,
          title: selectedEvent.title,
          menuClass: selectedEvent.menuClass,
          order_type: selectedEvent.order_type,
          event_price: selectedEvent.event_price,
          event_type: selectedEvent.event_type,
          event_date: selectedEvent.event_date,
          slug: selectedEvent.slug,
          notes: "",
          _id: selectedEvent._id,
        });
      }
      setOrderItems(newOrderItems);
    }
  }, [selectedEvent, setOrderItems]);

  const changeQuantity = (index, quantity) => {
    if (selectedEvent) {
      const newOrderItems = [];
      if (
        selectedEvent.menuCulinaries &&
        selectedEvent.menuCulinaries.length > 0
      ) {
        selectedEvent.menuCulinaries.forEach((product) => {
          product.quantity = product.order_limit > 0 ? 1 : 0
          newOrderItems.push({
            menuCulinaries: [product],
            media: product.media,
            title: selectedEvent.title,
            order_type: selectedEvent.order_type,
            event_type: selectedEvent.event_type,
            event_date: selectedEvent.event_date,
            slug: selectedEvent.slug,
            notes: "",
            _id: selectedEvent._id,
            id: uuidv4()
          });
        });
        selectedEvent.menuCulinaries[index].quantity = quantity
      } else if (selectedEvent.menuClass && selectedEvent.menuClass && selectedEvent.menuClass.order_limit > 0) {
        newOrderItems.push({
          quantity: quantity,
          media: selectedEvent.media,
          title: selectedEvent.title,
          menuClass: selectedEvent.menuClass,
          order_type: selectedEvent.order_type,
          event_price: selectedEvent.event_price,
          event_type: selectedEvent.event_type,
          event_date: selectedEvent.event_date,
          slug: selectedEvent.slug,
          notes: "",
          _id: selectedEvent._id,
        });
      }
      setOrderItems(newOrderItems);
    }
  }


  const onOrderSubmit = (index, i) => {
    if (selectedEvent.menuCulinaries &&
      selectedEvent.menuCulinaries.length > 0) {
      changeQuantity(index, i);
      addToCart(orderItems[index], state.quantity, true)
    } else {
      changeQuantity(index, i);
      addToCart(orderItems[index], i ? i : state.quantity, true)
      setNumberOfGuests(1)
    }
  };

  let costPerGuest;
  if (selectedEvent) {
    if (selectedEvent.event_type === "classes") {
      costPerGuest = selectedEvent.event_price;
    } else if (orderItems && orderItems.length > 0 && selectedEvent.event_type === "culinary_hosting") {
      costPerGuest =
        orderItems && orderItems.length > 0
          ? orderItems.reduce((acc, o) => o.product?.price * o?.quantity + acc, 0)
          : 0;
    }
  }
  const checkQuantity = (menu_title, title) => {
    if (cartItems?.length) {
      for (let j = 0; j < cartItems?.length; j++) {
        const element = cartItems[j];

        if (element.menuClass && element.title === menu_title) {
          return element.quantity
        }
        else if (element.event_type === "culinary_hosting" && element.title === title) {
          const filtered = element?.menuCulinaries.filter(e => e.menu_title === menu_title)
          const quantity = filtered.length && filtered[0].quantity
          return quantity
        }
      }
    }
  }
  return (
    <Admin>
      <Loader visible={loading} />
      {selectedEvent && (
        <React.Fragment>
          <div style={{ padding: "1rem", color: "rgba(0, 0, 0, 0.87)" }}>
            <Details
              selectedEvent={selectedEvent}
              onChange={(changes) => {
                setstate({ ...state, quantity: changes.quantity })
                setNumberOfGuests(changes.quantity)
                changeQuantity(null, changes.quantity)
              }}
              cartItems={cartItems}
              numberOfGuests={numberOfGuests}
              costPerGuest={costPerGuest}
              cartQuantity={checkQuantity(selectedEvent.title)}
              onAddToCart={(i) => onOrderSubmit(0, i)}
              submitButtonTitle="Add To Cart"
            />
            {selectedEvent.event_type === "culinary_hosting" &&
              selectedEvent.menuCulinaries && (
                <div>
                  <Separator />
                  <div style={{ marginBottom: 20 }}>
                    <h2 style={{ marginBottom: 5 }}>Menu</h2>
                    <p>
                      Please select the menu items and quantity based on your
                      guests preferences
                    </p>
                  </div>
                  {selectedEvent.menuCulinaries.map((menu, index) => (
                    <CulinaryMenu
                      menu={menu}
                      quantity={
                        orderItems && orderItems[index]
                          ? orderItems[index].quantity
                          : 1
                      }
                      key={`culinary_hosting_menu_${index}`}
                      submitButtonTitle="Add To Cart"
                      cartItems={cartItems}
                      onAddToCart={(i) => onOrderSubmit(index, i)}
                      cartQuantity={checkQuantity(menu.menu_title, selectedEvent.title)}
                      onChange={(changes) => {
                        setstate({ ...state, quantity: changes.quantity })
                        updateOrderItem(index, changes);
                        changeQuantity(index, changes.quantity)
                      }}
                    />
                  ))}
                </div>
              )}
            {selectedEvent.event_type === "classes" && selectedEvent.menuClass && (
              <div>
                <Separator />
                <ClassMenu menu={selectedEvent.menuClass} />
              </div>
            )}
            {orderItems && orderItems.length > 0 && (
              <div>
                <Separator />
                {/* <OrderSummary
                  submitButtonTitle="SUBMIT BOOKING REQUEST"
                  title={selectedEvent.title}
                  date={selectedEvent.event_date}
                  type={ORDER_TYPE_MAP[selectedEvent.order_type] || ""}
                  subtotal={
                    selectedEvent.event_type === "classes"
                      ? costPerGuest * numberOfGuests
                      : costPerGuest
                  }
                  onSubmit={onOrderSubmit}
                /> */}
              </div>
            )}
          </div>
        </React.Fragment>
      )}
    </Admin>
  );
}

EventDetails.propTypes = {
  fetchEventBySlug: PropTypes.func,
  setOrderItems: PropTypes.func,
  createOrder: PropTypes.func,
  updateOrderItem: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  match: PropTypes.object,
  selectedEvent: PropTypes.object,
  orderItems: PropTypes.array,
  cartItems: PropTypes.array,
  numberOfGuests: PropTypes.number,
  setNumberOfGuests: PropTypes.func,
};

const mapStateToProps = ({ cart, event, order }) => ({
  loading: event.loading,
  cartItems: cart.cartItems,
  selectedEvent: event.selectedEvent,
  orderItems: order.orderItems,
  numberOfGuests: order.numberOfGuests,
});

const mapDispatchToProps = (dispatch) => ({
  fetchEventBySlug: (id) => dispatch(fetchEventBySlug(id)),
  addToCart: (product, quantity, showCartDialog) => dispatch(addToCart(product, quantity, showCartDialog)),
  setNumberOfGuests: (no) => dispatch(setNumberOfGuests(no)),
  setOrderItems: (items) => dispatch(setOrderItems(items)),
  createEventOrder: (params) => dispatch(createEventOrder(params)),
  requestEmailVerifyCode: ({ email, name }) =>
    dispatch(requestEmailVerifyCode({ email, name })),
  updateOrderItem: (index, changes) =>
    dispatch(updateOrderItem(index, changes)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EventDetails));
