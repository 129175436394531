import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import HomeNavbar from "components/Navbars/HomeNavbar";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";

const useStyles = makeStyles(styles);

export default function Admin(props) {
  const { ...rest } = props;
  // ref for the wrapper div
  const wrapper = React.createRef();
  // styles
  const classes = useStyles();
  React.useEffect(() => {
    document.body.style.overflow = "unset";
    return function cleanup() {};
  });
  let extraStyles = {};
  if (rest.backgroundColor) {
    extraStyles.backgroundColor = rest.backgroundColor;
  }
  if (rest.containerStyles) {
    extraStyles = {
      ...extraStyles,
      ...rest.containerStyles,
    };
  }
  return (
    <div>
      <HomeNavbar headerPanel={rest.headerPanel} inverted {...rest} />
      <div className={classes.wrapper} ref={wrapper}>
        <div
          style={extraStyles}
          className={`${classes.fullPage} ${classes.fullPageInverted}`}
        >
          <div className={classes.container}>
            {rest.children}
          </div>
          <Footer footerPanel={rest.footerPanel} />
        </div>
      </div>
    </div>
  );
}
