import React from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import {
  IconButton,
} from "@material-ui/core";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { hideCartDialog } from "actions/ui";
import CartContent from "./CartContent";

const useStyles = makeStyles({
  checkedIcon: {
    color: '#1ea2d5 !important'
  },
  buttonProgress: {
    color: '#fff',
  },
  closeButton: {
    position: 'absolute',
    right: 0,
  },
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

function CartDialog({ cartDialogVisible, hideCartDialog }) {
  const handleClose = () => {
    hideCartDialog()
  };
  const classes = useStyles();
  return (
    <div>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={cartDialogVisible} maxWidth={"md"}>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <CartContent pricehide={true} />
        </DialogContent>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (props) => ({
  cartDialogVisible: props.ui.cartDialogVisible,
});
CartDialog.propTypes = {
  cartDialogVisible: PropTypes.bool,
  hideCartDialog: PropTypes.func,
}
const mapDispatchToProps = (dispatch) => ({
  hideCartDialog: () => dispatch(hideCartDialog()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CartDialog);