import * as actionTypes from "./types";
import Api from "../../../shared/api";
import requestAgent from "../../../shared/requestAgent";
import swal from "sweetalert";
import { history } from "store";


export const loginUser = (email, password) => (dispatch) => {

  dispatch({ type: actionTypes.LOGIN });
  requestAgent
    .post(Api.user.login(), { email, password })
    .then((res) => {
      if (!res.body.success) {
        swal({
          text: res.body.res_text,
        });
        loginUserFail(dispatch, res.body.res_text)
      } else if (res.body.res_data && res.body.res_data.data) {
        window.localStorage.setItem(
          "user",
          JSON.stringify(res.body.res_data.data)
        );
        loginUserSuccess(dispatch, res.body.res_data.data)
        history.go(0)
      }
    })
    .catch((err) => {
      console.log('errr', err);
      loginUserFail(dispatch, err)
    });
};

export const loginGoogleUser = (token) => (dispatch) => {

  dispatch({ type: actionTypes.LOGIN_GOOGLE });
  requestAgent
    .post(Api.user.loginGoogle(), { token })
    .then((res) => {
      if (!res.body.success) {
        swal({
          text: res.body.res_text,
        });
        loginGoogleUserFail(dispatch, res.body.res_text)
      } else if (res.body.res_data && res.body.res_data.data) {
        window.localStorage.setItem(
          "user",
          JSON.stringify(res.body.res_data.data)
        );
        history.go(0)
        loginGoogleUserSuccess(dispatch, res.body.res_data.data)
      }
    })
    .catch((err) => {
      console.log('errr', err);
      loginGoogleUserFail(dispatch, err)
    });
};

export const loginFacebookUser = (token, email, name) => (dispatch) => {
  dispatch({ type: actionTypes.LOGIN_FACEBOOK });
  requestAgent
    .post(Api.user.loginFacebook(), { token, email, name })
    .then((res) => {
      if (!res.body.success) {
        swal({
          text: res.body.res_text,
        });
        loginFacebookUserFail(dispatch, res.body.res_text)
      } else if (res.body.res_data && res.body.res_data.data) {
        window.localStorage.setItem(
          "user",
          JSON.stringify(res.body.res_data.data)
        );
        history.go(0)
        loginFacebookUserSuccess(dispatch, res.body.res_data.data)
      }
    })
    .catch((err) => {
      console.log('errr', err);
      loginFacebookUserFail(dispatch, err)
    });
};

const loginUserSuccess = (dispatch, user) => {
  dispatch({
    type: actionTypes.LOGIN_SUCCESS
  });
}
const loginUserFail = (dispatch, error) => {
  dispatch({
    type: actionTypes.LOGIN_FAIL
  });
}

const loginGoogleUserSuccess = (dispatch, user) => {
  dispatch({
    type: actionTypes.LOGIN_GOOGLE_SUCCESS
  });
}
const loginGoogleUserFail = (dispatch, error) => {
  dispatch({
    type: actionTypes.LOGIN_GOOGLE_FAIL
  });
}

const loginFacebookUserSuccess = (dispatch, user) => {
  dispatch({
    type: actionTypes.LOGIN_FACEBOOK_SUCCESS
  });
}
const loginFacebookUserFail = (dispatch, error) => {
  dispatch({
    type: actionTypes.LOGIN_FACEBOOK_FAIL
  });
}