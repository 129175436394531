import React from "react";
import GridContainer from "components/Grid/GridContainer.js";
import { Input, InputLabel, Typography } from "@material-ui/core";
import Admin from "layouts/Admin";
import GridItem from "components/Grid/GridItem";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
// import AppBar from '@material-ui/core/AppBar';
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import Orders from "views/Orders/index.js";
import { logoutUser } from "actions/user";
import { connect } from "react-redux";
const styles = {
  title: {
    color: "black", marginBottom: "2rem"
  },
  label: {
    fontFamily: 'proxima-nova,sans-serif', 
    color: 'black', 
    fontWeight: "bold", 
    fontSize: 16, 
    marginBottom: 10,
    marginTop: 5
  },
  input:{
    borderColor: ' #e6e6e6',
    boxShadow: '0 1px 2px 0 #e6e6e6',
    border: '1px solid #ccc',
    borderRadius: 5,
    fontFamily: 'proxima-nova,sans-serif',
    padding: '0px 15px 0px 10px',
    marginBottom: '15px'
  },
  tabPanel: {    
    paddingBottom: 25,
    paddingTop: 25
  },
};
const useStyles = makeStyles(styles);

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Grid
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Grid p={3}>
          <Typography component={'span'} >{children}</Typography>
        </Grid>
      )}
    </Grid>
  ); 
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tabpanel-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

function Profile({logoutUser}) {
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem("user"))
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Admin
      backgroundColor="white"
      containerStyles={{ alignItems: "unset",maxWidth: '100%'}}
    >
      <div>
        {/* <AppBar position="static" color="default"> */}
        <Grid  container
        direction="row"
        justify="center"
        alignItems="center"
        color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="on"
            indicatorColor="primary"
            textColor="primary"
            aria-label="scrollable force tabs example"
          >
            <Tab label="PROFILE" icon={<PersonPinIcon />} {...a11yProps(0)} />
            <Tab label="ORDERS" icon={<LocalShippingIcon />} {...a11yProps(1)} />
            <Tab label="LOGOUT" icon={<ExitToAppIcon />} {...a11yProps(2)} onClick={logoutUser}/>
          </Tabs>
        {/* </AppBar> */}
        </Grid>
        <TabPanel value={value} index={0} className={classes.tabPanel}>
          <Typography
            className={classes.title}
            variant="h4"
            component={'span'}
          >
            Profile
          </Typography>

          <hr />

          <GridContainer>
            <GridItem md={6}>
              <InputLabel className={classes.label}>
                First Name
              </InputLabel>
              <Input
                className={classes.input}
                value={user.name}
                name="firstName"
                type="text"
                fullWidth
                disableUnderline
              />
            </GridItem>

            <GridItem md={6}>
              <InputLabel className={classes.label}>
                Email
              </InputLabel>
              <Input
                className={classes.input}
                value={user.email}
                name="email"
                type="email"
                fullWidth
                disableUnderline
              />
            </GridItem>
          </GridContainer>        
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Orders/>
        </TabPanel>
        <TabPanel value={value} index={2}>
        </TabPanel>
      </div>
    </Admin>
  );
}
const mapStateToProps = (profile) => ({
});
// export default Profile;
const mapDispatchToProps = (dispatch) => ({
  logoutUser: () =>
    dispatch(logoutUser())
});
Profile.propTypes = {
  logoutUser: PropTypes.func,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile);