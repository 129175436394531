import React, { useState } from "react";
import PropTypes from "prop-types";
import ReactImageGallery from "react-image-gallery";
import Youtube from "assets/img/youtube.png";

const renderItem = ({ original, mediaType, youtubeId, imageProps }) => {
  if (mediaType === "image") {
    return (
      <img
        alt="gallery item"
        className="image-gallery-image"
        src={original}
        {...imageProps}
      />
    );
  }
  if (mediaType === "youtube") {
    return (
      <iframe
        title={`youtube-video-${youtubeId}`}
        width="100%"
        height="450"
        src={`https://www.youtube.com/embed/${youtubeId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    );
  }
  return (
    <img
      alt="gallery item"
      className="image-gallery-image"
      src={original}
      {...imageProps}
    />
  );
};

renderItem.propTypes = {
  original: PropTypes.string,
  youtubeId: PropTypes.string,
  mediaType: PropTypes.oneOf(["image", "youtube"]),
  imageProps: PropTypes.object,
};

function renderThumbInner({ thumbnail, mediaType }) {
  if (mediaType !== "image") {
    return (
      <img
        alt="gallery thumbnail"
        src={Youtube}
        className="image-gallery-thumbnail-image"
      />
    );
  }
  return (
    <img
      alt="gallery thumbnail"
      src={thumbnail}
      className="image-gallery-thumbnail-image"
    />
  );
}

renderThumbInner.propTypes = {
  thumbnail: PropTypes.string,
  mediaType: PropTypes.oneOf(["image", "youtube"]),
};

export default function Gallery({ media, imageProps, videoFirst }) {
  // const classes = useStyles();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showFullscreenButton, setShowFullscreenButton] = useState(true);
  let renderedMedia = videoFirst
    ? media.sort((a) => (a.mediaType === "youtube" ? -1 : 1))
    : media;

  return (
    <ReactImageGallery
      onSlide={(i) => {
        setCurrentIndex(i);
        if (media[i].mediaType !== "image") {
          setShowFullscreenButton(false);
        } else {
          setShowFullscreenButton(true);
        }
      }}
      startIndex={currentIndex}
      showFullscreenButton={showFullscreenButton}
      items={renderedMedia.map((i) => ({
        original: i.url,
        thumbnail: i.url,
        ...i,
        imageProps: imageProps || {},
      }))}
      renderThumbInner={renderThumbInner}
      renderItem={renderItem}
      showThumbnails={media && media.length > 1}
      showNav={false}
      showPlayButton={false}
    />
  );
}

Gallery.propTypes = {
  media: PropTypes.array,
  imageProps: PropTypes.object,
  videoFirst: PropTypes.bool,
};

