import React from "react";
import Admin from "layouts/Admin";
import CartContent from "components/Cart/CartContent";

function Cart() {
  return (
    <Admin backgroundColor="white" containerStyles={{ alignItems: "unset" }}>
      <CartContent/>
    </Admin >
  );
}

export default Cart;