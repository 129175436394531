import React, { useState, useCallback } from "react";
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import {
  Grid,
  Typography,
  Button,
  IconButton,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { loginUser, loginGoogleUser, loginFacebookUser } from "./modules/actions";
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import loginWithGoogle from "assets/img/google-icon.svg";
import { Facebook } from '@material-ui/icons';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import { hideLoginDialog, showSignupDialog, hideSignupDialog } from "actions/ui";

const GoogleClientID = process.env.REACT_APP_FT_GOOGLE_PUBLIC_KEY
const FacebookClientID = process.env.REACT_APP_FT_FACEBOOK_KEY

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    left: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});


const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
      <Typography variant="h6">{children}</Typography>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    maxWidth: '500px'
  },
}))(MuiDialogContent);


function LoginDialog({ loginUser, loginGoogleUser, loginGoogleSuccess, loginFacebookUser, loginSuccess, loginDialogVisible, hideLoginDialog, showSignupDialog, hideSignupDialog }) {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
    hideSignupDialog()
  };
  const handleClose = () => {
    setOpen(false);
    hideLoginDialog()
  };
  const { handleSubmit } = useForm();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');

  const onSubmit = (data) => {

    if (!showPassword) {
      setEmail(data.email)
      setShowPassword(true)
    } else {
      loginUser(email, data.password)
    }
  };

  const handleGoogleOAuth = useCallback(
    (response) => {
      try {
        const values = {
          accessToken: response.getAuthResponse(true).id_token,
          provider: "google-oauth2",
        };
        loginGoogleUser(values.accessToken)
      } catch (error) {
        console.error(error);
      }
    },
    // eslint-disable-next-line
    []
  );

  const responseFacebook = (response) => {
    console.log(response);
    if (response) {
      try {
        const values = {
          accessToken: response.accessToken,
          name: response.name,
          email: response.email,
        };
        loginFacebookUser(values.accessToken, values.email, values.name)
      } catch (error) {
        console.error(error);
      }
    }
  }

  if ((!loginDialogVisible || loginSuccess || loginGoogleSuccess) && open) {
    handleClose();
    return;
  }

  if (loginDialogVisible && !open && !loginSuccess && !loginGoogleSuccess) {
    handleClickOpen()
  }

  return (
    <div>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <Typography
            style={{ color: "black", marginBottom: "1rem", paddingTop: 40, textTransform: 'capitalize' }}
            align="center"
            variant="h4"
          >
            Welcome to Freshtable
          </Typography>
        </DialogTitle>
        <DialogContent dividers style={{ padding: 0 }}>
          <Grid container style={{ borderBottom: '1px solid lightgrey', marginBottom: 20, paddingBottom: 10, paddingTop: 10 }}>
            <Grid item xs={12}>
              <form onSubmit={handleSubmit(onSubmit)} style={{ marginRight: "10%", marginLeft: "10%", maxWidth: '80%', align: "center", }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <GoogleLogin
                      clientId={GoogleClientID}
                      render={renderProps => (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center ",
                          }}
                        >
                          <Button
                            style={{
                              backgroundColor: "rgb(67 121 228)",
                              color: " white",
                              padding: 1,
                              marginTop: 15,
                              borderRadius: 60,
                              width: '100%',
                              height: 60,
                              fontSize: 16,
                            }}
                            disabled={renderProps.disabled}
                            onClick={renderProps.onClick}
                            variant="contained"
                          >
                            <img
                              alt={"login img"}
                              src={loginWithGoogle}
                              width={33}
                              style={{ marginRight: 10, backgroundColor: '#fff', padding: 7 }}
                            />{" "}
                            <Typography
                              style={{
                                padding: "5px",
                                paddingRight: "2rem",
                                paddingLeft: "1.5rem",
                                textTransform: "none",
                                letterSpacing: '0.31px !important'
                              }}
                              align="center"
                              variant="p"
                            >
                              Continue with Google
                            </Typography>
                          </Button>
                        </div>
                      )}
                      buttonText="Login"
                      onSuccess={handleGoogleOAuth}
                      onFailure={handleGoogleOAuth}
                      cookiePolicy={'single_host_origin'}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <p
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        borderBottom: "1px solid #eee",
                        paddingBottom: 20,
                        width: '100%'
                      }}
                    ></p>
                    <p
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "1px solid #eee",
                        paddingBottom: 20,
                        backgroundColor: '#fff',
                        marginTop: -25,
                        width: 30,
                        fontSize: 16,
                        height: 30,
                        padding: 2,
                        paddingTop: 4,
                        borderRadius: 30,
                        color: 'grey'
                      }}
                    >OR</p>
                  </Grid>
                  <Grid container item xs={12}>
                    <FacebookLogin
                      appId={FacebookClientID}
                      autoLoad={false}
                      fields="name,email,picture"
                      render={renderProps => (
                        <Button
                          style={{
                            backgroundColor: "#4c69ba",
                            color: " white",
                            padding: 1,
                            marginBottom: 15,
                            borderRadius: 60,
                            width: '100%',
                            height: 60,
                            fontSize: 16,
                          }}
                          disabled={renderProps.disabled}
                          onClick={renderProps.onClick}
                          variant="contained"
                        >
                          <Facebook style={{ fontSize: 34 }} />
                          <Typography
                            style={{
                              padding: "5px",
                              paddingRight: "2rem",
                              paddingLeft: "1.5rem",
                              textTransform: "none",
                              letterSpacing: '0.31px !important'
                            }}
                            align="center"
                            variant="p"
                          >
                            Continue with Facebook
                          </Typography>
                        </Button>
                      )}
                      callback={responseFacebook} />
                  </Grid>

                  {/* <Grid item xs={12}>
                    {
                      showPassword ?
                        <Grid container justify={'space-between'} alignItems={"center"}>
                          <Typography>{email}</Typography>
                          <IconButton onClick={() => setShowPassword(false)}>
                            <Edit />
                          </IconButton>
                        </Grid>
                        :

                        <FormGroup>
                          <FormControl>
                            <Input
                              style={{
                                borderColor: ' #e6e6e6',
                                boxShadow: '0 1px 2px 0 #e6e6e6',
                                color: 'grey',
                                border: '1px solid #ccc',
                                fontFamily: 'proxima-nova,sans-serif',
                                padding: '5px 15px 5px 10px',
                                marginTop: -5
                              }}
                              error={!!errors.email}
                              value={email}
                              onChange={(value) => setEmail(value.target.value)}
                              name="email"
                              type="email"
                              placeholder="Email Address"
                              disableUnderline
                              inputRef={register({
                                required: true,
                              })}
                            />
                            {errors.email && errors.email.type === "required" && (
                              <FormHelperText style={{ color: "red", fontFamily: 'proxima-nova,sans-serif' }}>
                                Please enter a valid email address
                              </FormHelperText>
                            )}
                          </FormControl>
                        </FormGroup>
                    }
                  </Grid>
                  {showPassword && (
                    <Grid item xs={12}>
                      <FormGroup>
                        <FormControl>
                          <Input
                            style={{
                              borderColor: ' #e6e6e6',
                              boxShadow: '0 1px 2px 0 #e6e6e6',
                              color: 'grey',
                              border: '1px solid #ccc',
                              fontFamily: 'proxima-nova,sans-serif',
                              padding: '5px 15px 5px 10px',
                              marginTop: -5
                            }}
                            name="password"
                            type={"password"}
                            placeholder="Password"
                            disableUnderline
                            inputRef={register({ required: true })}
                          />
                          {errors.password && (
                            <FormHelperText style={{ color: "red" }}>
                              This field is required
                            </FormHelperText>
                          )}
                        </FormControl>
                      </FormGroup>
                    </Grid>
                  )}
                  <Grid item xs={12} style={{ paddingLeft: 0 }}>
                    <Grid style={{ fontWeight: 'bold', color: 'grey' }}>
                      <Checkbox classes={{ checked: classes.checkedIcon }} style={{ marginRight: -10, marginTop: -2 }} /> Remember me
                      </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        color="primary"
                        style={{
                          backgroundColor: "#1ea2d5",
                          textTransform: "none",
                          borderRadius: 1,
                          width: '100%',
                          height: 60,
                          margin: 'auto',
                          fontSize: 16,
                          paddingLeft: 20,
                          paddingRight: 20,
                          color: "white",
                        }}
                        variant="contained"
                        disabled={Object.keys(errors).length > 0}
                        type="submit"
                      >
                        {
                          loading ?
                            <CircularProgress size={18} className={classes.buttonProgress} />
                            : showPassword ? "Login" : "Continue"
                        }
                      </Button>
                    </div>
                  </Grid> */}
                </Grid>
              </form>
            </Grid>
          </Grid>
          <Grid container justify={"center"}>
            <Typography
              style={{ color: "black", width: '80%', marginBottom: "2rem", textTransform: 'capitalize' }}
              align="center"
              variant="body1"
            >
              By continuing, you agree to Freshtable <Link to={'/terms'} onClick={handleClose} style={{ cursor: 'pointer', fontWeight: 'bold', color: 'black' }}>Terms of Service</Link> and acknowledge you've read our <Link to={'/privacy'} onClick={handleClose} style={{ cursor: 'pointer', fontWeight: 'bold', color: 'black' }}>Privacy Policy</Link>
            </Typography>
          </Grid>
        </DialogContent>

      </Dialog>
    </div>
  );
}

const mapStateToProps = (props) => ({
  loading: props.login.loginLoading,
  loginSuccess: props.login.loginSuccess,
  loginGoogleLoading: props.login.loginGoogleLoading,
  loginGoogleSuccess: props.login.loginGoogleSuccess,
  loginFacebookLoading: props.login.loginFacebookLoading,
  loginFacebookSuccess: props.login.loginFacebookSuccess,
  loginDialogVisible: props.ui.loginDialogVisible,
});
LoginDialog.propTypes = {
  login: PropTypes.any,
  loading: PropTypes.bool,
  loginSuccess: PropTypes.bool,
  loginGoogleLoading: PropTypes.bool,
  loginGoogleSuccess: PropTypes.bool,
  loginFacebookLoading: PropTypes.bool,
  loginFacebookSuccess: PropTypes.bool,
  loginDialogVisible: PropTypes.bool,
  loginUser: PropTypes.func,
  loginGoogleUser: PropTypes.func,
  loginFacebookUser: PropTypes.func,
  hideLoginDialog: PropTypes.func,
  showSignupDialog: PropTypes.func,
  hideSignupDialog: PropTypes.func,
}
const mapDispatchToProps = (dispatch) => ({
  loginUser: (email, password) =>
    dispatch(loginUser(email, password)),
  loginGoogleUser: (token) =>
    dispatch(loginGoogleUser(token)),
  loginFacebookUser: (token, email, name) =>
    dispatch(loginFacebookUser(token, email, name)),
  hideLoginDialog: () => dispatch(hideLoginDialog()),
  showSignupDialog: () => dispatch(showSignupDialog()),
  hideSignupDialog: () => dispatch(hideSignupDialog()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginDialog);