import * as actionTypes from '../actions/meal/types';

const INITIAL_STATE = {
    meals: [],
   loading: false,
   mealMantenance: false,
   selectedMeal: null,
}

// eslint-disable-next-line
export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case actionTypes.SET_LOADING:
            return { ...state, loading: action.payload };
        case actionTypes.SET_MEALS:
            return { ...state, meals: action.payload };
        case actionTypes.SET_MEAL_MAINTENANCE:
            return { ...state, mealMantenance: action.payload };
        case actionTypes.SET_SELECTED_MEAL:
            return { ...state, selectedMeal: action.payload };
        default:
            return state;
    }
}