import React from "react";

import Admin from "layouts/Admin";
const styles = {
  wrapper: {
    width: "50%",
    margin: "1rem auto 5rem auto",
    color: "#666",
  },
  title: {
    color: "black",
  },
  section: {
    marginBottom: "3rem",
  },
};
function Privacy() {
  return (
    <Admin headerPanel>
      <div style={styles.wrapper}>
        <h2>Privacy</h2>
        <section style={styles.section}>
          <h4 style={styles.title}>Email Invoice</h4>
          <p>
            We use a third-party service called SendGrid to securely
            email invoices for your event and shop orders. Your email address will not be sold or shared with
            any other company or agency.
          </p>
        </section>
      </div>
    </Admin>
  );
}

export default Privacy;
