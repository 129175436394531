import * as actionTypes from "./types";
import Api from "../../../shared/api";
import requestAgent from "../../../shared/requestAgent";
import swal from "sweetalert";


export const verifyEmail = (id) => (dispatch) => {

  dispatch({ type: actionTypes.VERIFY_EMAIL });
  requestAgent
    .post(Api.user.verifyEmail(), { id })
    .then((res) => {
      console.log(res);
      if (res.body.res_code === 400) {
        swal({
          text: res.body.res_text,
        });
      } else if (res.body.res_data && res.body.res_data.data) {
        verifyEmailSuccess(dispatch, res.body.res_data.data)
      }
    })
    .catch((err) => {
      verifyEmailFail(dispatch, err)
    });
};
export const setPass = (password, id) => (dispatch) => {

  dispatch({ type: actionTypes.SET_PASSWORD });
  requestAgent
    .post(Api.user.updatePassword(), { password, id })
    .then((res) => {
      console.log(res);
      if (res.body.res_code === 400) {
        swal({
          text: res.body.res_text,
        });
      } else if (res.body.res_data && res.body.res_data.data) {
        setPassSuccess(dispatch, res.body.res_data.data)
      }
    })
    .catch((err) => {
      setPassFail(dispatch, err)
    });
};

const verifyEmailSuccess = (dispatch) => {
  dispatch({
    type: actionTypes.VERIFY_EMAIL_SUCCESS
  });
}
const verifyEmailFail = (dispatch, error) => {
  dispatch({
    type: actionTypes.VERIFY_EMAIL_FAIL
  });
  alert(error)
}

const setPassSuccess = (dispatch) => {
  dispatch({
    type: actionTypes.SET_PASSWORD_SUCCESS
  });
}
const setPassFail = (dispatch, error) => {
  dispatch({
    type: actionTypes.SET_PASSWORD_FAIL
  });
  alert(error)
}