import * as actionTypes from "./types";

const INITIAL_STATE = {
  ordersLoading: false,
  orders: [],
  authorized: false,
  events: [],
  eventOrders: null,
};
// eslint-disable-next-line
export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.SET_ORDERS_LOADING:
      return { ...state, ordersLoading: action.payload };
    case actionTypes.SET_ORDERS:
      return { ...state, orders: action.payload };
    case actionTypes.SET_AUTHORIZED:
      return { ...state, authorized: action.payload };
    case actionTypes.SET_EVENTS:
      return { ...state, events: action.payload };
    case actionTypes.SET_EVENT_ORDERS:
      return { ...state, eventOrders: action.payload };
    default:
      return state;
  }
}
