import React from "react";
// @material-ui/core components
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableContainer,
    Paper,
    IconButton,
} from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import { getImagePath } from "shared/utils/helpers";
import moment from "moment-timezone";
import { Link } from "react-router-dom";

function CartTable({ state, setstate, deleteToCart, addToCart, pricehide, hideCartDialog }: any) {


    const deleteItems = (index) => {

        state.tableData.splice(index, 1)
        setstate({
            ...state,
            tableData: state.tableData
        })
        deleteToCart(index)
    }

    const getTotalPrice = (data) => {
        let total = 0
        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            total = total + (element.quantity * element.meal_item_price)

        }
        return total
    }

    const getTotalPriceEvent = (data) => {
        let total = 0
        if (data.menuCulinaries) {
            for (let j = 0; j < data.menuCulinaries.length; j++) {
                total = total + (data.menuCulinaries[j].quantity * data.menuCulinaries[j].price)
            }
        }
        else if (data.price && data.quantity) {
            total = total + (data.quantity * data.price)
        }
        else {
            for (let i = 0; i < data.length; i++) {
                const element = data[i];
                if (element.price) {
                    total = total + (element.quantity * element.price)
                }
            }
        }
        return total
    }
    console.log('state.tableData', state.tableData);
    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow style={{ height: 50 }}>
                        <TableCell component="th" scope="row" padding="none" style={{ minWidth: '100px', paddingLeft: 20 }}>ITEM</TableCell>
                        <TableCell padding="none" style={{ minWidth: '100px' }}>edit</TableCell>
                        <TableCell padding="none" style={{ minWidth: '100px' }}>TOTAL</TableCell>
                        <TableCell padding="none" style={{ minWidth: '100px' }}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        state.tableData && state.tableData.length > 0 && state.tableData.map((data, index) => (
                            <TableRow key={index}>
                                <TableCell component="th" scope="row" padding="none" style={{ paddingLeft: 20, display: 'flex' }}>
                                    <img alt={""} style={{ marginRight: 20, width: 70, height: 70, marginTop: 10, marginBottom: 10 }} src={getImagePath(data?.meal_items?.length ? data?.meal_items[0].media[0].url : data.media[0].url)} />
                                    <div>
                                        <div style={{ fontSize: 24, fontWeight: 'bold' }}>
                                            {data?.meal_title ? data.meal_title : data.event_type === "classes" ? data.title + ': x' + data?.quantity : data.title}

                                        </div>
                                        <div key={index} style={{ color: 'black', marginTop: 5, marginBottom: 10 }}>{
                                            "Event Date - " + moment.utc(data.meal_title ? data.meal_date : data.event_date).format("MM/DD/YYYY hh:mm a")
                                        }</div>
                                        {
                                            data?.meal_items?.map((item, index) => (
                                                <div key={index} style={{ color: 'black', fontWeight: 'bold', marginTop: 5, marginBottom: 10 }}>{item?.meal_item_title + ': x' + item?.quantity}</div>
                                            ))
                                        }
                                        {
                                            data?.menuCulinaries?.map((item, index) => (
                                                <div key={index} style={{ color: 'black', fontWeight: 'bold', marginTop: 5, marginBottom: 10 }}>{item?.menu_title + ': x' + item?.quantity}</div>
                                            ))
                                        }
                                    </div>
                                </TableCell>
                                <TableCell padding="none" ><Link to={`/app/${data.event_type ? "events" : "freshmeals"}/${data.slug}`} onClick={() => hideCartDialog()}><Edit style={{ color: 'rgba(0, 0, 0, 0.54)' }} /></Link></TableCell>
                                <TableCell padding="none" >{data?.meal_items?.length ? getTotalPrice(data?.meal_items).toFixed(2) : data.event_type === "classes" ? (Number(data?.event_price) * Number(data.quantity)).toFixed(2) : getTotalPriceEvent(data).toFixed(2)}</TableCell>
                                <TableCell padding="none" ><IconButton onClick={() => deleteItems(index)}><Delete /></IconButton></TableCell>

                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default CartTable