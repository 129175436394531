import * as actionTypes from "./types";

const INITIAL_STATE = {
  verifyLoading: false,
  verifySuccess: false,
  setPassLoading: false,
  setPassSuccess: false,
  user: null,
  error: '',
};
// eslint-disable-next-line
export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.VERIFY_EMAIL:
      return { ...state, verifyLoading: true, verifySuccess: false };
    case actionTypes.VERIFY_EMAIL_FAIL:
      return { ...state, verifyLoading: false, verifySuccess: false };
    case actionTypes.VERIFY_EMAIL_SUCCESS:
      return { ...state, verifyLoading: false, verifySuccess: true };
    case actionTypes.SET_PASSWORD:
      return { ...state, setPassLoading: true, setPassSuccess: false };
    case actionTypes.SET_PASSWORD_FAIL:
      return { ...state, setPassLoading: false, setPassSuccess: false };
    case actionTypes.SET_PASSWORD_SUCCESS:
      return { ...state, setPassLoading: false, setPassSuccess: true };
    default:
      return state;
  }
}
