import * as actionTypes from './types';
import Api from '../../shared/api';
import requestAgent from '../../shared/requestAgent';


export const actionCreator = () => ({

});


export const setLoading = (loading) => ({
    type: actionTypes.SET_LOADING,
    payload: loading,
});

export const setMeals = (meals) => ({
    type: actionTypes.SET_MEALS,
    payload: meals,
});

export const setMealMaintenance = (MealMaintenance) => ({
    type: actionTypes.SET_MEAL_MAINTENANCE,
    payload: MealMaintenance,
});

export const setSelectedMeal = c => ({
    type: actionTypes.SET_SELECTED_MEAL,
    payload: c,
})

export const fetchMeals = () => (dispatch) => {
    dispatch(setLoading(true));
    requestAgent.get(Api.meal.getMeals())
    .then(({ body }) => {
        console.log('body.res_data',body.res_data)
        const meals = body.res_data.data.meals || [];
        if(body.res_data.data.meal_maintenance){
            const meal_maintenance = body.res_data.data.meal_maintenance;
            dispatch(setMealMaintenance(meal_maintenance))
        }
        dispatch(setMeals(meals))
        dispatch(setLoading(false));
    })
    .catch(err => {
        console.log(err);
        dispatch(setLoading(false));
    })
}

export const fetchMealBySlug = slug => (dispatch) => {
    dispatch(setLoading(true));
    requestAgent.get(Api.meal.getBySlug(slug))
    .then(({ body }) => {
        const c = body.res_data.data.meal || null;
        dispatch(setSelectedMeal(c))
        dispatch(setLoading(false));
    })
    .catch(err => {
        console.log(err);
        dispatch(setLoading(false));
    })
}

export const fetchMealById = id => (dispatch) => {
    dispatch(setLoading(true));
    requestAgent.get(Api.meal.getById(id))
    .then(({ body }) => {
        const c = body.res_data.data.meal || null;
        dispatch(setSelectedMeal(c))
        dispatch(setLoading(false));
    })
    .catch(err => {
        console.log(err);
        dispatch(setLoading(false));
    })
}