const MODULE_PREFIX = `UI`;

export const SET_HOME_IMAGES = `${MODULE_PREFIX}/SET_HOME_IMAGES`
export const SET_HOME_IMAGES_LOADING = `${MODULE_PREFIX}/SET_HOME_IMAGES_LOADING`
export const SHOW_LOGIN_DIALOG = `${MODULE_PREFIX}/SHOW_LOGIN_DIALOG`
export const SHOW_SIGNUP_DIALOG = `${MODULE_PREFIX}/SHOW_SIGNUP_DIALOG`
export const HIDE_LOGIN_DIALOG = `${MODULE_PREFIX}/HIDE_LOGIN_DIALOG`
export const HIDE_SIGNUP_DIALOG = `${MODULE_PREFIX}/HIDE_SIGNUP_DIALOG`
export const SHOW_CART_DIALOG = `${MODULE_PREFIX}/SHOW_CART_DIALOG`
export const HIDE_CART_DIALOG = `${MODULE_PREFIX}/HIDE_CART_DIALOG`
