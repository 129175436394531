import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
// @material-ui/core components
import {
  Grid,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import Admin from "layouts/Admin";
import PropTypes from "prop-types";
import { getOrderById } from "actions/order";
import moment from "moment-timezone";

function ConfirmOrder(props) {
  const {
    getOrderById,
    singleOrderItems
  } = props

  const [orderByID, setOrderByID] = useState(null)

  useEffect(() => {
    const id = window.location.pathname.split('/').pop()
    getOrderById(id)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (singleOrderItems) {
      let localculinaries = localStorage.getItem('culinaries')
      if (localculinaries) {
        localculinaries = JSON.parse(localculinaries)
        for (let i = 0; i < singleOrderItems.items.length; i++) {
          const element = singleOrderItems.items[i];
          if (element.event?.menuCulinaries && element.event.menuCulinaries.length) {
            for (let k = 0; k < element.event.menuCulinaries.length; k++) {
              const element1 = element.event.menuCulinaries[k];
              for (let j = 0; j < localculinaries.length; j++) {
                const element2 = localculinaries[j];
                if (element2.title === element1.menu_title) {
                  singleOrderItems.items[i] = element2
                }
              }
            }
          }
        }
      }
      setOrderByID(singleOrderItems)
    }
  }, [singleOrderItems])

  const getTotalPrice = (data) => {
    let total = 0
    for (let i = 0; i < data.length; i++) {
      const element = data[i];
      total = total + (element.quantity * element.meal_item_price)

    }
    return total
  }

  const getTotalPriceEvent = (data) => {
    let total = 0
    for (let i = 0; i < data.length; i++) {
      const element = data[i];
      total = total + (element.quantity * element.price)

    }
    return total
  }

  return (
    <Admin backgroundColor="white" containerStyles={{ alignItems: "unset" }}>
      <Grid container>
        <Grid style={{ margin: 0, background: 'white', boxSizing: 'border-box', color: '#0a0a0a', fontFamily: 'Helvetica', fontSize: 16, fontWeight: 400, lineHeight: 1.3, minWidth: '100%', padding: 0, textAlign: 'left', width: '100%' }}><span className="preheader" style={{ color: '#f3f3f3', display: 'none', fontSize: 1, lineHeight: 1, maxHeight: 0, maxWidth: 0, opacity: 0, overflow: 'hidden', visibility: 'hidden' }}></span>
          <div style={{ color: '#5E5E5E', width: '90%', margin: '20px auto' }}>
            <div style={{ textAlign: 'left' }}>
              <p style={{ fontSize: 20, fontWeight: 'bold' }}>
                Order Received
              </p>
              <h3>
                <small>
                  Thanks for your order,
                </small>
              </h3>
              <p>
                If you have any questions, please email <span style={{ color: 'black', fontWeight: 'bold' }}><u><strong>hello@freshtable.io</strong></u></span>
              </p>
            </div>


            <div style={{ marginTop: 5, width: '100%' }}>
              {/* <div style={{ maxWidth: 400, marginTop: 50 }}>
                <span style={{ width: '100%', display: 'inline-block', fontWeight: 'bold', paddingBottom: 10, borderBottom: '2px solid #5E5E5E' }}>
                  CONTACT INFORMATION
                </span>
                <p style={{ textTransform: 'uppercase', marginTop: 10 }}>
                  NAME: fahad
                </p>
                <p style={{ textTransform: 'uppercase' }}>
                  EMAIL: fahadhussain@gmail.com
                </p>
              </div> */}
              <div style={{ marginTop: 50 }}>
                <div style={{ maxWidth: 400 }}>
                  <span style={{ width: '100%', marginBottom: 10, display: 'inline-block', fontWeight: 'bold', paddingBottom: 10, borderBottom: '2px solid #5E5E5E' }}>
                    Payment Total
                  </span>
                  <div style={{ marginBottom: 10 }}>
                    <div style={{ width: '70%', display: 'inline-block' }}>
                      Subtotal
                    </div>
                    <div style={{ width: '30%', display: 'inline-block' }}>
                      {"$" + orderByID?.subtotal}
                    </div>
                  </div>
                  <div style={{ marginBottom: 10 }}>
                    <div style={{ width: '70%', display: 'inline-block' }}>
                      Shipping
                    </div>
                    <div style={{ width: '30%', display: 'inline-block' }}>
                      {orderByID?.shipping ? "$" + orderByID.shipping : "Free"}
                    </div>
                  </div>
                  <div style={{ marginBottom: 10 }}>
                    <div style={{ width: '70%', display: 'inline-block' }}>
                      Sales Tax
                    </div>
                    <div style={{ width: '30%', display: 'inline-block' }}>
                      {"$" + orderByID?.tax}
                    </div>
                  </div>
                  <div style={{ marginBottom: 10, fontWeight: 'bold', color: 'black' }}>
                    <div style={{ width: '70%', display: 'inline-block' }}>
                      Total
                    </div>
                    <div style={{ width: '30%', display: 'inline-block' }}>
                      {orderByID?.total_cost ? "$" + orderByID.total_cost : "Free"}
                    </div>
                  </div>

                </div>
              </div>
              <div style={{ marginTop: 50, paddingTop: 5, width: '100%', borderBottom: '2px solid #5E5E5E', borderTop: '2px solid #5E5E5E' }}>
                <table style={{ width: '100%' }}>
                  <tr>
                    <th>ITEMS ORDERED</th>
                    {/* <th>QTY</th> */}
                    <th>ITEM  PRICE</th>
                  </tr>
                  {
                    orderByID?.items?.map((item, index) => {
                      console.log('item',item?.meal_date);
                      return (
                        <tr key={index}>
                          <td>
                            <div style={{ fontWeight: 'bold', color: 'black', fontSize: 18, marginBottom: 20, marginTop: 20 }}>
                              {item.event_type === "classes" ? item.title + ': x' + item?.quantity : (item?.event?.event_type && item?.event?.title) ? item.event?.title : item?.meal_title}
                              <div style={{ color: 'black', marginTop: 5, marginBottom: 10,fontSize: 15 }}>{
                                "Event Date - " + moment.utc(item?.meal_date ? Number(item?.meal_date) : Number(item.event_date)).format("MM/DD/YYYY hh:mm a")
                              }</div>
                              {
                                item?.meal_items?.map((item, index) => (
                                  <div key={index} style={{ color: 'black', fontSize: 15, marginTop: 5, marginBottom: 10 }}>{item?.meal_item_title + ': x' + item?.quantity}</div>
                                ))
                              }
                              {
                                item?.menuCulinaries?.map((item, index) => (
                                  <div key={index} style={{ color: 'black', fontSize: 15, marginTop: 5, marginBottom: 10 }}>{item?.menu_title + ': x' + item?.quantity}</div>
                                ))
                              }
                            </div>
                          </td>
                          {/* <td>{item?.quantity}</td> */}
                          <td>{"$" + (item.event_type === "classes" ? (Number(item?.event_price) * Number(item.quantity)).toFixed(2) : item?.menuCulinaries?.length ? getTotalPriceEvent(item?.menuCulinaries).toFixed(2) : getTotalPrice(item?.meal_items).toFixed(2))} </td>
                        </tr>
                      )
                    })
                  }

                </table>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </Admin >
  );
}


const mapStateToProps = ({ order }) => ({
  singleOrderItems: order.singleOrderItems,

});

const mapDispatchToProps = (dispatch) => ({
  getOrderById: (id) => dispatch(getOrderById(id)),

});

ConfirmOrder.propTypes = {
  singleOrderItems: PropTypes.any,
  getOrderById: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ConfirmOrder));