import {
  container,
  whiteColor,
  blackColor,
  hexToRgb,
} from "assets/jss/material-dashboard-pro-react.js";

const pagesStyle = (theme) => ({
  wrapper: {
    height: "auto",
    minHeight: "100vh",
    position: "relative",
    top: "0",
    marginTop: "-75px",
    maxWidth: "100%",
  },
  fullPage: {
    padding: "150px 5rem",
    overflowX: "hidden",
    position: "relative",
    minHeight: "100vh",
    display: "flex!important",
    margin: "0",
    border: "0",
    color: whiteColor,
    alignItems: "center",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    height: "100%",
    maxWidth: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: "120px 1rem",
    },
    "& footer": {
      position: "absolute",
      bottom: "0",
      width: "100%",
      border: "none !important",
    },
    "&:before": {
      backgroundColor: "rgba(" + hexToRgb(blackColor) + ", 0.65)",
    },
  },
  container: {
    maxWidth: "100%",
    ...container,
  },
  fullPageInverted: {
    backgroundColor: "white",
  },
});

export default pagesStyle;
