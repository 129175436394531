import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import {
  Grid,
  Typography
} from "@material-ui/core";
import { fetchMeals } from "actions/meal";
import Admin from "layouts/Admin";
import { getImagePath } from "shared/utils/helpers";
import Loader from "components/Loader";
import moment from "moment";
import Chip from '@material-ui/core/Chip';
const useStyles = makeStyles(styles);

function Freshmeals(props) {
  const classes = useStyles();
  const { fetchMeals, loading } = props;

  useEffect(() => {
    fetchMeals();
  }, [fetchMeals]);

  const [chipData, setChipData] = React.useState([
    { key: 0, label: 'Snack' },
    { key: 1, label: 'Lunch' },
    { key: 2, label: 'Dinner' },
  ]);
  const handleDelete = (chipToDelete) => () => {
    setChipData((chips) => { chips.forEach((chip) => chip.active = (chip.key === chipToDelete.key) ? false : chip.active); return [...chips] });
  };
  const handleClick = (chipClicked) => () => {
    setChipData((chips) => { chips.forEach((chip) => { chip.active = chip.active || (chip.key === chipClicked.key) }); return [...chips] });
  };
  return (
    <Admin>
      <div>
        <GridContainer>
          <Grid item xs={12}>
            <Typography
              style={{ fontWeight: "bold", textTransform: "none", color: "#002C9B", marginBottom: "1rem", fontFamily: 'sans-serif' }}
              align="center"
              variant="h4"
            >
              Delicious fresh Meals delivered to your Door
            </Typography>
            <Typography
              style={{ textTransform: "none", color: "#002C9B", marginBottom: "1rem", fontFamily: 'sans-serif' }}
              align="center"
              variant="h5"
            >
              {
                props.mealMantenance ? "Coming Soon!" : "Choose from an ever-changing mix of meat, fish, Beyond Meat™, WW Approved,"
              }
            </Typography>
            <Grid component="ul" className={classes.chipList}>
              {chipData.map((data) => {
                return (
                  <li key={data.key}>
                    <Chip
                      size="large"
                      color={data.active ? "primary" : 'default'}
                      label={data.label}
                      onClick={handleClick(data)}
                      onDelete={!data.active ? undefined : handleDelete(data)}
                      className={classes.chip}
                    />
                  </li>
                );
              })}
            </Grid>
          </Grid>
          <Loader visible={loading} />
          {props.meals
            ? props.meals.map((product) => {
              return chipData.filter(chip => chip.active === true).every(chip => product.search_tags.includes(chip.label.toLowerCase())) && (<GridItem
                xs={12}
                sm={12}
                md={4}
                key={`product-item-${product.slug}`}
              >
                <Card product className={classes.clearCardBG}>
                  <CardHeader image className={classes.cardHeaderHover}>
                    <Link to={`/app/freshmeals/${product.slug}`}>
                      <img
                        className={classes.cardHeaderImage}
                        src={getImagePath(
                          product.media[0].url || product.thumbnailUrl
                        )}
                        alt="..."
                      />
                    </Link>
                  </CardHeader>
                  <CardFooter
                    product
                    className={`${classes.cardFooter} ${classes.clearCardFooter}`}
                  >
                    <h4 className={classes.cardProductTitle}>
                      <Link to={`/app/freshmeals/${product.slug}`}>
                        {product.meal_title}
                      </Link>
                    </h4>
                    {/* <p className={classes.divider}></p> */}
                    <div className={classes.productInfo}>
                      <div>
                        {moment.utc(product.meal_date).format("MMM	DD, hh:mm A")}
                      </div>
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>
              )
            })
            : null}
        </GridContainer>
      </div>
    </Admin>
  );
}

Freshmeals.propTypes = {
  fetchMeals: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  meals: PropTypes.array,
  mealMantenance: PropTypes.bool,
};

const mapStateToProps = ({ meal }) => ({
  loading: meal.loading,
  meals: meal.meals,
  mealMantenance: meal.mealMantenance,
});

const mapDispatchToProps = (dispatch) => ({
  fetchMeals: () => dispatch(fetchMeals()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Freshmeals);
