import React, { useEffect } from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  order_edit_container: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },

  order_status_option: {
    padding: 10,
    "&:hover": {
      backgroundColor: "#eee",
      cursor: "pointer",
    },
  },
};

const useStyles = makeStyles(styles);
//const OBJ_SAMPLE = {
//  event,
//  products: [{ menu_title, id }],
//  orders: [{
//      user,
//      items: [{
//        product,
//        price,
//        quantity,
//    }],
//
//}

export default function OrderTable({ data }: any) {
  const [rows, setRows] = React.useState([]);
  const [grand_total, setGrandtotal] = React.useState(0);

  const productsTitles =
    data?.event?.menuCulinaries.map((p) => p?.menu_title).filter((t) => t)
    || [];
  const classes = useStyles();

  useEffect(
    function () {
      const d = ((data && data.orders) || []).map((prop, key) => {
        const obj = {
          id: key,
          user: prop.user ? prop.user.name : "",
          total: `$${parseFloat(prop.total_cost).toFixed(2)}`,
        };
        setGrandtotal(Number(grand_total) + Number(prop.total_cost))
        if (data && data.event) {
          if (data.event.event_type === "culinary_hosting") {
            let totalQty = 0
            prop.items.forEach((item) => {
              if (item.menuCulinaries?.length > 0) {
                item.menuCulinaries.forEach((culinary) => {
                  obj[culinary?.menu_title] = culinary.quantity;
                  totalQty = totalQty + culinary.quantity;
                })
              }
            });
          } else {

            prop.items.forEach((item) => {
              if (item?.menuClass) {
                obj["number_of_guests"] = item.quantity;
              }
            });
          }
        }

        return obj;
      });
      // if (data && data.event) {
      //   if (
      //     data.event.event_type === "culinary_hosting" &&
      //     data.totals &&
      //     Object.keys(data.totals).length > 0
      //   ) {
      //     const obj = {
      //       id: "total_per_col",
      //       user: "",
      //       total: ``,
      //     };
      //     Object.keys(data.totals).forEach((key) => {
      //       obj[key] = `${data.totals[key]}`;
      //     });
      //     d.push(obj);
      //   }
      // }

      setRows(d);
    },
    // eslint-disable-next-line
    [data]
  );

  const columns = [
    {
      Header: "User",
      accessor: "user",
      Footer: "Grand Total"
    },
  ];

  if (data && data.event) {
    if (data.event.event_type === "culinary_hosting") {
      productsTitles.forEach((p) => {
        columns.push({
          Header: p,
          accessor: p,
          Footer: function Total(props: any) {
            let totalItemValue = 0
            props?.data.forEach(element => {
              for (var key in element) {
                if (key === p) {
                  if (element[key] !== undefined && element[key] !== 0)
                    totalItemValue += Number(element[key])
                }
              }
            });
            return (
              <div>{totalItemValue}</div>
            );
          }
        });
      });
    } else if (data.event.event_type === "classes") {
      columns.push({
        Header: "Number of guests",
        accessor: "number_of_guests",
      });
    }
  }
  // columns.push({
  //   Header: "Total Qty.",
  //   accessor: "totalQty",
  //   Cell: function Total(props: any) {
  //     return (
  //       <div>{props.value}</div>
  //     );
  //   },
  // });
  columns.push({
    Header: "Total",
    accessor: "total",
    Cell: function Total(props: any) {
      return (
        <div style={{ textAlign: 'right' }} >{props.value}</div>
      );
    },
    Footer: function Total(props: any) {
      let totalValue = 0
      props?.data.forEach(element => {
        var t = element.total.substring(1)
        totalValue += Number(t)
      });
      return (
        <div style={{ textAlign: 'right' }} >{totalValue.toFixed(2)}</div>
      );
    }
  });

  const tableProps = {
    data: rows,
    page: 0,
    filterable: true,
    sortable: false,
    columns,
    minRows: 1,
    showPaginationBottom: false,
    className: "-striped -highlight",
    //    style: {
    //      textAlign: "center",
    //    },
  };
  if (data && data.length) {
    tableProps.pageSize = data.length;
  }
  console.log('grand_total', grand_total);
  console.log('rows', rows);
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              <span>Events</span>
              {data && data.grand_total ? (
                <strong>Total: ${parseFloat(data.grand_total).toFixed(2)}</strong>
              ) : (
                ""
              )}
            </h4>
          </CardHeader>
          <CardBody>
            <ReactTable {...tableProps} />
            <div>

            </div>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
