import * as actionTypes from '../actions/ui/types';

const INITIAL_STATE = {
    homeImages: [],
}
// eslint-disable-next-line
export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case actionTypes.SET_HOME_IMAGES_LOADING:
            return { ...state, homeImagesLoading: action.payload };
        case actionTypes.SET_HOME_IMAGES:
            return { ...state, homeImages: action.payload };
        case actionTypes.SHOW_LOGIN_DIALOG:
            return { ...state, loginDialogVisible: true, signupDialogVisible: false };
        case actionTypes.SHOW_SIGNUP_DIALOG:
            return { ...state, loginDialogVisible: false, signupDialogVisible: true };
        case actionTypes.HIDE_LOGIN_DIALOG:
            return { ...state, user: action.payload, loginDialogVisible: false};
        case actionTypes.HIDE_SIGNUP_DIALOG:
            return { ...state, signupDialogVisible: false };
        case actionTypes.SHOW_CART_DIALOG:
                return { ...state, cartDialogVisible: true };    
        case actionTypes.HIDE_CART_DIALOG:
            return { ...state, user: action.payload, cartDialogVisible: false};
        default:
            return state;
    }
}