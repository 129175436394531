import Recipes from "views/Recipes/Recipes";
// @material-ui/icons
import LocalDining from "@material-ui/icons/LocalDining";
//import School from "@material-ui/icons/School";
import Event from "@material-ui/icons/Event";
import HomeIcon from "@material-ui/icons/Home";

import RecipeDetails from "views/RecipeDetails/RecipeDetails";
import ShopDetails from "views/ShopDetails/ShopDetails";
import Home from "views/Home/Home";
import Terms from "views/Terms/Terms";
import Privacy from "views/Privacy/Privacy";
import Contact from "views/Contact/Contact";
import Events from "views/Events/Events";
import EventDetails from "views/EventDetails/EventDetails";

import AdminOrder from "views/Admin/Order";
import Orders from "views/Orders";
import AdminEventOrders from "views/Admin/Events/Orders/Orders";
import Profile from "views/Profile/Profile";
import Cart from "views/Cart/Cart";
import Checkout from "views/Checkout/Checkout";
import PayPalSuccess from "views/Checkout/PayPalSuccess";
import PayPalCancel from "views/Checkout/PayPalCancel";
import ConfirmOrder from "views/Checkout/ConfirmOrder";
import EmailActivation from "views/EmailActivation/EmailActivation";
import SetPassword from "views/SetPassword/SetPassword";

import {ReactComponent as MealsIcon} from 'assets/img/dinner_dining-black-24dp.svg';
import Freshmeals from "views/Freshmeals/Freshmeals";
import FreshmealDetails from "views/FreshmealDetails/FreshmealDetails";
var dashRoutes = [
  {
    path: "/",
    exact: true,
    name: "Home",
    icon: HomeIcon,
    component: Home,
    sidebarVisible: true,
  },

  {
    path: "/home",
    exact: true,
    name: "Home",
    icon: HomeIcon,
    component: Home,
  },
  {
    path: "/app/cart",
    exact: true,
    name: "cart",
    component: Cart,
  },
  {
    path: "/app/checkout",
    exact: true,
    name: "checkout",
    component: Checkout,
  },
  {
    path: "/app/payment-confirm",
    exact: true,
    name: "PayPalSuccess",
    component: PayPalSuccess,
  },
  {
    path: "/app/payment-cancel",
    exact: true,
    name: "PayPalSuccess",
    component: PayPalCancel,
  },
  {
    path: "/app/confirm-order/:id",
    exact: true,
    name: "confirmorder",
    component: ConfirmOrder,
  },
  // {
  //   path: "/app/login",
  //   exact: true,
  //   name: "login",
  //   component: Login,
  // },
  // {
  //   path: "/app/create",
  //   exact: true,
  //   name: "signup",
  //   component: Signup,
  // },
  {
    path: "/account/emailActivation",
    exact: true,
    name: "emailActivation",
    component: EmailActivation,
  },
  {
    path: "/auth/verifyEmail",
    exact: true,
    name: "verifyEmail",
    component: SetPassword,
  },
  {
    path: "/profile",
    exact: true,
    name: "profile",
    component: Profile,
  },

  {
    path: "/terms",
    exact: true,
    name: "Terms",
    component: Terms,
  },

  {
    path: "/privacy",
    exact: true,
    name: "Privacy",
    component: Privacy,
  },
  {
    path: "/contact",
    exact: true,
    name: "Contact",
    component: Contact,
  },
  {
    path: "/app/recipes",
    exact: true,
    name: "Recipes",
    rtlName: "لوحة القيادة",
    icon: LocalDining,
    component: Recipes,
    sidebarVisible: false,
    nameRoute: "/app/recipes",
  },

  {
    path: "/app/recipes/:id",
    component: RecipeDetails,
    name: "Recipe Details",
    nameRoute: "/app/recipes",
  },

  {
    path: "/app/events",
    exact: true,
    name: "Events",
    rtlName: "الدروس",
    icon: Event,
    component: Events,
    sidebarVisible: true,
    nameRoute: "/app/events",
  },

  {
    path: "/app/events/:id",
    component: EventDetails,
    name: "Event Details",
    nameRoute: "/app/events",
  },

  // {
  //   path: "/app/shop",
  //   exact: true,
  //   name: "Shop",
  //   rtlName: "المتجر",
  //   icon: Store,
  //   component: Shop,
  //   sidebarVisible: true,
  //   nameRoute: "/app/shop",
  // },

  {
    path: "/app/shop/:id",
    component: ShopDetails,
    layout: "/app",
    name: "Shop Details",
    nameRoute: "/app/shop",
  },
  {
    path: "/app/orders",
    component: Orders,
    layout: "/app",
    name: "User Orders",
    nameRoute: "/app/orders",
  },
  {
    path: "/admin/orders",
    component: AdminOrder,
  },
  {
    path: "/admin/events/orders",
    component: AdminEventOrders,
  },
  {
    path: "/app/freshmeals",
    exact: true,
    name: "Fresh Meals",
    icon: MealsIcon,
    component: Freshmeals,
    sidebarVisible: true,
    nameRoute: "/app/freshmeals",
  },
  {
    path: "/app/freshmeals/:id",
    component: FreshmealDetails,
    layout: "/app",
    name: "Freshmeal Details",
    nameRoute: "/app/freshmeals",
  }
];
export default dashRoutes;
