import * as actionTypes from './types';
import Api from '../../shared/api';
import requestAgent from '../../shared/requestAgent';


export const actionCreator = () => ({

});


export const setLoading = (loading) => ({
    type: actionTypes.SET_LOADING,
    payload: loading,
});

export const setEvents = (events) => ({
    type: actionTypes.SET_EVENTS,
    payload: events,
});

export const setSelectedEvent = c => ({
    type: actionTypes.SET_SELECTED_EVENT,
    payload: c,
})

export const fetchEvents = () => (dispatch) => {
    dispatch(setLoading(true));
    requestAgent.get(Api.event.getEvents())
    .then(({ body }) => {
        const events = body.res_data.data.events || [];
        dispatch(setEvents(events))
        dispatch(setLoading(false));
    })
    .catch(err => {
        console.log(err);
        dispatch(setLoading(false));
    })
}

export const fetchEventBySlug = slug => (dispatch) => {
    dispatch(setLoading(true));
    requestAgent.get(Api.event.getBySlug(slug))
    .then(({ body }) => {
        const c = body.res_data.data.event || null;
        dispatch(setSelectedEvent(c))
        dispatch(setLoading(false));
    })
    .catch(err => {
        console.log(err);
        dispatch(setLoading(false));
    })
}

export const fetchEventById = id => (dispatch) => {
    dispatch(setLoading(true));
    requestAgent.get(Api.event.getById(id))
    .then(({ body }) => {
        const c = body.res_data.data.event || null;
        dispatch(setSelectedEvent(c))
        dispatch(setLoading(false));
    })
    .catch(err => {
        console.log(err);
        dispatch(setLoading(false));
    })
}