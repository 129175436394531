import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import PlayCircleFilled from "@material-ui/icons/PlayCircleFilledWhiteRounded";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import AdminLayout from "layouts/Admin.js";

import { fetchRecipes } from "actions/recipe";
import { getYoutubeUrlFromId } from "shared/utils/helpers";
import { getImagePath } from "shared/utils/helpers";
import Loader from "components/Loader";

const useStyles = makeStyles(styles);

function Dashboard(props) {
  const classes = useStyles();
  const { fetchRecipes, loading } = props;

  useEffect(() => {
    fetchRecipes();
  }, [fetchRecipes]);

  return (
    <AdminLayout>
      <div>
        <Loader visible={loading} />
        <GridContainer>
          {props.recipes
            ? props.recipes.map((recipe) => {
                const video = recipe.media
                  ? recipe.media.find((m) => m.mediaType === "youtube")
                  : null;

                return (
                  <GridItem
                    xs={12}
                    sm={12}
                    md={4}
                    key={`recipe-item-${recipe.slug}`}
                  >
                    <Card product className={classes.clearCardBG}>
                      <CardHeader image className={classes.cardHeaderHover}>
                        <Link to={`/app/recipes/${recipe.slug}`}>
                          <img
                            className={classes.cardHeaderImage}
                            src={getImagePath(recipe.media[0].url)}
                            alt="..."
                          />
                        </Link>
                        {video && (
                          <a
                            className={classes.videoUrl}
                            href={getYoutubeUrlFromId(video.youtubeId)}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <PlayCircleFilled fontSize="large" />
                          </a>
                        )}
                      </CardHeader>
                      <CardFooter
                        product
                        className={`${classes.cardFooter} ${classes.clearCardFooter}`}
                      >
                        <h4 className={classes.cardProductTitle}>
                          <Link to={`/app/recipes/${recipe.slug}`}>
                            {recipe.title}
                          </Link>
                        </h4>
                        <Link
                          to={`/app/recipes/${recipe.slug}`}
                          className={classes.cardProductDesciprion}
                        >
                          {recipe.subtitle}
                        </Link>
                      </CardFooter>
                    </Card>
                  </GridItem>
                );
              })
            : null}
        </GridContainer>
      </div>
    </AdminLayout>
  );
}

Dashboard.propTypes = {
  fetchRecipes: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  recipes: PropTypes.array,
};

const mapStateToProps = ({ recipe }) => ({
  loading: recipe.loading,
  recipes: recipe.recipes,
});

const mapDispatchToProps = (dispatch) => ({
  fetchRecipes: () => dispatch(fetchRecipes()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);
