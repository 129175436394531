import * as actionTypes from "./types";
import Api from "../../../shared/api";
import requestAgent from "../../../shared/requestAgent";
import swal from "sweetalert";


export const createUser = (email) => (dispatch) => {

  dispatch({ type: actionTypes.SIGNUP });
  requestAgent
    .post(Api.user.create(), { email })
    .then((res) => {
      if (!res.body.success) {
        swal({
          text: res.body.res_text,
        });
        signupUserFail(dispatch, res.body.res_text)
      } else if (res.body.res_data && res.body.res_data.data) {
        signupUserSuccess(dispatch, res.body.res_data.data)
      }
    })
    .catch((err) => {
      console.log(err);
      signupUserFail(dispatch, err)
    });
};


export const signupGoogleUser = (token) => (dispatch) => {

  dispatch({ type: actionTypes.SIGNUP_GOOGLE });
  requestAgent
    .post(Api.user.loginGoogle(), { token })
    .then((res) => {
      if (!res.body.success) {
        swal({
          text: res.body.res_text,
        });
        signupGoogleUserFail(dispatch, res.body.res_text)
      } else if (res.body.res_data && res.body.res_data.data) {
        signupGoogleUserSuccess(dispatch, res.body.res_data.data)
      }
    })
    .catch((err) => {
      console.log('errr', err);
      signupGoogleUserFail(dispatch, err)
    });
};

const signupUserSuccess = (dispatch, user) => {
  dispatch({
    type: actionTypes.SIGNUP_SUCCESS
  });
}
const signupUserFail = (dispatch, error) => {
  dispatch({
    type: actionTypes.SIGNUP_FAIL
  });
}

const signupGoogleUserSuccess = (dispatch, user) => {
  dispatch({
    type: actionTypes.SIGNUP_GOOGLE_SUCCESS
  });
}
const signupGoogleUserFail = (dispatch, error) => {
  dispatch({
    type: actionTypes.SIGNUP_GOOGLE_FAIL
  });
}