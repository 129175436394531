/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import styles from "assets/jss/material-dashboard-pro-react/components/footerStyle.js";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import YouTubeIcon from "@material-ui/icons/YouTube";
import InstagramIcon from "@material-ui/icons/Instagram";
import { Hidden } from "@material-ui/core";
import LocalDining from "@material-ui/icons/LocalDining";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { fluid, white, rtlActive, footerPanel, transparent } = props;
  var container = cx({
    [classes.container]: !fluid,
    [classes.containerFluid]: fluid,
    [classes.whiteColor]: white,
  });
  var anchor =
    classes.a +
    cx({
      [" " + classes.whiteColor]: white,
    });
  var block = cx({
    [classes.block]: true,
    [classes.whiteColor]: white,
  });
  const termsList = (
    <div>
      <List className={classes.list}>
        <ListItem className={classes.inlineBlock}>
          <Link to={"/terms"} className={block}>
            TERMS
          </Link>
        </ListItem>
        <ListItem className={classes.inlineBlock}>
          <Link to={"/privacy"} className={block}>
            PRIVACY
          </Link>
        </ListItem>
        <ListItem className={classes.inlineBlock}>
          <Link to={"/contact"} className={block}>
            CONTACT
          </Link>
        </ListItem>
      </List>
    </div>
  );
  const copyrights = (
    <div>
      <Link to={"/"} className={block} style={{ padding: 15 }}>
        &copy; {rtlActive ? "توقيت الإبداعية" : "FRESHTABLE"}
        &nbsp; {1900 + new Date().getYear()}{" "}
      </Link>
    </div>
  );
  const socialIcons = (
    <div style={{ padding: 15 }}>
      <a
        href="https://www.instagram.com/freshtable.io/"
        style={{ color: "white", margin: "0 1rem", display: "inline-block" }}
        target="_blank"
        referrerPolicy="noopener noreferrer"
      >
        <InstagramIcon />
      </a>
      <a
        href="https://www.youtube.com/channel/UCQ1jDGX6p6XvOSiH8UvNQdA/"
        style={{ color: "white", margin: "0 1rem", display: "inline-block" }}
        target="_blank"
        referrerPolicy="noopener noreferrer"
      >
        <YouTubeIcon />
      </a>
      <a
        href="/app/recipes"
        style={{ color: "white", margin: "0 1rem", display: "inline-block" }}
        target="_blank"
        referrerPolicy="noopener noreferrer"
      >
        <LocalDining />
      </a>
    </div>
  );
  const footerContent = (
    <div>
      <Hidden smDown>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {termsList}
          {socialIcons}
          {copyrights}
        </div>
      </Hidden>
      <Hidden mdUp>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: 'column',
          }}
        >
          {socialIcons}
          {copyrights}
          {termsList}
        </div>
      </Hidden>
    </div>
  );
  return (
    <footer
      className={`${classes.footer} ${
        transparent ? classes.footerTransparent : ""
      }`}
    >
      {footerPanel ? (
        <Card style={{ margin: 0 }}>
          <CardBody style={{ display: "flex", alignItems: "center" }}>
            {footerContent}
          </CardBody>
        </Card>
      ) : (
        footerContent
      )}
    </footer>
  );
}

Footer.propTypes = {
  fluid: PropTypes.bool,
  white: PropTypes.bool,
  rtlActive: PropTypes.bool,
  transparent: PropTypes.bool,
};
