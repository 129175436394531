import * as actionTypes from './types';
import { showSuccessSnackbar } from "../snackbar";

export const addToCart = (product, quantity, showCartDialog, date, time) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.ADD_TO_CART });
        try {
            localStorage.removeItem('culinaries')
            let products = []
            let match = false
            let match1 = false
            let localproducts = localStorage.getItem('products')
            if (localproducts) {
                products = JSON.parse(localproducts)
                for (let i = 0; i < products.length; i++) {
                    const element = products[i];
                    if (element._id === product._id && !product.id) {
                        match = true
                        element.quantity = element.quantity + quantity
                        // if(element.quantity ===0){
                        //     products.splice(i, 1)
                        // }
                    } else if (element._id === product._id && product.id && element?.meal_title && element?.meal_title === product?.meal_title) {
                        match = true
                        for (let k = 0; k < element.meal_items.length; k++) {
                            const element1 = element.meal_items[k];
                            if (element1.meal_item_title === product.meal_items[0].meal_item_title) {
                                match1 = true
                                element1.quantity = element1.quantity + product.meal_items[0].quantity
                            }
                        }
                        if (!match1) {
                            element.meal_items.push(product.meal_items[0])
                        }
                    } else if (element._id === product._id && product.id && element.title === product.title) {
                        match = true
                        for (let l = 0; l < element.menuCulinaries.length; l++) {
                            const element2 = element.menuCulinaries[l];
                            if (element2.menu_title === product.menuCulinaries[0].menu_title) {
                                match1 = true
                                element2.quantity = element2.quantity + product.menuCulinaries[0].quantity
                            }
                        }
                        if (!match1) {
                            element.menuCulinaries.push(product.menuCulinaries[0])
                        }
                    }
                }
                if (!match) {
                    // product.quantity = quantity
                    if (date) {
                        product.date = date
                        product.time = time
                    }
                    products.push(product)
                    products = JSON.stringify(products)
                    localStorage.setItem('products', products);
                } else {
                    products = JSON.stringify(products)
                    localStorage.setItem('products', products);
                }
            } else {
                // product.quantity = quantity
                if (date) {
                    product.date = date
                    product.time = time
                }
                products.push(product)
                localproducts = JSON.stringify(products)
                localStorage.setItem('products', localproducts);
            }
            products = JSON.parse(localproducts)
            let productQuantities = 0
            productQuantities = getNumberOfCart(products)
            dispatch({
                type: actionTypes.ADD_TO_CART_SUCCESS,
                payload: products,
                productQuantities
            });
            if (showCartDialog) {
                getCartFromLocal()
                dispatch(showSuccessSnackbar("Added to cart!"));
                // dispatch({ type: uiActionTypes.SHOW_CART_DIALOG });
            }
        } catch (error) {
            dispatch({ type: actionTypes.ADD_TO_CART_FAIL });
        }
    };
};

export const deleteToCart = (index) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.DELETE_TO_CART });
        try {
            let products = []
            let localproducts = localStorage.getItem('products')
            products = JSON.parse(localproducts)
            products.splice(index, 1)
            products = JSON.stringify(products)
            localStorage.setItem('products', products);
            products = JSON.parse(products)
            let productQuantities = 0
            productQuantities = getNumberOfCart(products)
            dispatch({
                type: actionTypes.DELETE_TO_CART_SUCCESS,
                payload: products,
                productQuantities
            });
        } catch (error) {
            dispatch({ type: actionTypes.DELETE_TO_CART_FAIL });
        }
    };
};

export const getCartFromLocal = () => {
    return (dispatch) => {
        dispatch({ type: actionTypes.GET_TO_CART });
        try {
            let products = []
            let productQuantities = 0
            let localproducts = localStorage.getItem('products')
            if (localproducts) {
                products = JSON.parse(localproducts)
                productQuantities = getNumberOfCart(products)
            }
            dispatch({
                type: actionTypes.GET_TO_CART_SUCCESS,
                payload: products,
                productQuantities
            });
        } catch (error) {
            dispatch({ type: actionTypes.GET_TO_CART_FAIL });
        }
    };
};

function getNumberOfCart(data) {
    let productQuantity = 0
    for (let i = 0; i < data.length; i++) {
        const element = data[i];
        if (element?.event_type === "classes") {
            productQuantity = productQuantity + Number(element?.quantity)
        }
        else if (element?.event_type === "culinary_hosting") {
            for (let j = 0; j < element?.menuCulinaries.length; j++) {
                const element1 = element?.menuCulinaries[j];
                productQuantity = productQuantity + Number(element1?.quantity)
            }
        }
        else if (element?.meal_items?.length) {
            for (let j = 0; j < element?.meal_items.length; j++) {
                const element2 = element?.meal_items[j];
                productQuantity = productQuantity + Number(element2?.quantity)
            }
        }
    }
    return productQuantity
}

