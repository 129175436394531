import * as actionTypes from "./types";
import { history } from "store";

export const logoutUser = () => (dispatch) => {
  dispatch({ type: actionTypes.LOGOUT });
  let products = window.localStorage.getItem('products');
  window.localStorage.clear()
  window.localStorage.setItem('products',products);
  if (history.location.pathname === '/profile') {
    history.push("/")
  }
  else {
    history.go(0)
  }
};