import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import HomeLayout from 'layouts/Home'
import styles from "assets/jss/material-dashboard-pro-react/views/lockScreenPageStyle.js";

const useStyles = makeStyles(styles);

export default function Home() {
  const classes = useStyles();
  return (
    <HomeLayout>

      <div className={classes.container}>

      </div>
    </HomeLayout>
  );
}
