import * as actionTypes from "./types";

const INITIAL_STATE = {
  loginLoading: false,
  loginGoogleLoading: false,
  loginFacebookLoading: false,
  user: null,
  loginSuccess: false,
  loginGoogleSuccess: false,
  loginFacebookSuccess: false,
  error: '',
};

// eslint-disable-next-line
export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.LOGIN:
      return { ...state, loginLoading: true, loginSuccess: false };
    case actionTypes.LOGIN_FAIL:
      return { ...state, loginLoading: false, loginSuccess: false };
    case actionTypes.LOGIN_SUCCESS:
      return { ...state, user: action.payload, loginLoading: false, loginSuccess: true };
    case actionTypes.LOGIN_GOOGLE:
      return { ...state, loginGoogleLoading: true, loginGoogleSuccess: false };
    case actionTypes.LOGIN_GOOGLE_FAIL:
      return { ...state, loginGoogleLoading: false, loginGoogleSuccess: false };
    case actionTypes.LOGIN_GOOGLE_SUCCESS:
      return { ...state, user: action.payload, loginGoogleLoading: false, loginGoogleSuccess: true };
    case actionTypes.LOGIN_FACEBOOK:
      return { ...state, loginFacebookLoading: true, loginFacebookSuccess: false };
    case actionTypes.LOGIN_FACEBOOK_FAIL:
      return { ...state, loginFacebookLoading: false, loginFacebookSuccess: false };
    case actionTypes.LOGIN_FACEBOOK_SUCCESS:
      return { ...state, user: action.payload, loginGoogleLoading: false, loginGoogleSuccess: true };
    case actionTypes.LOGOUT:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
}
