import React, { useEffect } from "react";
import { connect } from "react-redux";

import Loader from "components/Loader";
import { fetchOrdersByUser } from "actions/order";
import OrderTable from "./OrderTable";

function Orders(props:any) {
    const {
        orders,
        loading,
        fetchOrdersByUser,
    } = props;
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'))
        if (user) {
            fetchOrdersByUser(user._id);               //5f440cf143a5c9ce4a7861ff
        }
        // eslint-disable-next-line
    }, [])

    return (
        <div>
            <OrderTable
                title="Orders"
                data={orders}
            />
            <Loader visible={loading} />
        </div>
    );
}

const mapStateToProps = ({ order }) => ({
    orders: order.userOrderItems,
    loading: order.loading,
});

const mapDispatchToProps = (dispatch) => ({
    fetchOrdersByUser: (id) => dispatch(fetchOrdersByUser(id)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Orders);
