import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom"
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Button,
  Step,
  StepLabel,
  Stepper,
  StepContent,
  TextField,
  Checkbox,
  FormControlLabel,
  Select,
  CircularProgress,
  Dialog,
  IconButton
} from "@material-ui/core";
import { countries } from '../../shared/constants'
import { withRouter } from "react-router-dom";
import swal from "@sweetalert/with-react";
import Admin from "layouts/Admin";
import { CardCvcElement, CardNumberElement, CardExpiryElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { getCartFromLocal } from "actions/cart";
import { getImagePath } from "shared/utils/helpers";
import { orderSubmit, orderSubmitPaypal, orderSubmitPaypalSuccess, closePaypalModal } from "actions/order";
import { NavLink } from "react-router-dom";
import { showLoginDialog } from "actions/ui";
import { logoutUser } from "actions/user";
import PropTypes from "prop-types";
import PayPalSuccess from './PayPalSuccess'
import { Close } from "@material-ui/icons";
import PaypalWhite from "../../assets/img/paypalWhite.png";
import { history } from "store";
import moment from "moment-timezone";

const stripePromise = loadStripe(process.env.REACT_APP_FT_STRIPE_PUBLIC_KEY);

const firstnameError = "First name is required"
const lastnameError = "Last name is required"
const addressError = "Address is required"
const cityError = "City is required"
const provinceError = "Province is required"
const postalcodeError = "Postal code is required"

const PayPalButton = window.paypal && window.paypal.Buttons.driver("react", { React, ReactDOM });

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  cardInput: {
    border: '1px solid lightgrey',
    height: 40,
    borderRadius: 4,
    alignItems: 'center',
    backgroundColor: '#fff',
    padding: 10,
    fontSize: '16px',
    fontFamily: '"Open Sans", sans-serif',
    fontSmoothing: 'antialiased',
    '::placeholder': {
      color: '#CFD7DF',
    },
  },
  cardInputName: {
    border: '1px solid lightgrey',
    height: 40,
    borderRadius: 4,
    backgroundColor: '#fff',
    alignItems: 'center',
    paddingLeft: 10,
    paddingTop: 4,
  },
  guestBtn: {
    marginLeft: 20,
    color: '#fff',
    fontSize: 13,
    height: 40,
    backgroundColor: "#D3D3D3"
  },
  btnInactive: {
    backgroundColor: "#D3D3D3",
    "&:hover": {
      backgroundColor: "#D3D3D3"
    },
  },
  btnActive: {
    backgroundColor: "#333333",
    "&:hover": {
      backgroundColor: "#333333"
    },
  },
}));


function getSteps() {
  return ['CUSTOMER', 'PAYMENT'];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return `Checking out as a Guest? You'll be able to save your details to create an account with us later.`;
    case 1:
      return `How PayPal Works | PayPal Safety and Security`;
    case 2:
      return ``;
    default:
      return 'All Transaction are secure and encrypted.';
  }
}

function Checkout(props) {
  const {
    loadingOrder,
    getCartFromLocal,
    showLoginDialog,
    itemsAdded,
    cartItems,
    orderSubmit,
    orderSubmitPaypalSuccess,
    paypalModal,
    closePaypalModal,
    logoutUser
  } = props

  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  // eslint-disable-next-line
  const [checkout, setCheckOut] = useState(false);
  const [stripeModal, setstripeModal] = useState(false);
  const steps = getSteps();
  const [state, setState] = useState({
    checkedA: true,
    firstname: { value: "", error: true, errorText: firstnameError, initErrorText: firstnameError },
    lastname: { value: "", error: true, errorText: lastnameError, initErrorText: lastnameError },
    address: { value: "", error: true, errorText: addressError, initErrorText: addressError },
    city: { value: "", error: true, errorText: cityError, initErrorText: cityError },
    country: 'United States',
    province: { value: "", error: true, errorText: provinceError, initErrorText: provinceError },
    postalcode: { value: "", error: true, errorText: postalcodeError, initErrorText: postalcodeError },
    cardnumber: { value: "", error: true },
    cardname: { value: "", error: true },
    cvv: { value: "", error: true },
    expiry: { value: "", error: true },
    email: { value: "", textFieldValue: "", error: true, errorText: "Email address is required" },
    tableData: [],
    shippingError: false
  });
  const [stateBilling, setStateBilling] = useState({
    firstname: { value: "", error: true, errorText: firstnameError, initErrorText: firstnameError },
    lastname: { value: "", error: true, errorText: lastnameError, initErrorText: lastnameError },
    address: { value: "", error: true, errorText: addressError, initErrorText: addressError },
    city: { value: "", error: true, errorText: cityError, initErrorText: cityError },
    country: 'United States',
    province: { value: "", error: true, errorText: provinceError, initErrorText: provinceError },
    postalcode: { value: "", error: true, errorText: postalcodeError, initErrorText: postalcodeError },
    billingError: false
  });

  let disabledShipping = state.firstname.error || state.lastname.error || state.province.error || state.city.error || state.address.error || state.province.error || state.postalcode.error
  let disabledBilling = stateBilling.firstname.error || stateBilling.lastname.error || stateBilling.province.error || stateBilling.city.error || stateBilling.address.error || stateBilling.province.error || stateBilling.postalcode.error


  let user = null;
  if (window.localStorage.getItem("user")) {
    user = JSON.parse(window.localStorage.getItem("user"))
  }


  useEffect(() => {
    if (itemsAdded) {
      getCartFromLocal()
    }
    if (!itemsAdded && cartItems && cartItems.length < 1) {
      history.push('/')
    }
    if (cartItems) {
      setState({ ...state, tableData: cartItems })
    }
    // eslint-disable-next-line
  }, [itemsAdded])

  useEffect(() => {

    if (user && !state.email.value) {
      setActiveStep(1)
      const email = { value: user?.email, textFieldValue: user?.email, error: false, errorText: "" }
      setState(pre => ({
        ...pre,
        email: email
      }))
    }
    // eslint-disable-next-line
  }, [])


  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const handleChange = (event) => {
    if (event.target.name === 'checkedA') {
      setState({ ...state, [event.target.name]: event.target.checked })
    }
    else if (event.target.name === 'email') {
      let email = { value: "", textFieldValue: event.target.value, error: true, errorText: "Email address is required" }
      if (event.target.value) {
        if (!validateEmail(event.target.value)) {
          email.errorText = "Email address must be valid"
        }
        else {
          email = { value: event.target.value, textFieldValue: event.target.value, error: false, errorText: "" }
        }
      }
      setState({ ...state, [event.target.name]: email });
    } else {
      let values = event.target.name
      values = {
        value: event.target.value,
        error: event.target.value ? false : true,
        errorText: event.target.value === '' ? state[event.target.name].initErrorText : '',
        initErrorText: state[event.target.name].initErrorText
      }
      setState({ ...state, [event.target.name]: values });
    }
  };

  const handleBillingChange = (event) => {
    let values = event.target.name
    values = {
      value: event.target.value,
      error: event.target.value ? false : true,
      errorText: event.target.value === '' ? stateBilling[event.target.name].initErrorText : '',
      initErrorText: stateBilling[event.target.name].initErrorText
    }
    setStateBilling({ ...stateBilling, [event.target.name]: values });
  };

  const handleNext = (nextStep) => {
    if (activeStep === 1 && disabledShipping) {
      setState({
        ...state,
        shippingError: true
      })
    }
    else if (activeStep === 2 && disabledBilling) {
      setStateBilling({
        ...stateBilling,
        billingError: true
      })
    }
    else {
      setActiveStep((prevActiveStep) => prevActiveStep + nextStep);
    }
  };



  const handleChangeCard = (type, value) => {
    let values = type
    values = {
      value: value.value,
      error: value.value ? false : true,
    }
    setState({ ...state, [type]: values });
  };

  const onOrderSubmit = async (stripe, elements) => {
    if (!state.email.value) {
      swal({
        text: "Please fill your name and email",
        icon: "info",
      });
    } else {
      const card = elements.getElement(CardNumberElement, CardExpiryElement, CardCvcElement);
      const result = await stripe.createToken(card);
      if (result.error) {
        setState({
          ...state,
          orderError: true
        })
      } else {
        let products = []
        let item_type = ""
        for (let k = 0; k < state.tableData.length; k++) {
          const element = state.tableData[k];
          let value = {}
          if (element.meal_title) {
            value = {
              product: element._id,
              event: element._id,
              meal_title: element.meal_title,
              meal_items: element.meal_items,
              slug: element.slug,
              item_type: "meals",
              notes: element.notes ? element.notes : '',
              meal_date: element.meal_date,
              meal_order_type: element.meal_order_type,
            }
          }
          else if (element.event_type === 'culinary_hosting') {
            value = {
              meal: element._id,
              event: element._id,
              title: element.title,
              menuCulinaries: element.menuCulinaries,
              slug: element.slug,
              item_type: "events",
              event_type: element.event_type,
              notes: element.notes ? element.notes : '',
              event_date: element.event_date,
              order_type: element.order_type,
              currency: "USD",
            }
          } else {
            value = {
              meal: element._id,
              event: element._id,
              title: element.title,
              menuClass: element.menuClass,
              quantity: element.quantity,
              event_price: element.event_price,
              slug: element.slug,
              item_type: "events",
              event_type: element.event_type,
              notes: element.notes ? element.notes : '',
              event_date: element.event_date,
              order_type: element.order_type,
              currency: "USD",
              date: element.date ? element.date : '',
              time: element.time ? element.time : '',
            }

          }
          products.push(value)
        }
        const eventsType = products.some(e => e.item_type === 'events')
        const productType = products.some(e => (e.item_type === 'meals' || e.item_type === 'meal'))
        if (eventsType && productType) {
          item_type = "events_products"
        } else if (eventsType) {
          item_type = "events"
        } else {
          item_type = "meals"
        }
        const values = {
          "amount": Number(totalPrice()).toFixed(2),
          "email": state.email.value,
          "token": result.token.id,
          "items": products,
          "item_type": item_type
        }
        // eslint-disable-next-line
        orderSubmit(values);

      }
    }
  };

  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: `${Number(totalPrice()).toFixed(2)}`,
          },
        },
      ],
    });
  };

  const onApprove = async (data, actions) => {
    // console.log('data, actions',data, actions)
    const capture = await actions.order.capture();
    onOrderSubmitPaypal(capture)
    // const intervalID = setInterval(changeCapture, 100)
    // const changeCapture = () => {
    //   console.log('setsssss',capture)
    //   if (capture?.resolved) {
    //     console.log('capture', capture)
    //     clearInterval(intervalID)
    //     onOrderSubmitPaypal(capture.value)
    //   }
    // }

    // return capture
  };

  const onOrderSubmitPaypal = (payment) => {
    if (!state.email.value) {
      swal({
        text: "Please fill your name and email",
        icon: "info",
      });
    } else {
      let products = []
      let products1 = []
      let item_type = ""
      for (let k = 0; k < state.tableData.length; k++) {
        const element = state.tableData[k];
        let value = {}
        let value1 = {}
        if (element.meal_title) {
          value = {
            meal: element._id,
            event: element._id,
            meal_title: element.meal_title,
            meal_items: element.meal_items,
            slug: element.slug,
            item_type: element.event_price ? "events" : "meals",
            notes: element.notes ? element.notes : '',
            meal_date: element.meal_date,
            meal_order_type: element.meal_order_type,
            currency: "USD",
          }

          for (let l = 0; l < element.meal_items.length; l++) {
            const element3 = element.meal_items[l];
            value1 = {
              name: element.meal_title,
              price: `${element3.meal_item_price.toFixed(2)}`,
              currency: "USD",
              quantity: element3.quantity,
            }
            products1.push(value1)
          }

        } else if (element.event_type === 'culinary_hosting') {
          value = {
            meal: element._id,
            event: element._id,
            title: element.title,
            menuCulinaries: element.menuCulinaries,
            slug: element.slug,
            item_type: "events",
            event_type: element.event_type,
            notes: element.notes ? element.notes : '',
            event_date: element.event_date,
            order_type: element.order_type,
            currency: "USD",
          }
          for (let l = 0; l < element.menuCulinaries.length; l++) {
            const element4 = element.menuCulinaries[l];
            value1 = {
              name: element.meal_title,
              price: `${element4.price.toFixed(2)}`,
              currency: "USD",
              quantity: element4.quantity,
            }
            products1.push(value1)
          }
        }
        else {
          value = {
            meal: element._id,
            event: element._id,
            title: element.title,
            menuClass: element.menuClass,
            quantity: element.quantity,
            event_price: element.event_price,
            slug: element.slug,
            item_type: "events",
            event_type: element.event_type,
            notes: element.notes ? element.notes : '',
            event_date: element.event_date,
            order_type: element.order_type,
            currency: "USD",
            date: element.date ? element.date : '',
            time: element.time ? element.time : '',
          }
          value1 = {
            name: element.title,
            price: `${element.event_price}`,
            currency: "USD",
            quantity: element.quantity,
          }
          products1.push(value1)
        }

        products.push(value)
        const eventsType = products.some(e => e.item_type === 'events')
        const productType = products.some((e => e.item_type === 'meals' || e.item_type === 'meal'))
        if (eventsType && productType) {
          item_type = "events_products"
        } else if (eventsType) {
          item_type = "events"
        } else {
          item_type = "meals"
        }

      }
      const values = {
        "amount": Number(totalPrice()).toFixed(2),
        "email": state.email.value,
        "items": products,
        "items1": products1,
        "item_type": item_type,
        "payment": payment,
        "return_url": `${process.env.REACT_APP_FT_CLIENT_ROOT}${process.env.REACT_APP_FT_PAYPAL_PAYMENT_RETURN_URL}`,
        "cancel_url": `${process.env.REACT_APP_FT_CLIENT_ROOT}${process.env.REACT_APP_FT_PAYPAL_PAYMENT_CANCEL_URL}`
      }
      orderSubmitPaypalSuccess(values);
    }
  };

  const totalPrice = () => {
    let total = 0
    if (state.tableData) {
      for (let i = 0; i < state.tableData.length; i++) {
        const element = state.tableData[i];
        if (element?.meal_items?.length) {

          for (let j = 0; j < element.meal_items.length; j++) {
            const element1 = element.meal_items[j];

            total = total + (Number(element1.meal_item_price ? element1.meal_item_price : element.event_price ? element.event_price : element.price) * (element1.quantity ? element1.quantity : 0))
          }
        } else if (element?.menuCulinaries?.length) {
          for (let j = 0; j < element.menuCulinaries.length; j++) {
            const element1 = element.menuCulinaries[j];

            total = total + (Number(element1.price ? element1.price : element.event_price ? element.event_price : element.price) * (element1.quantity ? element1.quantity : 0))
          }
        }
        else if (element?.event_type === 'classes') {
          total = total + (Number(element.event_price) * (element.quantity))
        }
      }
      return total.toFixed(2)
    }
  }

  const getTotalPrice = (data) => {
    let total = 0
    for (let i = 0; i < data?.length; i++) {
      const element = data[i];
      total = total + (element.quantity * element.meal_item_price)

    }
    return total
  }


  const getTotalPriceEvent = (data) => {
    let total = 0
    for (let i = 0; i < data?.length; i++) {
      const element = data[i];
      total = total + (element.quantity * element.price)

    }
    return total
  }


  const { shippingError } = state;
  return (
    <Admin backgroundColor="white" containerStyles={{ alignItems: "unset" }}>
      <Grid container>
        <Grid item xs={12}>
          <Typography
            style={{ color: "black", marginBottom: "2rem", fontFamily: 'proxima-nova,sans-serif' }}
            align="center"
            variant="h4"
          >
            Checkout
          </Typography>
        </Grid>
        <Grid item container justify={'space-between'} style={{ marginTop: 40 }}>
          <Grid item md={6} >
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel >
                    <Grid container justify={'space-between'} alignItems={"center"}>
                      <Grid xs={2}>
                        <Typography style={{ color: '#000', fontWeight: 'bold' }}>
                          {label}
                        </Typography>
                      </Grid>
                      {
                        index === 2 && !disabledShipping &&
                        <Grid container item xs={8} alignItems={"center"} justify={"flex-end"}>
                          <Grid style={{ textAlign: 'right', marginRight: 10 }}>
                            <Typography style={{ fontSize: 12 }}>{state.firstname.value + " " + state.lastname.value}</Typography>
                            <Typography style={{ fontSize: 12 }}>{state.address.value}</Typography>
                            <Typography style={{ fontSize: 12 }}>{state.city.value ? state.city.value + " ," + state.province.value + " , " + state.postalcode.value + " / " + state.country : ''}</Typography>
                          </Grid>
                          <Button onClick={() => setActiveStep(1)} style={{ backgroundColor: '#333333', color: '#fff', fontSize: 13, height: 40 }}>EDIT</Button>
                        </Grid>
                      }
                      {
                        index === 3 && !disabledShipping && state.checkedA &&
                        <Grid container item xs={8} alignItems={"center"} justify={"flex-end"}>
                          <Grid style={{ textAlign: 'right', marginRight: 10 }}>
                            <Typography style={{ fontSize: 12 }}>{state.firstname.value + " " + state.lastname.value}</Typography>
                            <Typography style={{ fontSize: 12 }}>{state.address.value}</Typography>
                            <Typography style={{ fontSize: 12 }}>{state.city.value ? state.city.value + " ," + state.province.value + " , " + state.postalcode.value + " / " + state.country : ''}</Typography>
                          </Grid>
                          <Button onClick={() => setActiveStep(1)} style={{ backgroundColor: '#333333', color: '#fff', fontSize: 13, height: 40 }}>EDIT</Button>
                        </Grid>
                      }
                      {
                        index === 3 && !disabledBilling && !state.checkedA &&
                        <Grid container item xs={8} alignItems={"center"} justify={"flex-end"}>
                          <Grid style={{ textAlign: 'right', marginRight: 10 }}>
                            <Typography style={{ fontSize: 12 }}>{stateBilling.firstname.value + " " + stateBilling.lastname.value}</Typography>
                            <Typography style={{ fontSize: 12 }}>{stateBilling.address.value}</Typography>
                            <Typography style={{ fontSize: 12 }}>{stateBilling.city.value ? stateBilling.city.value + " ," + stateBilling.province.value + " , " + stateBilling.postalcode.value + " / " + stateBilling.country : ''}</Typography>
                          </Grid>
                          <Button onClick={() => setActiveStep(2)} style={{ backgroundColor: '#333333', color: '#fff', fontSize: 13, height: 40 }}>EDIT</Button>
                        </Grid>
                      }
                      {
                        (index === 0 && (user || state.email.value) &&
                          <Grid>{state.email.value}
                            {user ?
                              (<Button
                                onClick={() => { logoutUser() }}
                                style={{ backgroundColor: "white", borderStyle: "solid", borderWidth: "2px", marginLeft: "15px", }}
                              >
                                {"SIGN OUT"}
                              </Button>) : ((activeStep !== 0) && <Button onClick={() => setActiveStep(0)}
                                style={{ backgroundColor: "white", borderStyle: "solid", borderWidth: "2px", marginLeft: "15px", }}>{"EDIT"}</Button>)
                            }
                          </Grid>)}
                    </Grid>
                  </StepLabel>
                  <StepContent>
                    {((index !== 0 || (index === 0 && !user)) &&
                      <Typography variant={"body2"}>{index !== 1 && getStepContent(index)} {index === 1 && <span>How PayPal Works <a href="https://www.paypal.com/webapps/mpp/how-paypal-works" target="blank"><img src="https://img.icons8.com/material-outlined/24/000000/external-link.png" alt="external" /></a> | PayPal Safety and Security <a href="https://www.paypal.com/us/webapps/mpp/paypal-safety-and-security" target="blank"><img src="https://img.icons8.com/material-outlined/24/000000/external-link.png" alt="external" /></a></span>} {index === 3 && <span style={{ border: '1px solid #3232ff', color: '#3232ff', padding: 5, fontSize: 12, borderRadius: 3 }}>powered by <b>stripe</b></span>}</Typography>)
                    }
                    {
                      (index === 0 && !user) ?
                        <>
                          <React.Fragment>
                            <Grid container justify={'space-between'} style={{ marginTop: 20, marginBottom: 20 }} alignItems={"flex-start"}>
                              <Grid item xs={7}>
                                <TextField value={state.email.textFieldValue} onChange={handleChange} fullWidth inputProps={{ style: { padding: 11 } }} variant={"outlined"}
                                  name="email"
                                  placeholder={"Email Address"}
                                  helperText={state.email.errorText}
                                  error={state.email.error} />
                              </Grid>
                              <Grid item xs={5}>
                                <Button onClick={() => { return !state.email.error && handleNext(1) }} className={[classes.guestBtn, (!state.email.error ? classes.btnActive : classes.btnInactive)].join(' ')}>CONTINUE AS GUEST</Button>
                              </Grid>
                            </Grid>
                            <Typography>Already have an account? <span style={{ color: '#000', fontWeight: 'bold' }}>
                              <NavLink
                                // eslint-disable-next-line
                                href={"javascript:void(0)"}
                                onClick={() => { showLoginDialog() }}
                              >Login Now</NavLink></span></Typography>
                          </React.Fragment>
                        </>
                        :
                        <>
                        </>
                    }
                    {
                      index === 2 &&
                      <React.Fragment>
                        <Grid container justify={'space-between'} style={{ marginTop: 20, marginBottom: 20, height: 50 }} alignItems={"flex-start"}>
                          <Grid item xs={6}>
                            <TextField fullWidth inputProps={{ style: { padding: 11 } }} onChange={handleChange}
                              name="firstname" helperText={shippingError && state.firstname.errorText}
                              error={state.firstname.error && shippingError} value={state.firstname.value} variant={"outlined"} placeholder={"First Name"} />
                          </Grid>
                          <Grid item xs={5}>
                            <TextField fullWidth inputProps={{ style: { padding: 11 } }} onChange={handleChange}
                              name="lastname" helperText={shippingError && state.lastname.errorText}
                              error={state.lastname.error && shippingError} value={state.lastname.value} variant={"outlined"} placeholder={"Last Name"} />
                          </Grid>
                        </Grid>

                        <Grid container style={{ marginTop: 20, marginBottom: 20, height: 50 }} alignItems={"flex-start"}>
                          <Grid item xs={12}>
                            <TextField fullWidth inputProps={{ style: { padding: 11 } }} onChange={handleChange}
                              name="address" helperText={shippingError && state.address.errorText}
                              error={state.address.error && shippingError} value={state.address.value} variant={"outlined"} placeholder={"Address"} />
                          </Grid>
                        </Grid>

                        <Grid container style={{ marginTop: 20, marginBottom: 20, height: 50 }} alignItems={"flex-start"}>
                          <Grid item xs={12}>
                            <TextField fullWidth inputProps={{ style: { padding: 11 } }} onChange={handleChange}
                              name="city" helperText={shippingError && state.city.errorText}
                              error={state.city.error && shippingError} value={state.city.value} variant={"outlined"} placeholder={"City"} />
                          </Grid>
                        </Grid>

                        <Grid container style={{ marginTop: 20, marginBottom: 20, height: 50 }} alignItems={"flex-start"}>
                          <Grid item xs={12}>
                            <Select
                              native
                              placeholder={'Country'}
                              disableUnderline
                              className={classes.selector}
                              value={state.country}
                              fullWidth
                              inputProps={{ style: { padding: 11 } }}
                              name="country"
                              variant={"outlined"}
                              onChange={handleChange}
                            >
                              <option value="">Country</option>
                              {
                                countries.map((country, key) => {
                                  return (
                                    <option key={key} value={country.nationality}>{country.nationality}</option>
                                  )
                                })
                              }
                            </Select>
                          </Grid>
                        </Grid>

                        <Grid container justify={'space-between'} style={{ marginTop: 20, marginBottom: 20, height: 50 }} alignItems={"flex-start"}>
                          <Grid item xs={6}>
                            <TextField fullWidth inputProps={{ style: { padding: 11 } }} onChange={handleChange}
                              name="province" helperText={shippingError && state.province.errorText}
                              error={state.province.error && shippingError} value={state.province.value} variant={"outlined"} placeholder={"State/Province"} />
                          </Grid>
                          <Grid item xs={5}>
                            <TextField fullWidth inputProps={{ style: { padding: 11 } }} onChange={handleChange}
                              name="postalcode" helperText={shippingError && state.postalcode.errorText}
                              error={state.postalcode.error && shippingError} value={state.postalcode.value} variant={"outlined"} placeholder={"Postal Code"} />
                          </Grid>
                        </Grid>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={state.checkedA}
                              onChange={handleChange}
                              name="checkedA"
                              color="primary"
                            />
                          }
                          label="My billing address is the same as my shipping address."
                        />
                        <Grid container justify={'space-between'} style={{ marginTop: 20, marginBottom: 20 }} alignItems={"center"}>
                          <Grid item xs={5}>
                            <Button onClick={() => handleNext(state.checkedA ? 2 : 1)} style={{ backgroundColor: '#333333', color: '#fff', fontSize: 13, height: 40 }}>CONTINUE</Button>
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    }
                    {
                      index === 3 &&
                      <React.Fragment>
                        <Grid container justify={'space-between'} style={{ marginTop: 20, marginBottom: 20, height: 50 }} alignItems={"flex-start"}>
                          <Grid item xs={6}>
                            <TextField fullWidth inputProps={{ style: { padding: 11 } }} onChange={handleBillingChange}
                              name="firstname" helperText={stateBilling.billingError && stateBilling.firstname.errorText}
                              error={stateBilling.firstname.error && stateBilling.billingError} value={stateBilling.firstname.value} variant={"outlined"} placeholder={"First Name"} />
                          </Grid>
                          <Grid item xs={5}>
                            <TextField fullWidth inputProps={{ style: { padding: 11 } }} onChange={handleBillingChange}
                              name="lastname" helperText={stateBilling.billingError && stateBilling.lastname.errorText}
                              error={stateBilling.lastname.error && stateBilling.billingError} value={stateBilling.lastname.value} variant={"outlined"} placeholder={"Last Name"} />
                          </Grid>
                        </Grid>

                        <Grid container style={{ marginTop: 20, marginBottom: 20, height: 50 }} alignItems={"flex-start"}>
                          <Grid item xs={12}>
                            <TextField fullWidth inputProps={{ style: { padding: 11 } }} onChange={handleBillingChange}
                              name="address" helperText={stateBilling.billingError && stateBilling.address.errorText}
                              error={stateBilling.address.error && stateBilling.billingError} value={stateBilling.address.value} variant={"outlined"} placeholder={"Address"} />
                          </Grid>
                        </Grid>

                        <Grid container style={{ marginTop: 20, marginBottom: 20, height: 50 }} alignItems={"flex-start"}>
                          <Grid item xs={12}>
                            <TextField fullWidth inputProps={{ style: { padding: 11 } }} onChange={handleBillingChange}
                              name="city" helperText={stateBilling.billingError && stateBilling.city.errorText}
                              error={stateBilling.city.error && stateBilling.billingError} value={stateBilling.city.value} variant={"outlined"} placeholder={"City"} />
                          </Grid>
                        </Grid>

                        <Grid container style={{ marginTop: 20, marginBottom: 20, height: 50 }} alignItems={"flex-start"}>
                          <Grid item xs={12}>
                            <Select
                              native
                              placeholder={'Country'}
                              disableUnderline
                              className={classes.selector}
                              value={stateBilling.country}
                              fullWidth
                              inputProps={{ style: { padding: 11 } }}
                              name="country"
                              variant={"outlined"}
                              onChange={handleBillingChange}
                            >
                              <option value="">Country</option>
                              {
                                countries.map((country, key) => {
                                  return (
                                    <option key={key} value={country.nationality}>{country.nationality}</option>
                                  )
                                })
                              }
                            </Select>
                          </Grid>
                        </Grid>

                        <Grid container justify={'space-between'} style={{ marginTop: 20, marginBottom: 20, height: 50 }} alignItems={"flex-start"}>
                          <Grid item xs={6}>
                            <TextField fullWidth inputProps={{ style: { padding: 11 } }} onChange={handleBillingChange}
                              name="province" helperText={stateBilling.billingError && stateBilling.province.errorText}
                              error={stateBilling.province.error && stateBilling.billingError} value={stateBilling.province.value} variant={"outlined"} placeholder={"State/Province"} />
                          </Grid>
                          <Grid item xs={5}>
                            <TextField fullWidth inputProps={{ style: { padding: 11 } }} onChange={handleBillingChange}
                              name="postalcode" helperText={stateBilling.billingError && stateBilling.postalcode.errorText}
                              error={stateBilling.postalcode.error && stateBilling.billingError} value={stateBilling.postalcode.value} variant={"outlined"} placeholder={"Postal Code"} />
                          </Grid>
                        </Grid>
                        <Grid container justify={'space-between'} style={{ marginTop: 20, marginBottom: 20 }} alignItems={"center"}>
                          <Grid item xs={5}>
                            <Button onClick={() => handleNext(1)} style={{ backgroundColor: '#333333', color: '#fff', fontSize: 13, height: 40 }}>CONTINUE</Button>
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    }
                    {
                      index === 1 &&
                      <Grid item xs={12} style={{ marginTop: 20 }}>
                        {/* <Accordion square expanded={expanded === 'panel1'} onChange={handleChangeAccordion('panel1')}>
                          <AccordionSummary style={{ backgroundColor: expanded === 'panel1' ? '#f5f5f5' : "#fff" }} aria-controls="creditcardd-content" id="creditcardd-header">
                            <Grid container justify={'space-between'}>
                              <Grid container alignItems={"center"} item xs={4}>
                                <Radio
                                  checked={selectedValue === 'creditcard'}
                                  onChange={handleChangeRadio}
                                  color="primary"
                                  value="creditcard"
                                  name="creditcard"
                                  inputProps={{ 'aria-label': 'A' }}
                                />
                                <Typography>Credit Card</Typography>
                              </Grid>
                              <Grid container item justify={'flex-end'} xs={8}>
                                <img alt={""} src={Visa} style={{ width: 40, height: 30, marginRight: 5 }} />
                                <img alt={""} src={Ame} style={{ width: 40, height: 30, marginRight: 5 }} />
                                <img alt={""} src={Master} style={{ width: 40, height: 30, marginRight: 5 }} />
                                <img alt={""} src={Discover} style={{ width: 40, height: 30, marginRight: 5 }} />
                              </Grid>
                            </Grid>
                          </AccordionSummary>
                          */}
                        <Dialog open={stripeModal} fullWidth maxWidth={"lg"} PaperProps={{
                          style: {
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                            height: '100%',
                            marginTop: -20
                          },
                        }}>
                          <Grid container style={{ justifyContent: 'flex-end', alignItems: 'flex-start', }}>
                            <IconButton onClick={() => setstripeModal(false)}>
                              <Close style={{ color: '#fff' }} />
                            </IconButton>
                          </Grid>
                          <Grid container direction={"column"} alignItems={"center"} justify={"center"} style={{ marginTop: '5%' }}>
                            <Grid style={{ backgroundColor: '#fff', padding: '30px 30px', borderRadius: 5, width: 500 }}>
                              <Typography style={{ fontSize: 20, fontWeight: 'bold', color: '#000', marginBottom: 20 }}>Enter Credit Card Details</Typography>
                              <Elements stripe={stripePromise}>
                                <ElementsConsumer>
                                  {({ stripe, elements }) => (
                                    <Grid>
                                      <Grid container justify={'space-between'} alignItems={"center"}>
                                        <Grid item xs={12} className={classes.cardInput}>
                                          <CardNumberElement
                                            onChange={(value) => handleChangeCard('cardnumber', value)}
                                            options={{
                                              style: {
                                                base: {
                                                  color: '#303238',
                                                  fontSize: '16px',
                                                  fontFamily: '"Open Sans", sans-serif',
                                                  fontSmoothing: 'antialiased',
                                                  '::placeholder': {
                                                    color: '#CFD7DF',
                                                  },
                                                },
                                                invalid: {
                                                  color: '#e5424d',
                                                  ':focus': {
                                                    color: '#303238',
                                                  },
                                                },
                                              }
                                            }}
                                          />
                                        </Grid>
                                        {
                                          state.cardnumber.error && state.orderError &&
                                          <span style={{ color: 'red', fontSize: 12, marginTop: 5 }}>Card Number is required</span>
                                        }
                                      </Grid>
                                      <Grid container justify={'space-between'} style={{ marginTop: 20, marginBottom: 20 }} alignItems={"center"}>
                                        <Grid item xs={12} className={classes.cardInputName}>
                                          <TextField InputProps={{
                                            disableUnderline: true
                                          }} onChange={handleChange}
                                            name="cardname" value={state.cardname.value} placeholder={"Name on Card"} />
                                        </Grid>
                                        {
                                          state.cardname.error && state.orderError &&
                                          <span style={{ color: 'red', fontSize: 12, marginTop: 5 }}>Full Name is required</span>
                                        }
                                      </Grid>
                                      <Grid container justify={'space-between'} style={{ marginTop: 0, marginBottom: 20 }} alignItems={"center"}>
                                        <Grid item xs={5} container style={{ height: 50 }}>
                                          <Grid item xs={12} className={classes.cardInput} >
                                            <CardExpiryElement
                                              onChange={(value) => handleChangeCard('expiry', value)}
                                              options={{
                                                value: state.expiry,
                                                base: {
                                                  color: '#303238',
                                                  border: '1px solid grey',
                                                  fontSize: '16px',
                                                  fontFamily: '"Open Sans", sans-serif',
                                                  fontSmoothing: 'antialiased',
                                                  '::placeholder': {
                                                    color: '#CFD7DF',
                                                  },
                                                },
                                                invalid: {
                                                  color: '#e5424d',
                                                  ':focus': {
                                                    color: '#303238',
                                                  },
                                                },
                                              }} />
                                          </Grid>
                                          {
                                            state.expiry.error && state.orderError &&
                                            <span style={{ color: 'red', fontSize: 12, marginTop: 15 }}>Expiration Date is required</span>
                                          }
                                        </Grid>
                                        <Grid item xs={5} container style={{ height: 50 }}>
                                          <Grid item xs={12} className={classes.cardInput}>
                                            <CardCvcElement
                                              onChange={(value) => handleChangeCard('cvv', value)}
                                              options={{
                                                placeholder: "Security Code",
                                                base: {
                                                  color: '#303238',
                                                  border: '1px solid grey',
                                                  fontSize: '16px',
                                                  fontFamily: '"Open Sans", sans-serif',
                                                  fontSmoothing: 'antialiased',
                                                  '::placeholder': {
                                                    color: '#CFD7DF',
                                                  },
                                                },
                                                invalid: {
                                                  color: '#e5424d',
                                                  ':focus': {
                                                    color: '#303238',
                                                  },
                                                },
                                              }} />
                                          </Grid>
                                          {
                                            state.cvv.error && state.orderError &&
                                            <span style={{ color: 'red', fontSize: 12, marginTop: 15 }}>Security code is required</span>
                                          }
                                        </Grid>

                                      </Grid>
                                      <Grid item xs={12}>
                                        <Button fullWidth disabled={!stripe || loadingOrder} onClick={() => onOrderSubmit(stripe, elements)} style={{ backgroundColor: '#333333', color: '#fff', fontSize: 13, height: 40, marginTop: 20 }}>{loadingOrder ? <CircularProgress size={18} color={'#fff'} /> : "PLACE ORDER"}</Button>
                                      </Grid>
                                    </Grid>

                                  )}
                                </ElementsConsumer>
                              </Elements>
                            </Grid>
                          </Grid>
                        </Dialog>
                        {/* <Grid item xs={12} container direction={"column"} alignItems={"center"} justify={"center"} style={{ marginBottom: 20 }}>
                          <Button fullWidth onClick={() => setstripeModal(true)} style={{ backgroundColor: '#333333', color: '#fff', fontSize: 13, height: 40, marginTop: 20,marginBottom: 20 }}>{"Debit or Credit Card"}</Button>
                          <Typography variant={"body2"}> {<span style={{ border: '1px solid #3232ff', color: '#3232ff', padding: 5, fontSize: 12, borderRadius: 3 }}>powered by <b>stripe</b></span>}</Typography>
                        </Grid> */}
                        {/* </Accordion> */}
                        {/* <Accordion square expanded={expanded === 'panel2'} onChange={handleChangeAccordion('panel2')}>
                          <AccordionSummary style={{ backgroundColor: selectedValue === 'paypal' ? '#f5f5f5' : "#fff" }} aria-controls="creditcardd-content" id="creditcardd-header">
                            <Grid container justify={'space-between'}>
                              <Grid container alignItems={"center"} item xs={4}>
                                <Radio
                                  checked={selectedValue === 'paypal'}
                                  onChange={handleChangeRadio}
                                  color="primary"
                                  value="paypal"
                                  name="paypal"
                                  inputProps={{ 'aria-label': 'B' }}
                                />
                                <img alt={""} src={Paypal} width={'100px'} />
                              </Grid>
                              <Grid container item justify={'flex-end'} xs={8}>
                                <img alt={""} src={Visa} style={{ width: 40, height: 30, marginRight: 5 }} />
                                <img alt={""} src={Ame} style={{ width: 40, height: 30, marginRight: 5 }} />
                                <img alt={""} src={Master} style={{ width: 40, height: 30, marginRight: 5 }} />
                                <img alt={""} src={Discover} style={{ width: 40, height: 30, marginRight: 5 }} />
                              </Grid>
                            </Grid>
                          </AccordionSummary>
                        </Accordion> */}
                        {/* {
                          selectedValue === 'paypal' && */}
                        <Grid item xs={12}>
                          {checkout ?
                            <PayPalSuccess /> :
                            <>

                              <PayPalButton
                                style={{ marginBottom: 25 }}
                                funding={{
                                  allowed: [
                                    window.paypal && window.paypal.FUNDING.CARD,
                                  ],
                                  disallowed: [
                                    window.paypal && window.paypal.FUNDING.CREDIT,
                                  ],
                                }}

                                createOrder={(data, actions) => createOrder(data, actions)}
                                onApprove={(data, actions) => onApprove(data, actions)}
                              />
                            </>
                            // <Button fullWidth disabled={loadingOrder} onClick={() => onOrderSubmitPaypal()} style={{ backgroundColor: '#333333', color: '#fff', fontSize: 13, height: 40, marginTop: 20 }}>{loadingOrder ? <CircularProgress size={18} color={'#fff'} /> : "Pay with "}{!loadingOrder && <b style={{ marginLeft: 5, fontWeight: 'bolder' }}>PayPal</b>}</Button>
                          }
                        </Grid>
                        {/* } */}

                      </Grid>
                    }
                  </StepContent>
                </Step>
              ))}
            </Stepper>

          </Grid>
          <Grid item md={5} justify={'flex-end'}>
            <Grid container justify={'space-between'}>
              <Typography style={{ color: '#000', fontWeight: 'bold' }}>ORDER SUMMARY</Typography>
            </Grid>
            <hr style={{ color: '#000' }} />
            {
              state.tableData && state.tableData.length > 0 && state.tableData.map((item, index) => {
                return (
                  <Grid key={index} container alignItems={'center'} style={{ marginTop: 10 }} justify={'space-between'}>
                    <Grid container item xs={10} alignItems={'center'}>
                      <img alt={""} style={{ marginRight: 30, width: 70, height: 70, }} src={getImagePath(item?.meal_items?.length > 0 ? item?.meal_items[0]?.media[0]?.url : item?.menuCulinaries?.length ? item?.menuCulinaries[0]?.media[0]?.url : item.media[0].url)} />
                      <Grid xs={9}>
                        <Typography style={{ color: '#000', fontWeight: 'bold', fontSize: 18 }}>{item?.meal_title ? item?.meal_title : item.event_type === 'culinary_hosting' ? item?.title : item?.title + " x " + item?.quantity}</Typography>
                        <div style={{ color: 'black', marginTop: 5, marginBottom: 10 }}>{
                          "Event Date - " + moment.utc(item.meal_title ? item.meal_date : item.event_date).format("MM/DD/YYYY hh:mm a")
                        }</div>
                        {
                          item?.meal_items?.map((item, index) => (
                            <div key={index} style={{ fontWeight: 'bold', color: 'black', marginTop: 5, marginBottom: 10 }}>{item?.meal_item_title + ': x' + item?.quantity}</div>
                          ))
                        }
                        {
                          item?.menuCulinaries?.map((item, index) => (
                            <div key={index} style={{ fontWeight: 'bold', color: 'black', marginTop: 5, marginBottom: 10 }}>{item?.menu_title + ': x' + item?.quantity}</div>
                          ))
                        }
                      </Grid>
                    </Grid>
                    <Typography style={{ color: '#000' }}>${item?.meal_items?.length ? getTotalPrice(item?.meal_items).toFixed(2) : item.event_type === "classes" ? (Number(item?.event_price) * Number(item.quantity)).toFixed(2) : getTotalPriceEvent(item?.menuCulinaries).toFixed(2)}</Typography>
                  </Grid>
                )
              })
            }
            <hr style={{ color: '#000' }} />
            <Grid container justify={'space-between'}>
              <Typography style={{ color: '#000', fontWeight: 'bold' }}>Subtotal</Typography>
              <Typography style={{ color: '#000', fontWeight: 'bold' }}>${totalPrice()}</Typography>
            </Grid>
            <Grid container style={{ marginTop: 10 }} justify={'space-between'}>
              <Typography style={{ color: '#000' }}>Shipping</Typography>
              <Typography style={{ color: '#000' }}>--</Typography>
            </Grid>
            <Grid container style={{ marginTop: 10 }} justify={'space-between'}>
              <Typography style={{ color: '#000' }}>Tax</Typography>
              <Typography style={{ color: '#000' }}>$0.00</Typography>
            </Grid>
            <hr style={{ color: '#000' }} />
            <Grid container justify={'space-between'}>
              <Typography style={{ color: '#000', fontWeight: 'bold' }}>Total (USD)</Typography>
              <Typography style={{ color: '#000', fontWeight: 'bold' }}>${totalPrice()}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Dialog fullWidth maxWidth={"lg"} PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          height: '100%',
          marginTop: -20
        },
      }} open={paypalModal}>
        <Grid container style={{ justifyContent: 'flex-end', alignItems: 'flex-start', }}>
          <IconButton onClick={() => closePaypalModal()}>
            <Close style={{ color: '#fff' }} />
          </IconButton>
        </Grid>
        <Grid container alignItems={"center"} justify={"center"} style={{ marginTop: '10%' }} >
          <img alt={'paypal'} src={PaypalWhite} style={{ width: 200, height: 55, marginBottom: 30 }} />
          <div style={{ color: '#fff', textAlign: 'center', width: '100%' }}>{`Don't see the secure PayPal browser? We'll help`}</div>
          <div style={{ color: '#fff', textAlign: 'center', width: '100%' }}>{` you re - launch the window to complete your`}</div>
          <div style={{ color: '#fff', textAlign: 'center', width: '100%' }}>{` purchase`}</div>
          <div style={{ color: '#fff', fontWeight: 'bold', fontSize: 18, textDecoration: 'underline', marginTop: 30, cursor: 'pointer' }} onClick={() => onOrderSubmitPaypal()}>Click to Continue</div>
        </Grid>
      </Dialog>
    </Admin >
  );
}


const mapStateToProps = ({ product, order, cart }) => ({
  loading: product.loading,
  selectedProduct: product.selectedProduct,
  orderItems: order.orderItems,
  loadingOrder: order.loading,
  paypalModal: order.paypalModal,
  cartItems: cart.cartItems,
  itemsAdded: cart.itemsAdded,
});

const mapDispatchToProps = (dispatch) => ({
  getCartFromLocal: () => dispatch(getCartFromLocal()),
  showLoginDialog: () => dispatch(showLoginDialog()),
  orderSubmit: (params) => dispatch(orderSubmit(params)),
  orderSubmitPaypal: (params) => dispatch(orderSubmitPaypal(params)),
  orderSubmitPaypalSuccess: (params) => dispatch(orderSubmitPaypalSuccess(params)),
  closePaypalModal: () => dispatch(closePaypalModal()),
  logoutUser: () =>
    dispatch(logoutUser())
});

Checkout.propTypes = {
  loading: PropTypes.any,
  paypalModal: PropTypes.any,
  selectedProduct: PropTypes.any,
  orderItems: PropTypes.any,
  loadingOrder: PropTypes.bool,
  cartItems: PropTypes.any,
  itemsAdded: PropTypes.any,
  getCartFromLocal: PropTypes.func,
  showLoginDialog: PropTypes.func,
  orderSubmit: PropTypes.func,
  orderSubmitPaypal: PropTypes.func,
  orderSubmitPaypalSuccess: PropTypes.func,
  logoutUser: PropTypes.func,
  createProductOrder: PropTypes.func,
  requestEmailVerifyCode: PropTypes.func,
  closePaypalModal: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Checkout));