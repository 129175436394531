import React, { useState, useEffect } from "react";
import {
  IconButton,
  TextField,
  Grid,
  Typography,
  Button,
} from "@material-ui/core";
import { connect } from "react-redux";
import { Add, Remove } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import CartTable from "views/Cart/Table";
import { getCartFromLocal, deleteToCart, addToCart } from "actions/cart";
import { history } from "store";
import { hideCartDialog } from "actions/ui";

const useStyles = makeStyles((theme) => ({
  checkoutBtn: {
    marginTop: 20,
    backgroundColor: '#333333',
    color: '#fff',
    fontSize: 13,
    height: 40,
    '&:hover': {
      backgroundColor: '#333333',
      color: '#fff',
    },
  },
  continueShoppingBtn: {
    width: '49%',
    marginTop: 20,
    backgroundColor: 'white',
    border: '1px solid #333333',
    color: '#333333',
    fontSize: 13,
    height: 40
  },
  btnHalfWidth: {
    width: '49%',
  },
  btnFullWidth: {
    width: '100%',
  }
}));


function CartContent({ getCartFromLocal, itemsAdded, cartItems, deleteToCart, addToCart, cartDialogVisible, hideCartDialog, pricehide }: any) {
  const classes = useStyles();
  const [addPromo, setaddPromo] = useState(false)
  const [state, setstate] = useState({
    tableData: []
  });
  const toggleAddPromo = () => {
    setaddPromo(!addPromo)
  }
  useEffect(() => {
    if (itemsAdded) {
      getCartFromLocal()
    }
    if (cartItems) {
      setstate(pre => ({ ...pre, tableData: cartItems }))
    }
  }, [itemsAdded, cartItems, getCartFromLocal])

  const totalPrice = () => {
    let total = 0
    if (state.tableData) {
      for (let i = 0; i < state.tableData.length; i++) {
        const element = state.tableData[i];
        if (element?.meal_items?.length) {

          for (let j = 0; j < element.meal_items.length; j++) {
            const element1 = element.meal_items[j];

            total = total + (Number(element1.meal_item_price ? element1.meal_item_price : element.event_price ? element.event_price : element.price) * (element1.quantity ? element1.quantity : 0))
          }
        } else if (element?.menuCulinaries?.length) {
          for (let j = 0; j < element.menuCulinaries.length; j++) {
            const element1 = element.menuCulinaries[j];

            total = total + (Number(element1.price ? element1.price : element.event_price ? element.event_price : element.price) * (element1.quantity ? element1.quantity : 0))
          }
        }
        else if (element?.event_type === 'classes') {
          total = total + (Number(element.event_price) * (element.quantity))
        }
      }
      return total.toFixed(2)
    }
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography
          style={{ color: "black", marginBottom: "2rem", fontFamily: 'proxima-nova,sans-serif' }}
          align="center"
          variant="h4"
        >
          Cart
            </Typography>
      </Grid>
      {cartItems.length === 0 ?
        <>
          <Grid item xs={12}>
            <Typography
              style={{ color: "black", marginBottom: "2rem", fontFamily: 'proxima-nova,sans-serif' }}
              align="center"
              variant="h5">
              Your cart is currently empty.
              </Typography>
          </Grid>
        </>
        :
        <>
          <CartTable pricehide={pricehide} addToCart={addToCart} setstate={setstate} state={state} hideCartDialog={hideCartDialog} deleteToCart={deleteToCart} />
          <Grid item container justify={'flex-end'} style={{ marginTop: 40 }}>
            <Grid item md={6} xs={12} justify={'flex-end'}>
              <Grid container justify={'space-between'} alignItems={"center"}>
                <Typography style={{ color: '#000' }}>ADD PROMO CODE:</Typography>
                <IconButton onClick={() => toggleAddPromo()}>{!addPromo && <Add style={{ color: '#000' }} />}{addPromo && <Remove style={{ color: '#000' }} />}</IconButton>
              </Grid>
              {
                addPromo &&
                <Grid container justify={'space-between'} style={{ marginTop: 20, marginBottom: 20 }} alignItems={"center"}>
                  <Grid item xs={10}>
                    <TextField fullWidth inputProps={{ style: { padding: 11 } }} variant={"outlined"} placeholder={"Enter your coupon code"} />
                  </Grid>
                  <Grid item xs={2}>
                    <Button onClick={() => console.log('')} style={{ marginLeft: 20, backgroundColor: '#333333', color: '#fff', fontSize: 13, height: 40 }}>Apply</Button>
                  </Grid>
                </Grid>
              }
              <hr style={{ color: '#000' }} />
              <Grid container justify={'space-between'}>
                <Typography style={{ color: '#000' }}>ESTIMATED TOTAL:</Typography>
                <Typography style={{ color: '#000' }}>${totalPrice()}</Typography>
              </Grid>
              <Grid container direction={'row'} justify={'space-between'}>
                {cartDialogVisible &&
                  <Button onClick={() => hideCartDialog()} className={classes.continueShoppingBtn}>CONTINUE SHOPPING</Button>
                }
                <Button disabled={state.tableData.length === 0} fullWidth onClick={() => { hideCartDialog(); history.push('/app/checkout') }} className={[classes.checkoutBtn, (!cartDialogVisible ? classes.btnFullWidth : classes.btnHalfWidth)].join(' ')}>CHECK OUT</Button>
              </Grid>
            </Grid>
          </Grid>
        </>
      }
    </Grid>
  );
}

const mapDispatchToProps = (dispatch) => ({
  getCartFromLocal: () => dispatch(getCartFromLocal()),
  addToCart: (product, quantity, showCartDialog) => dispatch(addToCart(product, quantity, showCartDialog)),
  deleteToCart: (index) => dispatch(deleteToCart(index)),
  hideCartDialog: () => dispatch(hideCartDialog()),
});

const mapStateToProps = ({ cart, ui }) => ({
  cartItems: cart.cartItems,
  itemsAdded: cart.itemsAdded,
  cartDialogVisible: ui.cartDialogVisible,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CartContent);