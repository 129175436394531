export const ORDER_TYPE_MAP = {
  in_person: "In Person",
  pickup_only: "Pickup Only",
  pickup_delivery: "Pickup / Delivery",
  online: "Online",
};

export const countries = [
    {
        "num_code": "840",
        "alpha_2_code": "US",
        "alpha_3_code": "USA",
        "en_short_name": "United States of America",
        "nationality": "United States"
    }
];