import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import { clearSnackbar } from "./actions/snackbar";
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function SuccessSnackbar() {
    const dispatch = useDispatch();

    const { successSnackbarMessage, successSnackbarOpen } = useSelector(
        state => state.snackbar
    );

    function handleClose() {
        dispatch(clearSnackbar());
    }

    return (
        <Snackbar
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
            }}

            open={successSnackbarOpen}
            autoHideDuration={3000}
            onClose={handleClose}
            aria-describedby="client-snackbar"
        >
            <Alert severity="success">{successSnackbarMessage}</Alert>
        </Snackbar>
    );
}