import React, { useState, useCallback, useEffect } from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import {
  CircularProgress,
  Input,
  FormControl,
  FormHelperText,
  FormGroup,
  Grid,
  Typography,
  Button,
  IconButton,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { createUser, signupGoogleUser } from "./modules/actions";
import { GoogleLogin } from 'react-google-login';
import loginWithGoogle from "assets/img/google-icon.svg";
import { Link } from "react-router-dom";
import { history } from "store";
import PropTypes from 'prop-types';
import { showLoginDialog, hideLoginDialog, hideSignupDialog } from "actions/ui";

const GoogleClientID = process.env.REACT_APP_FT_GOOGLE_PUBLIC_KEY

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    left: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const useStyles = makeStyles({
  buttonProgress: {
    color: '#fff'
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
      <Typography variant="h6">{children}</Typography>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    maxWidth: '500px'
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function SignupDialog({ createUser, signupGoogleUser, signupGoogleLoading, signupGoogleSuccess, loading, signupSuccess, signupDialogVisible, showLoginDialog, hideLoginDialog, hideSignupDialog }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    hideLoginDialog()
  };
  const handleClose = () => {
    setOpen(false);
    hideSignupDialog()
  };
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();
  const [email, setEmail] = useState('');

  const onSubmit = (data) => {
    createUser(data.email)
  };

  useEffect(() => {
    if (signupSuccess) {
      history.push('/account/emailActivation')
    }
    // eslint-disable-line react-hooks/exhaustive-deps
  }, [signupSuccess])

  useEffect(() => {
    if (signupGoogleSuccess) {
      history.go(0)
    }
    // eslint-disable-next-line
  }, [signupGoogleSuccess])

  const handleGoogleOAuth = useCallback(
    (response) => {
      try {
        const values = {
          accessToken: response.getAuthResponse(true).id_token,
          provider: "google-oauth2",
        };
        signupGoogleUser(values.accessToken)
      } catch (error) {
        console.error(error);
      }
    },
    // eslint-disable-next-line
    []
  );
  if ((!signupDialogVisible || signupSuccess || signupGoogleSuccess) && open) {
    handleClose();
    return;
  }
  if (signupDialogVisible && !open && !signupSuccess && !signupGoogleSuccess) {
    handleClickOpen()
  }
  return (
    <div>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <Typography
            style={{ color: "black", marginBottom: "2rem", paddingTop: 40, }}
            align="center"
            variant="h4"
          >
            Create an account
            </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container>
            <Grid item xs={12}>
              <form onSubmit={handleSubmit(onSubmit)} style={{ marginRight: "10%", marginLeft: "10%", maxWidth: '80%', align: "center", }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <GoogleLogin
                      clientId={GoogleClientID}
                      render={renderProps => (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center ",
                          }}
                        >
                          <Button
                            style={{
                              backgroundColor: "rgb(67 121 228)",
                              color: " white",
                              padding: 1,
                              marginTop: 15,
                              borderRadius: 0,
                              width: '100%',
                              height: 60,
                              fontSize: 16,
                            }}
                            disabled={renderProps.disabled}
                            onClick={renderProps.onClick}
                            variant="contained"
                          >
                            <img
                              alt={"login img"}
                              src={loginWithGoogle}
                              width={33}
                              style={{ marginRight: 10, backgroundColor: '#fff', padding: 7 }}
                            />{" "}
                            <Typography
                              style={{
                                padding: "5px",
                                paddingRight: "2rem",
                                paddingLeft: "1.5rem",
                                textTransform: "none",
                                letterSpacing: '0.31px !important'
                              }}
                              align="center"
                              variant="p"
                            >
                              Continue with Google
                        </Typography>
                          </Button>
                        </div>
                      )}
                      buttonText="Login"
                      onSuccess={handleGoogleOAuth}
                      onFailure={handleGoogleOAuth}
                      cookiePolicy={'single_host_origin'}
                    />

                  </Grid>
                  <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <p
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        borderBottom: "1px solid #eee",
                        paddingBottom: 20,
                        width: '100%'
                      }}
                    ></p>
                    <p
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "1px solid #eee",
                        paddingBottom: 20,
                        backgroundColor: '#fff',
                        marginTop: -25,
                        width: 30,
                        fontSize: 16,
                        height: 30,
                        padding: 2,
                        paddingTop: 4,
                        borderRadius: 30,
                        color: 'grey'
                      }}
                    >OR</p>
                  </Grid>

                  <Grid item xs={12}>
                    <FormGroup>
                      {/* <p style={{ fontFamily: 'proxima-nova,sans-serif', color: 'black', fontWeight: "bold", fontSize: 15 }}>EMAIL ADDRESS</p> */}
                      <FormControl>
                        <Input
                          style={{
                            borderColor: ' #e6e6e6',
                            boxShadow: '0 1px 2px 0 #e6e6e6',
                            color: 'grey',
                            border: '1px solid #ccc',
                            fontFamily: 'proxima-nova,sans-serif',
                            padding: '5px 15px 5px 10px',
                            marginTop: -5
                          }}
                          error={!!errors.email}
                          value={email}
                          onChange={(value) => setEmail(value.target.value)}
                          name="email"
                          type="email"
                          placeholder="Email Address"
                          disableUnderline
                          inputRef={register({
                            required: true,
                          })}
                        />
                        {errors.email && errors.email.type === "required" && (
                          <FormHelperText style={{ color: "red", fontFamily: 'proxima-nova,sans-serif' }}>
                            Please enter a valid email address
                          </FormHelperText>
                        )}
                      </FormControl>
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        color="primary"
                        style={{
                          backgroundColor: "#1ea2d5",
                          textTransform: "none",
                          borderRadius: 1,
                          width: '100%',
                          height: 60,
                          margin: 'auto',
                          marginTop: 15,
                          marginBottom: 15,
                          fontSize: 16,
                          color: "white",
                          letterSpacing: 0.80
                        }}
                        fullWidth
                        variant="contained"
                        disabled={loading || Object.keys(errors).length > 0}
                        type="submit"
                      >
                        {
                          loading ?
                            <CircularProgress size={18} className={classes.buttonProgress} />
                            : "Create an account"
                        }
                      </Button>
                    </div>
                    <p style={{ marginTop: "1rem", fontWeight: 'bold', color: 'grey' }}>
                      By signing up or creating an account, you agree to the Freshtable <span style={{ color: '#3fbfba', fontWeight: 'normal' }}>Terms</span> & <span style={{ color: '#3fbfba', fontWeight: 'normal' }}>Privacy Policy</span>
                    </p>
                  </Grid>
                </Grid>
              </form>
              <Grid container spacing={3}>
                {/* <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                  <p
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      borderBottom: "1px solid #eee",
                      paddingBottom: 20,
                      width: '100%'
                    }}
                  ></p>
                  <p
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      border: "1px solid #eee",
                      paddingBottom: 20,
                      backgroundColor: '#fff',
                      marginTop: -25,
                      width: 30,
                      fontSize: 12,
                      height: 30,
                      padding: 2,
                      textAlign: 'center',
                      paddingTop: 4,
                      borderRadius: 30,
                      color: 'grey'
                    }}
                  >OR</p>
                </Grid> */}
                <Grid item xs={12} style={{ textAlign: "left", marginLeft: '10%', marginRight: '10%', paddingTop: '25px' }}>
                  <p style={{ fontWeight: 'bold', color: 'grey' }}>
                    Already have an account?
                    {/* <LoginDialog/> */}
                    <Link to="#" style={{ color: '#3fbfba', fontWeight: 'normal' }} onClick={() => { showLoginDialog() }}>{" Login now >"}</Link>
                  </p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ paddingTop: '10%' }}>
        </DialogActions>
      </Dialog>
    </div>
  );
}
SignupDialog.propTypes = {
  user: PropTypes.any,
  loading: PropTypes.bool,
  signupSuccess: PropTypes.bool,
  signupGoogleLoading: PropTypes.bool,
  signupGoogleSuccess: PropTypes.bool,
  signupDialogVisible: PropTypes.bool,
  createUser: PropTypes.func,
  signupGoogleUser: PropTypes.func,
  showLoginDialog: PropTypes.func,
  hideLoginDialog: PropTypes.func,
  hideSignupDialog: PropTypes.func,
}
const mapStateToProps = ({ signup, ui }) => ({
  user: signup.user,
  loading: signup.signupLoading,
  signupSuccess: signup.signupSuccess,
  signupGoogleLoading: signup.signupGoogleLoading,
  signupGoogleSuccess: signup.signupGoogleSuccess,
  signupDialogVisible: ui.signupDialogVisible,
});

const mapDispatchToProps = (dispatch) => ({
  createUser: (email, password) =>
    dispatch(createUser(email, password)),
  signupGoogleUser: (token) =>
    dispatch(signupGoogleUser(token)),
  showLoginDialog: () => dispatch(showLoginDialog()),
  hideLoginDialog: () => dispatch(hideLoginDialog()),
  hideSignupDialog: () => dispatch(hideSignupDialog()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignupDialog);