import * as actionTypes from '../actions/recipe/types';

const INITIAL_STATE = {
   recipes: [],
   loading: false,
   selectedRecipe: null,
}

// eslint-disable-next-line
export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case actionTypes.SET_LOADING:
            return { ...state, loading: action.payload };
        case actionTypes.SET_RECIPES:
            return { ...state, recipes: action.payload };
        case actionTypes.SET_SELECTED_RECIPE:
            return { ...state, selectedRecipe: action.payload };
        default:
            return state;
    }
}