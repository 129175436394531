import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

import Admin from "layouts/Admin";
import Loader from "components/Loader";
import Input from "@material-ui/core/Input";
import { authorizeAdmin, updateOrderStatus, fetchOrders } from "../modules/actions";
import { Button } from "@material-ui/core";
import OrderTable from "./OrderTable";

const styles = {
  marginBottom30: {
    marginBottom: 30,
  },
};

const useStyles = makeStyles(styles);

function Orders({ orders,
  authorized,
  loading,
  authorizeAdmin,
  updateOrderStatus,
  fetchOrders, }: any) {
  const classes = useStyles();

  const [email, setEmail] = useState("");
  const [roleCode, setRoleCode] = useState("");
  useEffect(() => {
    if (authorized) {
      fetchOrders();
    }
  }, [authorized, fetchOrders])

  return (
    <Admin>
      <div>
        {authorized ? (
          <div>
            <OrderTable
              title="Orders"
              data={orders}
              onChange={(order, changes) => {
                updateOrderStatus(order._id, changes.order_status);
              }}
            />
            <Loader visible={loading} />
          </div>
        ) : (
          <div style={{ width: "40%", margin: "2rem auto" }}>
            <div className={classes.marginBottom30}>
              <Input
                key="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                type="email"
              />
            </div>
            <div className={classes.marginBottom30}>
              <Input
                key="roleCode"
                fullWidth
                type="password"
                value={roleCode}
                onChange={(e) => setRoleCode(e.target.value)}
                placeholder="Role code"
              />
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                disabled={!email || !roleCode}
                color="primary"
                onClick={() => authorizeAdmin(email, roleCode)}
              >
                Submit
              </Button>
            </div>
          </div>
        )}
      </div>
    </Admin>
  );
}

const mapStateToProps = ({ admin }) => ({
  orders: admin.orders,
  loading: admin.ordersLoading,
  authorized: admin.authorized,
});

const mapDispatchToProps = (dispatch) => ({
  authorizeAdmin: (email, role_code) =>
    dispatch(authorizeAdmin(email, role_code)),
  fetchOrders: () => dispatch(fetchOrders()),
  updateOrderStatus: (orderId, order_status) =>
    dispatch(updateOrderStatus(orderId, order_status)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Orders);
