import React, { useEffect } from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

import moment from "moment";


export default function OrderTable({ data }: any) {
  const [rows, setRows] = React.useState([]);

  useEffect(
    function () {
      const d = (data || []).map((prop, key) => {
        return {
          _id: prop._id,
          id: prop._id,
          order_id: prop.order_id,
          order_status: prop.order_status,
          item_type: prop?.item_type??(prop?.items[0]?.event_type),
          total_qty: (prop.items || []).reduce((acc, i) => acc + (i.meal_items || []).reduce((item_quantity, j) => item_quantity + (j?.quantity??1), 0) +((i.meal_items&&i.meal_items.length>0)?0:(i?.quantity??1)), 0),
          order_created_at: prop.order_created_at
            ? moment(prop.order_created_at).format("MMM DD, YYYY hh:mm a")
            : "empty",
          total_cost: "$" + parseFloat(prop.total_cost).toFixed(2),
        };
      });
      setRows(d);
    },
    [data]
  );
  if (!data) return <div>No Orders set yet</div>;

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
          </CardHeader>
          <CardBody>
            <ReactTable
              data={rows}
              filterable
              sortable={false}
              columns={[
                {
                  Header: "Order ID",
                  accessor: "order_id",
                },
                {
                  Header: "Status",
                  accessor: "order_status",
                  width: 250,
                },
                {
                  Header: "Item Type",
                  accessor: "item_type",
                },
                {
                  Header: "Total Quantity",
                  accessor: "total_qty",
                },
                {
                  Header: "Total Cost",
                  accessor: "total_cost",
                },
                {
                  Header: "Created At",
                  accessor: "order_created_at",
                }
              ]}
              pageSize={data.length}
              //   showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
