import * as actionTypes from './types';
import Api from '../../shared/api';
import requestAgent from '../../shared/requestAgent';

// const MODULE_NAME = 'product';

export const actionCreator = () => ({

});


export const setLoading = (loading) => ({
    type: actionTypes.SET_LOADING,
    payload: loading,
});

export const setProducts = (products) => ({
    type: actionTypes.SET_PRODUCTS,
    payload: products,
});

export const setSelectedProduct = c => ({
    type: actionTypes.SET_SELECTED_PRODUCT,
    payload: c,
})

export const fetchProducts = () => (dispatch) => {
    dispatch(setLoading(true));
    requestAgent.get(Api.product.getProducts())
    .then(({ body }) => {
        const products = body.res_data.data.products || [];
        dispatch(setProducts(products))
        dispatch(setLoading(false));
    })
    .catch(err => {
        console.log(err);
        dispatch(setLoading(false));
    })
}

export const fetchProductBySlug = slug => (dispatch) => {
    dispatch(setLoading(true));
    requestAgent.get(Api.product.getBySlug(slug))
    .then(({ body }) => {
        const c = body.res_data.data.product || null;
        dispatch(setSelectedProduct(c))
        dispatch(setLoading(false));
    })
    .catch(err => {
        console.log(err);
        dispatch(setLoading(false));
    })
}

export const fetchProductById = id => (dispatch) => {
    dispatch(setLoading(true));
    requestAgent.get(Api.product.getById(id))
    .then(({ body }) => {
        const c = body.res_data.data.product || null;
        dispatch(setSelectedProduct(c))
        dispatch(setLoading(false));
    })
    .catch(err => {
        console.log(err);
        dispatch(setLoading(false));
    })
}