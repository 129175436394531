import React from "react";

import Admin from "layouts/Admin";
const styles = {
  wrapper: {
    width: "50%",
    margin: "1rem auto 5rem auto",
    color: "#666",
  },
  title: {
    color: "black",
  },
  section: {
    marginBottom: "3rem",
  },
};
function Terms() {
  return (
    <Admin headerPanel>
      <div style={styles.wrapper}>
        <h2>Terms</h2>
        <section style={styles.section}>
          <h4 style={styles.title}>TERMS OF USE</h4>
          <p>
            Any person is authorised to view the content on the
            freshtable.io website, unless the content is under geographic
            limitation and therefore not available in the country in which that
            person is accessing the material from. All information onthis server
            is protected by copyright law as the property of Freshtable. Unless otherwise specified, no one has permission to copy,
            republish, repurpose or redistribute in any form, any information or
            imagery found on the freshtable.io website without prior written
            consent by a representative of Freshtable.
          </p>
        </section>
      </div>
    </Admin>
  );
}

export default Terms;
