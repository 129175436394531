import React, { useEffect } from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Save from "@material-ui/icons/Save";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import Select from "@material-ui/core/Select";
import moment from "moment";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  order_edit_container: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },

  order_status_option: {
    padding: 10,
    "&:hover": {
      backgroundColor: "#eee",
      cursor: "pointer",
    },
  },
};

const useStyles = makeStyles(styles);

const ORDER_STATUS_ENUM = [
  "order_placed",
  "order_cancelled",
  "order_test",
  "order_shipped",
  "order_complete",
];

export default function OrderTable({ data, onChange }: any) {
  const [rows, setRows] = React.useState([]);
  const [editingOrder, setEditingOrder] = React.useState(null);

  const classes = useStyles();
  useEffect(
    function () {
      const d = (data || []).map((prop, key) => {
        return {
          _id: prop._id,
          id: prop._id,
          order_id: prop.order_id,
          order_status: prop.order_status,
          item_type: prop?.item_type ?? (prop?.items[0]?.event_type),
          user_name: prop.user ? prop.user.name : "",
          total_qty: (prop.items || []).reduce((acc, i) => acc + (i.meal_items || []).reduce((item_quantity, j) => item_quantity + (j?.quantity ?? 1), 0) + ((i.meal_items && i.meal_items.length > 0) ? 0 : (i?.quantity ?? 1)), 0),
          order_created_at: prop.order_created_at
            ? moment(prop.order_created_at).format("MMM DD, YYYY hh:mm a")
            : "empty",
          total_cost: "$" + parseFloat(prop.total_cost).toFixed(2),
          actions: (
            // we've added some custom button actions
            <div className="actions-right">
              {/* use this button to add a edit kind of action */}
              <Button
                justIcon
                round
                simple
                onClick={() => {
                  setEditingOrder(prop);
                }}
                color="warning"
                className="edit"
              >
                <Dvr />
              </Button>
            </div>
          ),
        };
      });
      setRows(d);
    },
    [data]
  );
  if (!data) return <div>No Orders set yet</div>;

  const completedOrdersTotal = parseFloat(
    data
      .filter((d) => d.order_status === "order_complete")
      .reduce((acc, d) => acc + (d.total_cost || 0), 0)
  ).toFixed(2);

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              <span>Orders</span>
              <span>
                <strong>Total: ${completedOrdersTotal}</strong>
              </span>
            </h4>
          </CardHeader>
          <CardBody>
            <ReactTable
              data={rows}
              filterable
              sortable={false}
              columns={[
                {
                  Header: "Order ID",
                  accessor: "order_id",
                },
                {
                  Header: "Status",
                  accessor: "order_status",
                  width: 250,
                  Cell: function OrderItems(row: any) {
                    return (
                      <div>
                        {editingOrder &&
                          editingOrder._id &&
                          editingOrder._id === row.original._id ? (
                          <div className={classes.order_edit_container}>
                            <Select
                              name="Order Status"
                              onChange={(e) => {
                                const order = { ...editingOrder };
                                order.order_status = e.target.value;
                                setEditingOrder(order);
                              }}
                              value={editingOrder.order_status}
                            >
                              {ORDER_STATUS_ENUM.map((status) => (
                                <option
                                  className={classes.order_status_option}
                                  key={`order-status-${status}`}
                                  value={status}
                                >
                                  {status}
                                </option>
                              ))}
                            </Select>
                            <Button
                              justIcon
                              round
                              simple
                              onClick={() => {
                                setEditingOrder(null);
                              }}
                              style={{
                                color: "#3fbfba",
                              }}
                            >
                              <Close />
                            </Button>
                            <Button
                              justIcon
                              round
                              simple
                              onClick={() => {
                                onChange(row.original, {
                                  order_status: editingOrder.order_status,
                                });
                                setEditingOrder(null);
                              }}
                              style={{
                                color: "#3fbfba",
                              }}
                            >
                              <Save />
                            </Button>
                          </div>
                        ) : (
                          <span>{row.value}</span>
                        )}
                      </div>
                    );
                  },
                },
                {
                  Header: "Item Type",
                  accessor: "item_type",
                },
                {
                  Header: "User Name",
                  accessor: "user_name",
                },
                {
                  Header: "Total Quantity",
                  accessor: "total_qty",
                },
                {
                  Header: "Total Cost",
                  accessor: "total_cost",
                },
                {
                  Header: "Created At",
                  accessor: "order_created_at",
                },
                {
                  Header: "Actions",
                  accessor: "actions",
                  sortable: false,
                  filterable: false,
                },
              ]}
              pageSize={data.length}
              //   showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

