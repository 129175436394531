import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

import Admin from "layouts/Admin";
import Loader from "components/Loader";
import Input from "@material-ui/core/Input";
import {
  authorizeAdmin,
  fetchEventOrders,
  fetchOrders,
  fetchEvents,
} from "../../modules/actions";
import { Button, Select, MenuItem } from "@material-ui/core";
import EventsTable from "./EventsTable";
import moment from "moment";

const styles = {
  marginBottom30: {
    marginBottom: 30,
  },
};

const useStyles = makeStyles(styles);

function Orders({ authorized,
  loading,
  authorizeAdmin,
  events,
  fetchEventOrders,
  eventOrders,
  fetchEvents, }: any) {
  const classes = useStyles();

  const [email, setEmail] = useState("");
  const [roleCode, setRoleCode] = useState("");
  const [selectedEvent, setSelectedEvent] = useState("");

  const handleEventChange = (e) => {
    fetchEventOrders(e.target.value);
    setSelectedEvent(e.target.value);
  };

  useEffect(() => {
    if (authorized) {
      fetchEvents();
    }
  }, [authorized, fetchEvents]);

  return (
    <Admin>
      <div style={{ color: "black" }}>
        {authorized ? (
          <div>
            <div className={classes.marginBottom30}>
              <span>Select Event: </span>
              <Select value={selectedEvent} onChange={handleEventChange}>
                {events &&
                  events.length > 0 &&
                  events.map((e) => (
                    <MenuItem value={e._id} key={e._id}>
                      {e.title}{" - "}
                      {e.event_date && moment.utc(e.event_date).format("MMM DD, hh:mm a")}
                    </MenuItem>
                  ))}
              </Select>
            </div>
            <div style={{ position: "relative" }}>
              <EventsTable title="Events" data={eventOrders} />
              <Loader visible={loading} />
            </div>
          </div>
        ) : (
          <div style={{ width: "40%", margin: "2rem auto" }}>
            <div className={classes.marginBottom30}>
              <Input
                key="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                type="email"
              />
            </div>
            <div className={classes.marginBottom30}>
              <Input
                key="roleCode"
                fullWidth
                type="password"
                value={roleCode}
                onChange={(e) => setRoleCode(e.target.value)}
                placeholder="Role code"
              />
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                disabled={!email || !roleCode}
                color="primary"
                onClick={() => authorizeAdmin(email, roleCode, "events_orders")}
              >
                Submit
              </Button>
            </div>
          </div>
        )}
      </div>
    </Admin>
  );
}

const mapStateToProps = ({ admin }) => ({
  orders: admin.orders,
  eventOrders: admin.eventOrders,
  events: admin.events,
  loading: admin.ordersLoading,
  authorized: admin.authorized,
});

const mapDispatchToProps = (dispatch) => ({
  authorizeAdmin: (email, role_code, type) =>
    dispatch(authorizeAdmin(email, role_code, type)),
  fetchEvents: () => dispatch(fetchEvents()),
  fetchOrders: () => dispatch(fetchOrders()),
  fetchEventOrders: (event) => dispatch(fetchEventOrders(event)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Orders);
