import * as actionTypes from "./types";

const INITIAL_STATE = {
  signupLoading: false,
  signupGoogleLoading: false,
  user: null,
  signupSuccess: false,
  signupGoogleSuccess: false,
  error: '',
};
// eslint-disable-next-line
export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.SIGNUP:
      return { ...state, signupLoading: true, signupSuccess: false };
    case actionTypes.SIGNUP_FAIL:
      return { ...state, signupLoading: false, signupSuccess: false };
    case actionTypes.SIGNUP_SUCCESS:
      return { ...state, user: action.payload, signupLoading: false, signupSuccess: true };
    case actionTypes.SIGNUP_GOOGLE:
      return { ...state, signupGoogleLoading: true, signupGoogleSuccess: false };
    case actionTypes.SIGNUP_GOOGLE_FAIL:
      return { ...state, signupGoogleLoading: false, signupGoogleSuccess: false };
    case actionTypes.SIGNUP_GOOGLE_SUCCESS:
      return { ...state, user: action.payload, signupGoogleLoading: false, signupGoogleSuccess: true };
    case actionTypes.LOGOUT:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
}
