import React, { useEffect, useState } from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import Gallery from "components/Gallery/Gallery";
import { getImagePath } from "shared/utils/helpers";
import { Select, MenuItem, Button, IconButton } from "@material-ui/core";
import { Add, Delete, Remove } from "@material-ui/icons";

const styles = {
  cardTitle: {
    marginBottom: 0,
  },
  marginTop30: {
    marginTop: "30px",
  },
  marginBottom30: {
    marginBottom: "30px",
  },
  paddingTop30: {
    paddingTop: "30px",
  },
  recipe_description: {
    paddingTop: "20px",
  },
  recipe_summary: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "30px",
  },
  special_requests: {
    marginTop: 30,
  },
  textArea: {
    width: "80%",
    height: 80,
    padding: "1rem",
  },
  numberOfItems: {
    width: "30%",
    display: "flex",
    justifyContent: "space-between",
    textAlign: "center",
  },
  cost: {
    marginLeft: 30,
    marginTop: 20,
    fontSize: 20
  },
  recipe_url: {
    color: "#1A72E7 !important",
    fontSize: "1rem",
    fontWeight: "500",
    display: "flex",
    alignItems: "center",
    marginBottom: 30,
  },
  open_icon: {
    marginLeft: 10,
  },
  meta_title: {
    fontSize: "2rem",
  },
  meta_description: {
    marginTop: "10px",
  },
  inputClass: {
    textAlign: "center",
  },
  quantity_select: {
    width: 100,
    textAlign: "center",
  },
  guestsLeft: {
    color: "red",
    fontWeight: "bold",
    marginTop: 15,
    marginLeft: 40,
  },
};
const useStyles = makeStyles(styles);

export default function CulinaryMenu({ menu, cartItems, onChange, onAddToCart, submitButtonTitle }: any) {
  const classes = useStyles();
  const [numberOfItems, setNumberOfItems] = useState(1);
  const [cartItemState, setCartItemState] = useState(null);
  const changeNumberOfItems = (to) => {
    setNumberOfItems(to);
    onChange({ quantity: to });
  };

  const checkItem = () => {
    for (let i = 0; i < cartItems.length; i++) {
      const element = cartItems[i];
      if (element?.menuCulinaries?.length) {
        for (let j = 0; j < element?.menuCulinaries.length; j++) {
          const element1 = element?.menuCulinaries[j];
          if (element1?.menu_title === menu.menu_title) {
            setCartItemState(element1)
          }

        }
      }

    }
  }

  useEffect(() => {
    checkItem()
    // eslint-disable-next-line
  }, [cartItems])

  return (
    <GridContainer className={classes.marginBottom30}>
      <GridItem md={6}>
        {menu.media && (
          <Gallery
            videoFirst
            media={menu.media.map((m) => ({ ...m, url: getImagePath(m.url) }))}
          />
        )}
      </GridItem>
      <GridItem md={6}>
        {/* {menu._id && (
          <div>
            <a
              className={classes.recipe_url}
              href={`/app/shop/${menu._id}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              Recipe Details <Launch className={classes.open_icon} />
            </a>
          </div>
        )} */}
        <div>
          <h2 style={{ margin: 0 }}>{menu.menu_title}</h2>
          <div className={classes.marginBottom30}>
            <p
              className={classes.recipe_description}
              dangerouslySetInnerHTML={{ __html: menu.menu_description }}
            />
          </div>
        </div>
        {menu.order_limit ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            {
              cartItemState?.quantity > 0 ? null :
                <>
                  <span style={{ marginRight: 10 }}>Qty:</span>
                  <Select
                    className={classes.quantity_select}
                    value={numberOfItems}
                    onChange={(e) => changeNumberOfItems(e.target.value)}
                  >
                    {Array.from(
                      Array(
                        menu.order_limit && menu.order_limit < 10
                          ? menu.order_limit + 1
                          : 11
                      )
                    )
                      .map((_, i) => i + 1)
                      .map((q) => (
                        <MenuItem key={`quantity-${q}`} value={q}>
                          {q}
                        </MenuItem>
                      ))}
                  </Select>
                </>}
            <div className={classes.cost}>
              <p>
                Cost:{" "}
                <strong>${parseFloat(menu.price).toFixed(2)}</strong>
              </p>
            </div>
          </div>
        ) : <div className={classes.cost}>
          <p>
            Cost:{" "}
            <strong>${parseFloat(menu.price).toFixed(2)}</strong>
          </p>
        </div>}


        {menu.order_limit > 0 &&
          <div className={classes.add_to_card} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {
              cartItemState?.quantity > 0 ?
                <div style={{
                  backgroundColor: "rgba(221, 116, 93, 0.2)", display: 'flex', alignItems: 'center', paddingLeft: 20, paddingRight: 20, flexDirection: 'row', justifyContent: 'space-between',
                  height: 50, marginTop: "10px", width: 180, borderRadius: 8
                }}>
                  {
                    cartItemState?.quantity === 1 ?
                      <IconButton
                        onClick={() => {
                          onAddToCart(-1)
                        }}
                      >
                        <Delete />
                      </IconButton>
                      :
                      <IconButton onClick={() => onAddToCart(-1)}>
                        <Remove />
                      </IconButton>
                  }
                  <div style={{ fontSize: 20, fontWeight: '500', color: '#000' }}>
                    {cartItemState?.quantity}
                  </div>
                  <IconButton onClick={() => onAddToCart(1)}>
                    <Add />
                  </IconButton>
                </div>
                :
                <Button
                  size="large"
                  onClick={() => {
                    onAddToCart(numberOfItems)
                    setNumberOfItems(1)
                  }}
                  disabled={(menu.order_limit > 0 ? false : true)}
                  style={{ backgroundColor: "#dd745d", color: "white", marginTop: "10px", width: 180, borderRadius: 8 }}
                >
                  {submitButtonTitle}
                </Button>
            }
            {/* {
              cartQuantity > 0 &&
              <div style={{ marginLeft: 20, width: 80, height: 30, backgroundColor: 'lightgrey', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{cartQuantity + " in cart"}</div>
            } */}
          </div>
        }
        {menu.order_limit ? (
          <p className={classes.guestsLeft}>
            {menu.order_limit} items left
          </p>
        ) : (
          <p className={classes.guestsLeft}>Out of stock!</p>
        )}
      </GridItem>
    </GridContainer>
  );
}

CulinaryMenu.propTypes = {
  menu: PropTypes.object,
  onChange: PropTypes.func,
  quantity: PropTypes.number,
};
