import * as actionTypes from '../actions/event/types';

const INITIAL_STATE = {
   events: [],
   loading: false,
   selectedEvent: null,
}

// eslint-disable-next-line
export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case actionTypes.SET_LOADING:
            return { ...state, loading: action.payload };
        case actionTypes.SET_EVENTS:
            return { ...state, events: action.payload };
        case actionTypes.SET_SELECTED_EVENT:
            return { ...state, selectedEvent: action.payload };
        default:
            return state;
    }
}