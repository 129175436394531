import {
  successColor,
  tooltip,
  cardTitle,
  grayColor,
} from "assets/jss/material-dashboard-pro-react.js";

import hoverCardStyle from "assets/jss/material-dashboard-pro-react/hoverCardStyle.js";
import { green, orange } from "@material-ui/core/colors";

const dashboardStyle = {
  ...hoverCardStyle,
  tooltip,
  clearCardBG: {
    background: 'transparent',
    boxShadow: 'none',  
    color: '#5E5E5E !important',
  },
  clearCardFooter: {
    border: 'none',
  },
  videoUrl: {
    position: 'absolute',
    right: '1rem',
    bottom: '1rem',
    color: 'white',
    "&:hover, &:focus": {
      color: 'white',
    },
    "& svg": {
      background: '#43413F',
      borderRadius: '2rem',
    },
  },
  cardTitle: {
    ...cardTitle,
    marginTop: "0px",
    marginBottom: "3px"
  },
  divider: {
    marginLeft: '2rem',
    borderBottom: '1px solid rgba(0,0,0,0.1)',
    width: '80%',
    marginTop: '1rem',
  },
  info: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: '1rem',
    marginLeft: '2rem',
    "& > div": {
      display: 'flex',
      alignItems: 'center',
      marginRight: '3px',
    }
  },
  infoMinutes: {
    color: green[500],
  },
  infoCalories: {
    color: orange[500],
  },
  infoIcon: {
    marginRight: '10px',
  },
  eventInfo: {
    marginLeft: '2rem',
    marginRight: '2rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 'bold',
  },
  productInfo: {
    marginLeft: '2rem',
    marginRight: '2rem',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontWeight: 'bold',
  },
  verticalDivider: {
    width: '1px',
      color: '#949494',
      padding: '0 11px',
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  cardProductTitle: {
    ...cardTitle,
    marginTop: "0px",
    padding: '1rem 2rem 0.5rem 2rem',
    marginBottom: "3px",
    "& a": {
      color: '#002C9B',
      fontWeight: 'bold',
    }
  },
  cardFooter: {
    display: 'block',
  },
  cardCategory: {
    color: grayColor[0],
    fontSize: "14px",
    paddingTop: "10px",
    marginBottom: "0",
    marginTop: "0",
    margin: "0"
  },
  cardProductDesciprion: {
    color: '#5E5E5E !important',
    padding: '0 2rem',
    fontWeight: '900',
    margin: 0,
  },
  stats: {
    color: grayColor[0],
    fontSize: "12px",
    lineHeight: "22px",
    display: "inline-flex",
    "& svg": {
      position: "relative",
      top: "4px",
      width: "16px",
      height: "16px",
      marginRight: "3px"
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      position: "relative",
      top: "4px",
      fontSize: "16px",
      marginRight: "3px"
    }
  },
  productStats: {
    paddingTop: "7px",
    paddingBottom: "7px",
    margin: "0"
  },
  successText: {
    color: successColor[0]
  },
  upArrowCardCategory: {
    width: 14,
    height: 14
  },
  underChartIcons: {
    width: "17px",
    height: "17px"
  },
  price: {
    color: "inherit",
    "& h4": {
      marginBottom: "0px",
      marginTop: "0px"
    }
  },
  chipList: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: "1rem",
    paddingBottom: "2rem",
    margin: 0,
  },
  chip: {
    margin: "0.3rem",
  },
};

export default dashboardStyle;
