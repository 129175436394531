export function getYoutubeUrlFromId(youtubeId) {
  return `https://www.youtube.com/watch?v=${youtubeId}`;
}

export function getImagePath(relativePath) {
  return `${process.env.REACT_APP_FT_CLOUDFRONT_HOST}/${relativePath}`;
}

export function setGoogleAnalyticsPage(page) {
  if (window.ga && typeof window.ga === "function") {
    window.ga("set", "page", page);
  }
}

export function shuffleArray(a) {
  let array = a.slice();
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}
