import * as actionTypes from "./types";
import Api from "../../../shared/api";
import requestAgent from "../../../shared/requestAgent";
import swal from "sweetalert";

export const setAuthorized = (authorized) => ({
  type: actionTypes.SET_AUTHORIZED,
  payload: authorized,
});

export const authorizeAdmin = (email, role_code, type) => (dispatch) => {
  requestAgent
    .post(Api.user.generateToken(), { email, role_code })
    .then((res) => {
      console.log(res);
      if (res.body.res_code === 400) {
        swal({
          text: res.body.res_text,
        });
      } else if (res.body.res_data && res.body.res_data.data) {
        window.localStorage.setItem(
          "x-access-token",
          res.body.res_data.data.token
        );
        dispatch(setAuthorized(true));
        if (type === "events_orders") {
          dispatch(fetchEvents());
        } else {
          dispatch(fetchOrders());
        }
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

const setOrdersLoading = (loading) => ({
  type: actionTypes.SET_ORDERS_LOADING,
  payload: loading,
});
const setOrders = (orders) => ({
  type: actionTypes.SET_ORDERS,
  payload: orders,
});

export const fetchOrders = () => (dispatch) => {
  dispatch(setOrdersLoading(true));
  requestAgent
    .get(Api.order.getAll())
    .then((res) => {
      dispatch(setOrders(res.body.res_data.data.orders));
      dispatch(setOrdersLoading(false));
    })
    .catch((err) => {
      console.log(err);
      dispatch(setAuthorized(false));
      dispatch(setOrdersLoading(false));
    });
};

export const updateOrderStatus = (id, order_status) => (dispatch, getState) => {
  requestAgent
    .patch(Api.order.updateOrderStatus(id), { order_status })
    .then((res) => {
      const { body } = res;
      if (body && body.res_data && body.res_data.data.order) {
        const { order } = body.res_data.data;
        const { orders } = getState()[actionTypes.MODULE_NAME];
        const newOrders = orders.slice();
        const orderIndex = newOrders.findIndex((o) => o._id === order._id);
        newOrders.splice(orderIndex, 1, order);
        dispatch(setOrders(newOrders));
        window.location.reload();
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch(setAuthorized(false));
    });
};

const setEvents = (events) => ({
  type: actionTypes.SET_EVENTS,
  payload: events,
});

export const fetchEvents = () => (dispatch) => {
  requestAgent
    .get(Api.order.getEvents())
    .then((res) => {
      if (res.body.res_data && res.body.res_data.data) {
        console.log('res.body.res_data.data.events', res.body.res_data.data.events)
        let futureEvents = []
        for (let i = 0; i < res.body.res_data.data.events.length; i++) {
          const element = res.body.res_data.data.events[i];
          if (new Date(element.event_date) >= Date.now()) {
            futureEvents.push(element)
          }
        }
        dispatch(setEvents(futureEvents));
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch(setAuthorized(false));
    });
};

const setEventOrders = (order) => ({
  type: actionTypes.SET_EVENT_ORDERS,
  payload: order,
});

export const fetchEventOrders = (event) => (dispatch) => {
  dispatch(setOrdersLoading(true));
  requestAgent
    .get(Api.order.getEventOrders(event))
    .then((res) => {
      if (res.body.res_data && res.body.res_data.data) {
        dispatch(setEventOrders(res.body.res_data.data));
      }
      dispatch(setOrdersLoading(false));
    })
    .catch((err) => {
      console.log(err);
      dispatch(setOrdersLoading(false));
      dispatch(setAuthorized(false));
    });
};
