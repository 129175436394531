import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import {
  Grid,
  Typography
} from "@material-ui/core";
import { fetchEvents } from "actions/event";
import moment from "moment";
import Admin from "layouts/Admin";
import { getImagePath } from "shared/utils/helpers";
import Loader from "components/Loader";

const useStyles = makeStyles(styles);

function Events(props) {
  const classes = useStyles();
  const { fetchEvents, loading } = props;

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);

  return (
    <Admin>
      <div>
        <Loader visible={loading} />
        <GridContainer>
          <Grid item xs={12}>
            <Typography
              style={{ fontWeight: "bold", textTransform: "none", color: "#002C9B", marginBottom: "3rem", fontFamily: 'sans-serif' }}
              align="center"
              variant="h4"
            >
              Private Culinary Events and Cooking Classes
            </Typography>
          </Grid>
          {props.events
            ? props.events.map((event) => (
              <GridItem
                xs={12}
                sm={12}
                md={4}
                key={`event-item-${event.slug}`}
              >
                <Card product className={classes.clearCardBG}>
                  <CardHeader image className={classes.cardHeaderHover}>
                    <Link to={`/app/events/${event.slug}`}>
                      <img
                        className={classes.cardHeaderImage}
                        src={getImagePath(
                          event.homeMedia ? event.homeMedia.url : '',
                        )}
                        alt="..."
                      />
                    </Link>
                  </CardHeader>
                  <CardFooter
                    product
                    className={`${classes.cardFooter} ${classes.clearCardFooter}`}
                  >
                    <h4 className={classes.cardProductTitle}>
                      <Link to={`/app/events/${event.slug}`}>
                        {event.title}
                      </Link>
                    </h4>
                    {/* <p className={classes.divider}></p> */}
                    <div className={classes.eventInfo}>
                      <div>
                        {moment.utc(event.event_date).format("MMM	DD, hh:mm A")}
                      </div>
                      {/* <div>
                          $
                          {parseInt(event.price) === event.price
                            ? `${event.price}.00`
                            : event.price}
                        </div> */}
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>
            ))
            : null}
        </GridContainer>
      </div>
    </Admin>
  );
}

Events.propTypes = {
  fetchEvents: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  events: PropTypes.array,
};

const mapStateToProps = ({ event }) => ({
  loading: event.loading,
  events: event.events,
});

const mapDispatchToProps = (dispatch) => ({
  fetchEvents: () => dispatch(fetchEvents()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Events);
